import { backgroundImage, conditionalSpread } from 'clyne-core';
import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import To from '../to';
import Tag from '../tag';
import Tab from '../tab';
import Card from '../card';
import Tabs from '../tabs';
import NoData from '../noData';
import Rating from '../rating';
import Button from '../button';
import Tooltip from '../tooltip';
import Popover from '../popover';
import CopyText from '../copyText';
import Translate from '../translate';
import MobileStack from '../mobileStack';
import WorkingDays from '../workingDays';
import LinkedScreen from '../linkedScreen';
import ScreenDetails from '../screenDetails';
import ScreenLocation from '../screenLocation';
import MassActionApply from '../massActionApply';
import ScreenCampaigns from '../screenCampaigns';
import DeviceInformation from '../deviceInformation';
import ScreenOnlineTooltip from '../screenOnlineTooltip';

import connectionService from '../../services/connectionService';

import useDevice from '../../hooks/useDevice';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';

import { dynamicDataState, workspaceIdState } from '../../state';

import { deviceActions, screenConsolePrompts } from '../screenConsole/data';

import { getURLParam, screenURL, idFormatter, successStatus, getScreenThumbnail, removeDuplicatesFromArray } from '../../helpers';

import './index.scss';

const ScreenCard = props => {
    const {
        id,
        name,
        menu,
        cache,
        rating,
        images,
        aliveAt,
        typeId,
        linked,
        visible,
        skeleton,
        isOnline,
        latitude,
        longitude,
        deviceUid,
        description,
        workingHours,
        onSyncSuccess,
        updatedNeeded,
        runningCampaigns,
        appearance = 'default',
    } = props;

    const { isMobile } = useDevice();

    const synced = !!deviceUid;

    const dynamicData = useRecoilValue(dynamicDataState);
    const workspaceId = useRecoilValue(workspaceIdState);

    const [counter, setCounter] = useState(1);
    const [syncLoading, setSyncLoading] = useState(false);


    const actions = screenConsolePrompts({});

    const massScreens = removeDuplicatesFromArray([...(dynamicData?.deviceStatuses?.offline || []), ...(dynamicData?.deviceStatuses?.online || [])].map(screen => screen.screenId));

    return (
        <>
            <MassActionApply
                status
                active={counter === 10}
                closeOnBackdropClick={false}
                endpointConstructor={id => `/workspaces/${workspaceId}/screens/${id}/device`}
                title={<Translate>Mass Action</Translate>}
                paramsConstructor={({ value, command }) => ({
                    action: value,
                    ...conditionalSpread({
                        data: {
                            command,
                        }
                    }, value === deviceActions.runCommand),
                })}
                onClose={() => setCounter(1)}
                dropDown={{
                    data: Object.keys(actions).map(key => ({
                        name: actions[key].label,
                        id: key,
                    })),
                    placeholder: translate('Select the action'),
                }}
                screens={massScreens}
                message={{
                    type: 'massAction',
                    children: (
                        <>
                            <Translate>You've found a hidden functionality!</Translate>
                            <br />
                            <Translate replaceMap={{ '_COUNT_': massScreens?.length }}>You can select action to apply to _COUNT_ screens.</Translate>
                        </>
                    ),
                }}
            />
            <Card
                padding={14}
                overflow={false}
                appearance={(isMobile || appearance === 'select') ? 'light' : 'clean'}
            >
                <ul
                    className={classNames(
                        `screen-card-c-holder`,
                        `a-${appearance}`,
                        {
                            'pointer-events-none': skeleton,
                        }
                    )}
                >
                    <li>
                        <div className='screen-c-image-holder'>
                            {skeleton ? (
                                <div className='skeleton-blink absolutely-splash' />
                            ) : (
                                <>
                                    <figure
                                        className='absolutely-splash'
                                        style={backgroundImage(getScreenThumbnail(images))}
                                    />
                                    <div className='screen-rating-holder s-small'>
                                        <Rating rate={rating} />
                                    </div>
                                    <To
                                        url={screenURL(id)}
                                        target='_blank'
                                        className='absolutely-splash'
                                    />
                                </>
                            )}
                        </div>
                    </li>
                    {appearance === 'default' && (
                        <li>
                            <div className='h-divider' />
                        </li>
                    )}
                    <li className='screen-cc-content'>
                        <ul className='screen-c-headline'>
                            {((synced ? synced : !visible) || skeleton) && (
                                <li>
                                    {skeleton ? (
                                        <div className='skeleton-wave h-32 full-radius min-110' />
                                    ) : (
                                        <Tooltip
                                            position='top'
                                            content={(visible && synced) ? (
                                                <Translate>Screen is visible and synced</Translate>
                                            ) : synced ? (
                                                <Translate>Screen is synced but not visible</Translate>
                                            ) : (
                                                visible ? (
                                                    <Translate>Screen is not synced but visible</Translate>
                                                ) : (
                                                    <Translate>Screen is not synced and not visible</Translate>
                                                )
                                            )}
                                        >
                                            <Tag
                                                size='medium'
                                                appearance={(visible && synced) ? 'fill' : 'outline'}
                                                color={(visible && synced) ? 'success' : synced ? 'warning' : 'error'}
                                                {...conditionalSpread({
                                                    icon: {
                                                        size: 18,
                                                        type: 'icon-a-eye-slash',
                                                    },
                                                }, !visible)}
                                                name={synced ? (
                                                    <Translate>Synced</Translate>
                                                ) : visible ? (
                                                    <Translate>Not Synced</Translate>
                                                ) : (
                                                    <Translate>Hidden</Translate>
                                                )}
                                            />
                                        </Tooltip>
                                    )}
                                </li>
                            )}
                            <li>
                                {skeleton ? (
                                    <div className='skeleton-wave t-blink h-32 full-c-radius min-60' />
                                ) : (
                                    <Tag
                                        size={isMobile ? 'small' : 'medium'}
                                        appearance='outline'
                                        name={idFormatter(id)}
                                        {...conditionalSpread({
                                            icon: {
                                                size: isMobile ? 14 : 18,
                                                type: 'icon-linked',
                                            },
                                            ...conditionalSpread({
                                                tooltip: (
                                                    <LinkedScreen
                                                        linked={linked}
                                                    />
                                                ),
                                            }, !isMobile),
                                        }, linked?.length)}
                                    />
                                )}
                            </li>
                            <li className='f-auto'>
                                <h2>
                                    {skeleton ? (
                                        <span className='skeleton-wave small' />
                                    ) : (
                                        <CopyText
                                            text={`${idFormatter(id)} - ${name}`}
                                        >
                                            <To url={screenURL(id)} target='_blank'>
                                                {name}
                                            </To>
                                        </CopyText>
                                    )}
                                </h2>
                            </li>
                            <li>
                                {!!synced && (
                                    <Tooltip
                                        className='content-screen-online'
                                        content={
                                            <ScreenOnlineTooltip
                                                id={id}
                                                uid={deviceUid}
                                                online={isOnline}
                                                aliveAt={aliveAt}
                                            />
                                        }
                                    >
                                        <div
                                            onClick={() => !!massScreens?.length && setCounter(val => val + 1)}
                                            className={classNames(
                                                'screen-online-info',
                                                {
                                                    'warning': !isOnline,
                                                }
                                            )}
                                        >
                                            <span>
                                                {isOnline ? <Translate>Online</Translate> : <Translate>Offline</Translate>}
                                            </span>
                                        </div>
                                    </Tooltip>
                                )}
                            </li>
                            <li>
                                {skeleton ? (
                                    <div
                                        className={`skeleton-wave t-blink full-radius ${isMobile ? 'b-32' : 'b-40'}`}
                                    />
                                ) : (
                                    <>
                                        {appearance === 'select' ? (
                                            <Button
                                                color='accent'
                                                itemsDirection='end'
                                                flexibility='fit'
                                                size={isMobile ? 'small' : 'default'}
                                                {...conditionalSpread({
                                                    className: 'c-danger',
                                                }, synced)}
                                                loading={syncLoading}
                                                icon={{
                                                    size: isMobile ? 20 : 24,
                                                    type: synced ? 'icon-a-cross' : 'icon-a-check',
                                                }}
                                                onClick={() => {
                                                    if (synced) {
                                                        menu?.find(item => item.action === 'un-sync')?.onClick?.();
                                                    } else {
                                                        setSyncLoading(true);
                                                        const syncItem = menu.find(item => item.action === 'sync');
                                                        const url = `/workspaces/${workspaceId}/screens/${id}/device`;
                                                        connectionService.putJson(url, {
                                                            action: 'sync',
                                                            data: {
                                                                code: getURLParam('sync'),
                                                            },
                                                        }).subscribe(res => {
                                                            setSyncLoading(false);
                                                            if (successStatus(res)) {
                                                                addToast(syncItem.onSuccess.toast);
                                                                typeof syncItem.onSuccess.callBack() === 'function' && syncItem.onSuccess.callBack();
                                                                typeof onSyncSuccess === 'function' && onSyncSuccess();
                                                            }
                                                        });
                                                    }
                                                }}
                                                tooltip={synced ? (
                                                    <Translate>Un-Sync</Translate>
                                                ) : (
                                                    <Translate>Sync</Translate>
                                                )}
                                            />
                                        ) : (
                                            <Popover menu={menu}>
                                                <Button
                                                    color='accent'
                                                    badge={updatedNeeded}
                                                    size={isMobile ? 'small' : 'default'}
                                                    icon={{
                                                        size: isMobile ? 20 : 22,
                                                        type: 'icon-a-more-horiz'
                                                    }}
                                                />
                                            </Popover>
                                        )}
                                    </>
                                )}
                            </li>
                        </ul>
                        {appearance === 'select' ? (
                            <>
                                <div className='v-divider' />
                                <ScreenDetails
                                    data={props}
                                    skeleton={skeleton}
                                    includeRunningCampaigns
                                    appearance='scrollable'
                                />
                            </>
                        ) : (
                            <Tabs skeleton={skeleton}>
                                <Tab
                                    icon='icon-a-list-unordered'
                                    title={<Translate>Details</Translate>}
                                >
                                    <ScreenDetails
                                        data={props}
                                        skeleton={skeleton}
                                        appearance='scrollable'
                                    />
                                </Tab>
                                <Tab
                                    icon='icon-a-megaphone'
                                    badge={runningCampaigns}
                                    title={<Translate>Campaigns</Translate>}
                                >
                                    <ScreenCampaigns
                                        id={id}
                                        skeletonCount={runningCampaigns}
                                    />
                                </Tab>
                                <Tab
                                    icon='icon-a-processor'
                                    title={<Translate>Device</Translate>}
                                >
                                    {deviceUid ? (
                                        <DeviceInformation
                                            cache={cache}
                                            screenId={id}
                                            aliveAt={aliveAt}
                                            isOnline={isOnline}
                                            deviceUid={deviceUid}
                                        />
                                    ) : (
                                        <div className='screen-card-no-data has-cta'>
                                            <NoData
                                                size='small'
                                                subTitle={
                                                    <Translate>To see the device information, please sync the screen</Translate>
                                                }
                                                cta={{
                                                    onClick: () => menu.find(obj => obj.action === 'sync').onClick(),
                                                    color: 'accent',
                                                    children: (
                                                        <Translate>Sync Screen</Translate>
                                                    )
                                                }}
                                            />
                                        </div>
                                    )}
                                </Tab>
                                <Tab
                                    icon='icon-a-time'
                                    title={<Translate>Working Hours</Translate>}
                                >
                                    <MobileStack>
                                        <WorkingDays data={workingHours} />
                                    </MobileStack>
                                </Tab>
                                <Tab
                                    icon='icon-a-pin'
                                    title={<Translate>Map</Translate>}
                                >
                                    <ScreenLocation
                                        size='wide'
                                        typeId={typeId}
                                        latitude={latitude}
                                        longitude={longitude}
                                    />
                                </Tab>
                                <Tab
                                    icon='icon-a-chart-bar'
                                    title={<Translate>Description</Translate>}
                                >
                                    {description ? (
                                        <div>{description}</div>
                                    ) : (
                                        <div className='screen-card-no-data'>
                                            <NoData
                                                size='small'
                                                subTitle={
                                                    <Translate>No Description</Translate>
                                                }
                                            />
                                        </div>
                                    )}
                                </Tab>
                            </Tabs>
                        )}
                    </li>
                </ul>
            </Card>
            {(isMobile || appearance === 'select') && (
                <div className='v-divider screen-card-v-divider' />
            )}
        </>
    );
};

ScreenCard.propTypes = {
    id: PropTypes.number,
    menu: PropTypes.array,
    name: PropTypes.string,
    data: PropTypes.array,
    cache: PropTypes.number,
    typeId: PropTypes.number,
    cityId: PropTypes.number,
    rating: PropTypes.number,
    linked: PropTypes.arrayOf(PropTypes.number),
    images: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        featured: PropTypes.bool,
    })),
    aliveAt: PropTypes.string,
    setCode: PropTypes.func,
    visible: PropTypes.bool,
    pWidth: PropTypes.number,
    pHeight: PropTypes.number,
    cityName: PropTypes.string,
    timezone: PropTypes.string,
    latitude: PropTypes.number,
    skeleton: PropTypes.bool,
    isOnline: PropTypes.bool,
    countryId: PropTypes.string,
    placement: PropTypes.number,
    longitude: PropTypes.number,
    deviceUid: PropTypes.string,
    updateById: PropTypes.func,
    setCommand: PropTypes.func,
    appearance: PropTypes.oneOf([
        'default',
        'select',
    ]),
    description: PropTypes.string,
    placeTypeId: PropTypes.number,
    pricePerSlot: PropTypes.number,
    slotDuration: PropTypes.number,
    loopDuration: PropTypes.number,
    workingHours: PropTypes.arrayOf(PropTypes.object),
    onSyncSuccess: PropTypes.func,
    updatedNeeded: PropTypes.bool,
    orientationId: PropTypes.number,
    handleActionClick: PropTypes.func,
    runningCampaigns: PropTypes.number,
    averageDailyPlayback: PropTypes.number,
    averagePricePerMonth: PropTypes.number,
};

export default ScreenCard;
