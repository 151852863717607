import React from 'react';
import classNames from 'classnames';
import { conditionalSpread } from 'clyne-core';

import Card from '../../../card';
import Popover from '../../../popover';

import Cell from '../cell';
import Actions from '../actions';

import useDevice from '../../../../hooks/useDevice';

import { rowProps } from './props';

import './index.scss';

const Row = props => {
    const {
        row,
        total,
        actions,
        subItem,
        children,
        skeleton,
        collapsed,
        cardProps,
        type = 'td',
        colSpanTotal,
    } = props;

    const { isMobile } = useDevice();

    return isMobile ? (
        <Card
            padding={0}
            shadow={!total}
            overflow={false}
            appearance='clean'
            {...conditionalSpread(cardProps, !!cardProps)}
        >
            <div
                className={classNames(
                    'mobile-card-table-row',
                    {
                        total,
                        'pending-review': row?.pendingReview,
                    }
                )}
            >
                {children}
            </div>
            {(!!subItem || !!actions.length) && (
                <div className='mobile-card-actions-holder'>
                    <Actions
                        subItem={subItem}
                        actions={actions}
                        skeleton={skeleton}
                    />
                </div>
            )}
            {!!subItem && (
                <Popover
                    content={subItem}
                    opened={!collapsed}
                >
                    <div />
                </Popover>
            )}
        </Card>
    ) : (
        <>
            <tr
                className={classNames({
                    'totals-row': total,
                    'opened': !collapsed,
                    'pending-review': row?.pendingReview,
                })}
            >
                {children}
                {!!actions?.length && (
                    <Cell
                        fit
                        type={type}
                    >
                        {type === 'td' && (
                            <Actions
                                actions={actions}
                                skeleton={skeleton}
                            />
                        )}
                    </Cell>
                )}
            </tr>
            {(subItem && !collapsed) && (
                <tr>
                    <td colSpan={colSpanTotal}>
                        <ul className='tr-sub-children-holder'>
                            <li className='row-background' />
                            <li className='tr-sub-children'>
                                {subItem}
                            </li>
                        </ul>
                    </td>
                </tr>
            )}
        </>
    );
};

Row.propTypes = rowProps;

export default Row;
