import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Guide from '../guide';
import Button from '../button';
import MorphingLogo from '../morphingLogo';
import BillboardArtwork from '../billboardArtwork';

import translate from '../../utils/translate';

import './index.scss';

const SplashInformation = props => {
    const {
        logo,
        ctas,
        title,
        message,
        artwork,
        appearance = 'default',
    } = props;

    const content = (
        <>
            {!!artwork?.icon && (
                <div className='si-illustration'>
                    <BillboardArtwork
                        icon={artwork?.icon}
                        color={artwork?.color}
                    />
                </div>
            )}
            <div
                className={classNames(
                    'grid',
                    'splash-information',
                    `a-${appearance}`,
                    {
                        'has-logo': logo,
                        'gap-3': appearance === 'extra',
                    }
                )}
            >
                {!!logo && (
                    <div className='splash-logo-holder'>
                        <MorphingLogo
                            scale={false}
                            appearance='mark'
                        />
                    </div>
                )}
                {!!title && (
                    <h2>{title}</h2>
                )}
                {!!message && (
                    <div className='si-message-holder'>{message}</div>
                )}
                {!!ctas?.length && (
                    <div className='splash-information-ctas'>
                        {ctas.map((cta, index) => (
                            <Button
                                color={index ? 'accent' : 'brand'}
                                to={cta.to}
                                key={index}
                                href={cta.href}
                                icon={cta.icon}
                                flexibility='fit'
                                target={cta.target}
                                itemsDirection='end'
                                onClick={cta.onClick}
                                loading={cta.loading}
                                size={appearance === 'extra' ? 'extra-large' : 'medium'}
                            >
                                {translate(cta.children)}
                            </Button>
                        ))}
                    </div>
                )}
            </div>
        </>
    );

    return appearance === 'extra' ? (
        <Guide>
            {content}
        </Guide>
    ) : content;
};

SplashInformation.propTypes = {
    logo: PropTypes.bool,
    ctas: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.any,
    message: PropTypes.any,
    artwork: PropTypes.shape({
        icon: PropTypes.string,
        color: PropTypes.string,
    }),
    appearance: PropTypes.oneOf([
        'default',
        'extra',
    ]),
};

export default SplashInformation;
