import React, { Suspense, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';

import Guide from '../guide';
import Loader from '../loader';

import useDevice from '../../hooks/useDevice';
import useRedirectToSignIn from '../../hooks/useRedirectToSignIn';

import lazyWithRetry from '../../utils/lazyWithRetry';

import { crState, currencyState, signedInState } from '../../state';

import { isWhiteLabel } from '../../helpers';

const WhiteLabelFallback = lazyWithRetry(() => import('../whiteLabelFallback').then());

const RouteComponent = props => {
    const {
        route,
        children,
        isProtected,
    } = props;

    const { isMobile } = useDevice();

    const cr = useRecoilValue(crState);
    const signedIn = useRecoilValue(signedInState);
    const currency = useRecoilValue(currencyState);
    const setShouldRedirect = useRedirectToSignIn();

    const shouldRedirect = isProtected && !signedIn && signedIn !== null;

    useEffect(() => {
        setShouldRedirect(shouldRedirect);
    }, [shouldRedirect]); // eslint-disable-line

    const loader = (
        <Loader absolute={!isMobile || !!cr?.wizard} />
    );

    return (route?.externalOnWhiteLabel && isWhiteLabel) ? (
        <Suspense fallback={loader}>
            <WhiteLabelFallback />
        </Suspense>
    ) : (((signedIn !== null && !shouldRedirect && !!currency) || (!isProtected && route?.instantRender)) ? (
        children ? (
            <Suspense fallback={loader}>
                {children}
            </Suspense>
        ) : (
            <Guide
                enabled={route.guide.enabled}
                padding={route.guide.padding}
            >
                <Suspense fallback={loader}>
                    {route.component}
                </Suspense>
            </Guide>
        )
    ) : loader);
};

RouteComponent.propTypes = {
    route: PropTypes.object,
    children: PropTypes.any,
    isProtected: PropTypes.bool,
};

export default RouteComponent;
