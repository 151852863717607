import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

import Translate from '../translate';

import translate from '../../utils/translate';

import { authenticationRoutes } from '../../containers/authentication/data';

import { deleteURLParam } from '../../helpers';

import searchParamsConstructor from '../../utils/searchParamsConstructor';

const AppUrlListener = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const getRedirectURL = keyMessage => {
        switch (keyMessage) {
            case 'account_not_found':
            case 'account_exists':
            case 'email_verification_failed':
            case 'invalid_token':
            case 'token_doesnt_exist':
            case 'token_used':
            case 'token_expired':
                return authenticationRoutes.signIn;
            default:
                return null;
        }
    };

    const getHubCallBackMessage = keyMessage => {
        switch (keyMessage) {
            case 'account_not_found':
                return translate('Account not found');
            case 'account_exists':
                return translate('Account already exists');
            case 'payment_error':
                return translate('Please try again later or contact your bank');
            case 'card_is_attached':
                return translate('Your card is successfully attached');
            case 'email_verification_failed':
                return translate('Email verification failed');
            case 'account_verified':
                return translate('Account is successfully verified');
            case 'invalid_token':
                return translate('Your link is not valid');
            case 'token_doesnt_exist':
                return translate('Your link does not exist');
            case 'token_used':
                return translate('Your link has been already used');
            case 'token_expired':
                return translate('Your link is expired');
            case 'backend_translations':
                return [
                    translate('OS'),
                    translate('day'),
                    translate('time'),
                    translate('User'),
                    translate('Euro'),
                    translate('hour'),
                    translate('year'),
                    translate('days'),
                    translate('Name'),
                    translate('Taxi'),
                    translate('Brand'),
                    translate('Level'),
                    translate('today'),
                    translate('weeks'),
                    translate('hours'),
                    translate('month'),
                    translate('years'),
                    translate('Model'),
                    translate('Timers'),
                    translate('Uptime'),
                    translate('Subnet'),
                    translate('config'),
                    translate('months'),
                    translate('Tablet'),
                    translate('Street'),
                    translate('Square'),
                    translate('Indoor'),
                    translate('Totals'),
                    translate('Kiosks'),
                    translate('minute'),
                    translate('second'),
                    translate('Version'),
                    translate('network'),
                    translate('Carrier'),
                    translate('Trigger'),
                    translate('Command'),
                    translate('Changed'),
                    translate('Current'),
                    translate('Time on'),
                    translate('Airport'),
                    translate('Outdoor'),
                    translate('Unrated'),
                    translate('minutes'),
                    translate('seconds'),
                    translate('Strength'),
                    translate('Pin code'),
                    translate('Alive at'),
                    translate('Smil url'),
                    translate('Capacity'),
                    translate('External'),
                    translate('Time off'),
                    translate('Portrait'),
                    translate('1+ stars'),
                    translate('2+ stars'),
                    translate('3+ stars'),
                    translate('4+ stars'),
                    translate('Platform'),
                    translate('Time zone'),
                    translate('Screen id'),
                    translate('resources'),
                    translate('Available'),
                    translate('Simulator'),
                    translate('Unplugged'),
                    translate('US Dollar'),
                    translate('Landscape'),
                    translate('Created at'),
                    translate('Updated at'),
                    translate('Crossroads'),
                    translate('Web version'),
                    translate('Lit version'),
                    translate('APK version'),
                    translate('ADB enabled'),
                    translate('Design name'),
                    translate('OS build id'),
                    translate('Device type'),
                    translate('Reported at'),
                    translate('millisecond'),
                    translate('Last Connected'),
                    translate('Last Disconnected'),
                    translate('Connection Uptime'),
                    translate('Connection Downtime'),
                    translate('Manufacturer'),
                    translate('Product name'),
                    translate('Total memory'),
                    translate('Host version'),
                    translate('Is connected'),
                    translate('5 stars only'),
                    translate('milliseconds'),
                    translate('Armenian Dram'),
                    translate('Russian Ruble'),
                    translate('Bionic version'),
                    translate('Chrome version'),
                    translate('Low power mode'),
                    translate('Led Billboards'),
                    translate('Under 18'),
                    translate('65 and over'),
                    translate('Male'),
                    translate('Other'),
                    translate('Female'),
                    translate('Indoor Screens'),
                    translate('Cpu temperature'),
                    translate('Browser version'),
                    translate('Lit app version'),
                    translate('Is wifi enabled'),
                    translate('Trading Centres'),
                    translate('Shopping Center'),
                    translate('Campaign stopped'),
                    translate('Device year class'),
                    translate('Platform features'),
                    translate('Platform api level'),
                    translate('Bionic app version'),
                    translate('Lit native version'),
                    translate('Media debug enabled'),
                    translate('Cellular generation'),
                    translate('OS build fingerprint'),
                    translate('OS internal build id'),
                    translate('Side loading enabled'),
                    translate('Socket debug enabled'),
                    translate('Bionic native version'),
                    translate('Is internet reachable'),
                    translate('Smil refresh interval'),
                    translate('Stats submit interval'),
                    translate('Playlist debug enabled'),
                    translate('Is connection expensive'),
                    translate('Platform settings version'),
                    translate('Supported cpu architectures'),
                    translate('Email must be a valid email'),
                    translate('Campaign is created or updated'),
                    translate('Advelit | Digital Advertising Platform'),
                    translate('Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character'),
                    translate('Advelit is a dynamic platform that connects advertisers directly with screen owners, simplifying the process of digital advertising. Our marketplace offers a diverse range of digital displays, from retail stores to public venues, allowing advertisers to select the perfect locations for their campaigns'),
                ];
            default:
                return translate(keyMessage);
        }
    };

    useEffect(() => {
        const keyType = 'hcrType';
        const keyMessage = 'hcrMessage';

        const searchQuery = searchParamsConstructor(location.search, [keyType, keyMessage], 'get');

        deleteURLParam(['hcrType', 'hcrMessage']);

        const redirectURL = getRedirectURL(searchQuery?.[keyMessage]);

        !!searchQuery?.[keyType] && import('../../utils/addToast').then(({ default: addToast }) => addToast({
            type: searchQuery[keyType],
            message: <Translate>{getHubCallBackMessage(searchQuery?.[keyMessage])}</Translate>,
        }));

        !!redirectURL && navigate(redirectURL, { replace: true });
    }, [location.search]); // eslint-disable-line

    return null;
};

export default AppUrlListener;
