const products = {
    lit: {
        name: 'com.advelit.lit',
        icon: 'icon-a-elements',
        label: 'Advelit Lit',
    },
    bionic: {
        name: 'com.advelit.bionic',
        icon: 'icon-home-alt',
        label: 'Advelit Bionic',
    },
    host: {
        name: 'com.teamviewer.host.market',
        icon: 'icon-a-stack',
        url: 'https://download.teamviewer.com/download/TeamViewerHost.apk',
        label: 'Host',
    },
    chrome: {
        name: 'com.android.chrome',
        icon: 'icon-chrome',
        label: 'Chrome',
    },
    browser: {
        name: 'acr.browser.barebones',
        icon: 'icon-a-compass',
        label: 'Browser',
    },
};

export default products;
