import { conditionalSpread } from 'clyne-core';

import getStreamChatClient from './getStreamChatClient';

const connectStreamChatUser = async user => {
    if (user?.id) {
        try {
            const id = `${user.id}`;
            const workspaceId = user.workspace?.id;

            const client = getStreamChatClient();

            if (!client.user) {
                await client.connectUser({
                    id,
                    name: user?.givenName || user?.name,
                    image: user.avatarURL,
                    ...conditionalSpread({
                        workspace: {
                            ...(user?.workspace || {}),
                            image: user?.workspace?.avatarURL || '',
                        },
                    }, workspaceId),
                }, client.devToken(id));

                if (workspaceId) {
                    const channel = client.channel('messaging', workspaceId, {
                        name: user?.workspace?.name,
                    });
                    await channel.create();
                    await channel.addMembers([
                        id,
                    ]);
                }
            }
        } catch (e) {
            console.log(e);
        }
    }
};

export default connectStreamChatUser;
