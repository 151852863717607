import classnames from 'classnames';
import PropTypes from 'prop-types';

import Translate from '../translate';
import LazyImage from '../lazyImage';

import './index.scss';

import hybridImage from './media/hybrid.jpeg';
import standardImage from './media/standard.jpeg';
import satelliteImage from './media/satellite.jpeg';
import mutedStandardImage from './media/mutedStandard.jpeg';

const MapLayerSwitcher = props => {
    const {
        selected,
        setSelected,
    } = props;

    const types = {
        roadmap: {
            value: 'roadmap',
            image: standardImage,
            label: <Translate>Standard</Translate>,
        },
        satellite: {
            value: 'satellite',
            image: satelliteImage,
            label: <Translate>Satellite</Translate>,
        },
        hybrid: {
            value: 'hybrid',
            image: hybridImage,
            label: <Translate>Hybrid</Translate>,
        },
        terrain: {
            value: 'terrain',
            image: mutedStandardImage,
            label: <Translate>Terrain</Translate>,
        },
    };

    return (
        <div className='map-layer-switcher'>
            {Object.values(types).map(type => (
                <div
                    key={type.value}
                    onClick={() => setSelected(type.value)}
                    className={classnames(
                        'map-layer-item',
                        {
                            active: selected === type.value,
                        }
                    )}
                >
                    <div className='map-layer-image'>
                        <LazyImage
                            skeleton
                            source={type.image}
                        />
                    </div>
                    <p>{type.label}</p>
                </div>
            ))}
        </div>
    );
};

MapLayerSwitcher.propTypes = {
    selected: PropTypes.string,
    setSelected: PropTypes.func,
};

export default MapLayerSwitcher;
