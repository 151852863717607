import PropTypes from 'prop-types';
import { Suspense } from 'react';

import SplitInput from '../splitInput';

import lazyWithRetry from '../../utils/lazyWithRetry';

import { verificationHashLength } from '../../constants/construct';

const QrScanner = lazyWithRetry(() => import('../qrScanner'));

const SyncScreenForm = props => {
    const {
        setCode,
    } = props;

    return (
        <>
            <SplitInput
                onChange={setCode}
                length={verificationHashLength}
            />
            <Suspense fallback={null}>
                <QrScanner
                    setData={setCode}
                />
            </Suspense>
        </>
    );
};

SyncScreenForm.propTypes = {
    setCode: PropTypes.func,
};

export default SyncScreenForm;
