import { useEffect, useRef, useState } from 'react';
import Marquee from 'react-fast-marquee';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useWindowResize from '../../hooks/useWindowResize';

import './index.scss';

const MarqueeLoop = props => {
    const {
        children,
    } = props;

    const holderRef = useRef(null);
    const contentRef = useRef(null);
    const [fits, setFits] = useState(null);
    const [paused, setPaused] = useState(false);

    const {
        screenWidth,
    } = useWindowResize();

    useEffect(() => {
        if (holderRef.current && contentRef.current && children) {
            setFits(contentRef.current.clientWidth <= holderRef.current.clientWidth);
        }
    }, [screenWidth, children]);

    return (fits || fits === null) ? (
        <div
            ref={holderRef}
            className={classNames(
                'rfm-marquee-container',
                {
                    fits,
                }
            )}
        >
            <div
                ref={contentRef}
                className='rfm-marquee'
            >
                {children}
            </div>
        </div>
    ) : (
        <Marquee
            delay={1}
            play={!paused}
            gradient={false}
            onCycleComplete={() => {
                setPaused(true);
                setTimeout(() => {
                    setPaused(false);
                }, 1000);
            }}
        >
            {children}
        </Marquee>
    );
};

MarqueeLoop.propTypes = {
    children: PropTypes.any,
};

export default MarqueeLoop;
