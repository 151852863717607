import To from '../to';
import Tag from '../tag';

import { mergedTagValueProps } from './props';

import './index.scss';

const MergedTagValue = props => {
    const {
        url,
        href,
        name,
        color,
        target,
        children,
    } = props;

    return (
        <ul className='merged-tag-value'>
            <li>
                <Tag
                    url={url}
                    name={name}
                    size='default'
                    color={color}
                    target={target}
                />
            </li>
            <li>
                {(url || href) ? (
                    <To
                        url={url}
                        href={href}
                        target={target}
                    >
                        <span className='text-ellipsis'>
                            {children}
                        </span>
                    </To>
                ) : (
                    <span className='text-ellipsis'>
                        {children}
                    </span>
                )}
            </li>
        </ul>
    );
};

MergedTagValue.propTypes = mergedTagValueProps;

export default MergedTagValue;
