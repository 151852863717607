import { useRecoilValue } from 'recoil';
import React from 'react';

import Loader from '../loader';
import Screen from '../screen';
import NoData from '../noData';
import Translate from '../translate';

import { favouritesState } from '../../state';

import useConnector from '../../hooks/useConnector';

import { getScreenThumbnail, screenURL } from '../../helpers';

import './index.scss';

const Favourites = () => {
    const favourites = useRecoilValue(favouritesState);

    const { data, initialLoading } = useConnector(`/screens/${favourites?.join(',')}`, null, {}, true, favourites?.length);

    return (
        <div className='favorites-holder'>
            {favourites?.length ? (
                initialLoading ? (
                    <Loader placement='popover'/>
                ) : (
                    <div className='favorites-grid'>
                        {data?.map(screen => (
                            <Screen
                                favoriteAble
                                id={screen.id}
                                key={screen.id}
                                appearance='zip'
                                name={screen.name}
                                rating={screen.rating}
                                to={screenURL(screen.id)}
                                price={screen.averagePricePerMonth}
                                image={getScreenThumbnail(screen.images)}
                            />
                        ))}
                    </div>
                )
            ) : (
                <NoData
                    type='favorite'
                    size='small'
                    subTitle={
                        <Translate>You don't have any favorites. ...yet!</Translate>
                    }
                />
            )}
        </div>
    );
};

export default Favourites;
