import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

import Table from '../table';
import Translate from '../translate';
import MergedTagValue from '../mergedTagValue';

import { workspaceIdState } from '../../state';

import useConnector from '../../hooks/useConnector';

import getCampaignStatus from '../../utils/getCampaignStatus';

import { dateFormat } from '../../constants/construct';

const excludedData = [
    'slot',
    'status',
    'endDate',
    'startDate',
];

const keys = [
    {
        name: 'id',
        span: true,
        label: <Translate>Campaign</Translate>,
        renderer: ({ cell, row }) => (
            <MergedTagValue
                target='_blank'
                name={`ID-${cell.content}`}
                url={`/campaigns?page=1&limit=10&query=${cell.content}`}
            >
                {row.name}
            </MergedTagValue>
        ),
        style: {
            width: '30%',
        },
    },
    {
        name: 'estimatedPrice',
        label: <Translate>Est. Price</Translate>,
        style: {
            width: '16%',
        },
    },
    {
        name: 'totalRevenue',
        label: <Translate>Actual Price</Translate>,
        style: {
            width: '16%',
        },
    },
    {
        name: 'netRevenue',
        label: <Translate>Revenue</Translate>,
        style: {
            width: '16%',
        },
    },
    {
        name: 'campaignStatusTag',
        label: <Translate>Status</Translate>,
        style: {
            width: '16%',
        },
    },
    {
        name: 'dates',
        label: <Translate>Dates</Translate>,
        style: {
            width: '10%',
        },
    },
];

const dateFormatter = date => moment(date, 'YYYY-MM-DD').format(dateFormat);

const ScreenCampaigns = props => {
    const {
        id,
        skeletonCount,
    } = props;

    const workspaceId = useRecoilValue(workspaceIdState);

    const { data, initialLoading } = useConnector(`/workspaces/${workspaceId}/screens/${id}/campaigns`, [], {}, false);

    const tableData = initialLoading ? (
        Array(skeletonCount || keys.length).fill({ skeleton: true })
    ) : (
        data
            .filter(obj => Object.keys(obj).filter(key => !excludedData.includes(key)))
            .map(obj => ({
                ...obj,
                dates: {
                    start: dateFormatter(obj.startDate),
                    end: dateFormatter(obj.endDate),
                },
                campaignStatusTag: getCampaignStatus(obj.status),
            }))
    );

    return (
        <Table
            keys={keys}
            data={tableData}
            actions={row => [
                {
                    icon: {
                        size: 22,
                        type: 'icon-a-arrow-right',
                    },
                    name: <Translate>View Campaign</Translate>,
                    url: `/campaigns?page=1&limit=10&query=${row?.id}`,
                },
            ]}
        />
    );
};

ScreenCampaigns.propTypes = {
    id: PropTypes.number,
    skeletonCount: PropTypes.number,
};

export default ScreenCampaigns;
