import Translate from '../components/translate';

const transactionConstants = {
    type: {
        verification: {
            id: 1,
            key: 'verification',
            name: <Translate>Verification</Translate>,
            color: 'brand',
        },
        charge: {
            id: 2,
            key: 'charge',
            name: <Translate>Charge</Translate>,
            color: 'success',
        },
        refund: {
            id: 3,
            key: 'refund',
            name: <Translate>Refund</Translate>,
            color: 'error',
        },
        hold: {
            id: 4,
            key: 'hold',
            name: <Translate>Hold</Translate>,
            color: 'warning',
        },
    },
    status: {
        draft: {
            id: 1,
            key: 'draft',
            name: <Translate>Draft</Translate>,
            color: 'grey',
        },
        failed: {
            id: 2,
            key: 'failed',
            name: <Translate>Failed</Translate>,
            color: 'error',
        },
        finished: {
            id: 3,
            key: 'finished',
            name: <Translate>Finished</Translate>,
            color: 'success',
        },
        uncollectible: {
            id: 5,
            key: 'uncollectible',
            name: <Translate>Uncollectible</Translate>,
            color: 'error',
        },
        authorized: {
            id: 6,
            key: 'authorized',
            name: <Translate>Authorized</Translate>,
            color: 'brand',
        },
    },
    gateways: {
        AmeriaVPOS: {
            id: 1,
            key: 'ameriaVPOS',
            name: 'Ameria VPOS',
        },
    },
};

export default transactionConstants;
