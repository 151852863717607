import { conditionalSpread } from 'clyne-core';

import features from '../../configs/features';
import products from '../../configs/products';

import translate from '../../utils/translate';
import getAdbConnectCommand from '../../utils/getAdbConnectCommand';

export const screenConsolePrompts = props => {
    const {
        hasLitAppUpdate,
        hasLitNativeUpdate,
        hasBionicAppUpdate,
        hasBionicNativeUpdate,
    } = props;

    return ({
        'RELOAD_LIT_APP': {
            label: translate('Reload Lit App'),
            verb: translate('Reloading Lit App'),
            success: translate('Lit App is successfully reloaded'),
            error: translate(`Couldn't Reload Lit App`),
        },
        'INSTALL_LIT_APP': {
            label: hasLitAppUpdate ? translate('Update Lit App') : translate('Install Lit App'),
            verb: hasLitAppUpdate ? translate('Updating Lit App') : translate('Installing Lit App'),
            success: hasLitAppUpdate ? translate('Lit App is successfully updated') : translate('Lit App is successfully installed'),
            error: hasLitAppUpdate ? translate(`Couldn't Update Lit App`) : translate(`Couldn't Install Lit App`),
        },
        'INSTALL_LIT_NATIVE': {
            label: hasLitNativeUpdate ? translate('Update Lit Native') : translate('Install Lit Native'),
            verb: hasLitNativeUpdate ? translate('Updating Lit Native') : translate('Installing Lit Native'),
            success: hasLitNativeUpdate ? translate('Lit Native is successfully updated') : translate('Lit Native is successfully installed'),
            error: hasLitNativeUpdate ? translate(`Couldn't Update Lit Native`) : translate(`Couldn't Install Lit Native`),
        },
        'LAUNCH_LIT_NATIVE': {
            label: translate('Launch Lit Native'),
            verb: translate('Launching Lit Native'),
            success: translate('Lit Native is successfully launched'),
            error: translate(`Couldn't Launch Lit Native`),
        },
        ...conditionalSpread({
            'RELOAD_BIONIC_APP': {
                label: translate('Reload Bionic App'),
                verb: translate('Reloading Bionic App'),
                success: translate('Bionic App is successfully reloaded'),
                error: translate(`Couldn't Reload Bionic App`),
            },
            'INSTALL_BIONIC_APP': {
                label: hasBionicAppUpdate ? translate('Update Bionic App') : translate('Install Bionic App'),
                verb: hasBionicAppUpdate ? translate('Updating Bionic App') : translate('Installing Bionic App'),
                success: hasBionicAppUpdate ? translate('Bionic App is successfully updated') : translate('Bionic App is successfully installed'),
                error: hasBionicAppUpdate ? translate(`Couldn't Update Bionic App`) : translate(`Couldn't Install Bionic App`),
            },
            'INSTALL_BIONIC_NATIVE': {
                label: hasBionicNativeUpdate ? translate('Update Bionic Native') : translate('Install Bionic Native'),
                verb: hasBionicNativeUpdate ? translate('Updating Bionic Native') : translate('Installing Bionic Native'),
                success: hasBionicNativeUpdate ? translate('Bionic Native is successfully updated') : translate('Bionic Native is successfully installed'),
                error: hasBionicNativeUpdate ? translate(`Couldn't Update Bionic Native`) : translate(`Couldn't Install Bionic Native`),
            },
            'LAUNCH_BIONIC_NATIVE': {
                label: translate('Launch Bionic Native'),
                verb: translate('Launching Bionic Native'),
                success: translate('Bionic Native is successfully launched'),
                error: translate(`Couldn't Launch Bionic Native`),
            },
        }, !!features.bionic),
        'INSTALL_HOST_NATIVE': {
            label: translate('Install Host Native'),
            verb: translate('Installing Host Native'),
            success: translate('Host Native is successfully installed'),
            error: translate(`Couldn't Install Host Native`),
        },
        'LAUNCH_HOST_NATIVE': {
            label: translate('Launch Host Native'),
            verb: translate('Launching Host Native'),
            success: translate('Host Native is successfully launched'),
            error: translate(`Couldn't Launch Host Native`),
        },
        'LAUNCH_CHROME': {
            label: translate('Launch Chrome'),
            verb: translate('Launching Chrome'),
            success: translate('Chrome is successfully launched'),
            error: translate(`Couldn't Launch Chrome`),
        },
        'LAUNCH_BROWSER': {
            label: translate('Launch Browser'),
            verb: translate('Launching Browser'),
            success: translate('Browser is successfully launched'),
            error: translate(`Couldn't Launch Browser`),
        },
        'LAUNCH_NETWORK_SETTINGS': {
            label: translate('Launch Network Settings'),
            verb: translate('Launching Network Settings'),
            success: translate('Network Settings is successfully launched'),
            error: translate(`Couldn't Launch Network Settings`),
        },
        'LAUNCH_EIP_SETTINGS': {
            label: translate('Launch EIP Settings'),
            verb: translate('Launching EIP Settings'),
            success: translate('EIP Settings is successfully launched'),
            error: translate(`Couldn't Launch EIP Settings`),
        },
        'LAUNCH_IIP_SETTINGS': {
            label: translate('Launch IIP Settings'),
            verb: translate('Launching IIP Settings'),
            success: translate('IIP Settings is successfully launched'),
            error: translate(`Couldn't Launch IIP Settings`),
        },
        'LAUNCH_PLATFORM_SETTINGS': {
            label: translate('Launch Platform Settings'),
            verb: translate('Launching Platform Settings'),
            success: translate('Platform Settings is successfully launched'),
            error: translate(`Couldn't Launch Platform Settings`),
        },
        'LAUNCH_NATIVE_SETTINGS': {
            label: translate('Launch Native Settings'),
            verb: translate('Launching Native Settings'),
            success: translate('Native Settings is successfully launched'),
            error: translate(`Couldn't Launch Native Settings`),
        },
        'LAUNCH_DATE_TIME_SETTINGS': {
            label: translate('Launch Date/Time Settings'),
            verb: translate('Launching Date/Time Settings'),
            success: translate('Date/Time Settings is successfully launched'),
            error: translate(`Couldn't Launch Date/Time Settings`),
        },
        'TURN_ON_SCREEN': {
            label: translate('Turn On Screen'),
            verb: translate('Turning On Screen'),
            success: translate('Screen is successfully turned on'),
            error: translate(`Couldn't Turn On Screen`),
        },
        'TURN_OFF_SCREEN': {
            label: translate('Turn Off Screen'),
            verb: translate('Turning Off Screen'),
            success: translate('Screen is successfully turned off'),
            error: translate(`Couldn't Turn Off Screen`),
        },
        'RESTART_DEVICE': {
            label: translate('Restart Device'),
            verb: translate('Restarting Device'),
            success: translate('Device is successfully restarted'),
            error: translate(`Couldn't Restart Device`),
        },
        'RUN_COMMAND': {
            label: translate('Run Command'),
            verb: translate('Running Command'),
            error: title => translate(`Couldn't Run Command on _NAME_`, { _NAME_: title }),
            success: title => translate('Command is successfully executed on _NAME_', { _NAME_: title }),
        },
        'ENABLE_ADB': {
            label: translate('Enable ADB'),
            verb: translate('Enabling ADB'),
            success: translate('ADB is successfully enabled'),
            error: translate(`Couldn't Enable ADB`),
        },
        'DISABLE_ADB': {
            label: translate('Disable ADB'),
            verb: translate('Disabling ADB'),
            success: translate('ADB is successfully disabled'),
            error: translate(`Couldn't Disable ADB`),
        },
        'ENABLE_MEDIA_DEBUG': {
            label: translate('Enable Media Debug'),
            verb: translate('Enabling Media Debug'),
            success: translate('Media Debug is successfully enabled'),
            error: translate(`Couldn't Enable Media Debug`),
        },
        'DISABLE_MEDIA_DEBUG': {
            label: translate('Disable Media Debug'),
            verb: translate('Disabling Media Debug'),
            success: translate('Media Debug is successfully disabled'),
            error: translate(`Couldn't Disable Media Debug`),
        },
        'ENABLE_KIOSK_MODE': {
            label: translate('Enable Kiosk Mode'),
            verb: translate('Enabling Kiosk Mode'),
            success: translate('Kiosk Mode is successfully enabled'),
            error: translate(`Couldn't Enable Kiosk Mode`),
        },
        'DISABLE_KIOSK_MODE': {
            label: translate('Disable Kiosk Mode'),
            verb: translate('Disabling Kiosk Mode'),
            success: translate('Kiosk Mode is successfully disabled'),
            error: translate(`Couldn't Disable Kiosk Mode`),
        },
        'ENABLE_SOCKET_DEBUG': {
            label: translate('Enable Socket Debug'),
            verb: translate('Enabling Socket Debug'),
            success: translate('Socket Debug is successfully enabled'),
            error: translate(`Couldn't Enable Socket Debug`),
        },
        'DISABLE_SOCKET_DEBUG': {
            label: translate('Disable Socket Debug'),
            verb: translate('Disabling Socket Debug'),
            success: translate('Socket Debug is successfully disabled'),
            error: translate(`Couldn't Disable Socket Debug`),
        },
        'ENABLE_PLAYLIST_DEBUG': {
            label: translate('Enable Playlist Debug'),
            verb: translate('Enabling Playlist Debug'),
            success: translate('Playlist Debug is successfully enabled'),
            error: translate(`Couldn't Enable Playlist Debug`),
        },
        'DISABLE_PLAYLIST_DEBUG': {
            label: translate('Disable Playlist Debug'),
            verb: translate('Disabling Playlist Debug'),
            success: translate('Playlist Debug is successfully disabled'),
            error: translate(`Couldn't Disable Playlist Debug`),
        },
    });
};

export const connectionTypeMapping = {
    wifi: {
        icon: 'icon-wifi',
        label: 'WiFi',
    },
    none: {
        icon: 'icon-a-cross-octagon',
        label: translate('None'),
    },
    cellular: {
        icon: 'icon-a-gps',
        label: translate('Cellular'),
    },
    ethernet: {
        icon: 'icon-brackets',
        label: 'Ethernet',
    },
    unknown: {
        icon: 'icon-a-question',
        label: translate('Unknown'),
    },
    bluetooth: {
        icon: 'icon-bluetooth',
        label: 'Bluetooth',
    },
    wimax: {
        icon: 'icon-wifi',
        label: 'WiMax',
    },
    vpn: {
        icon: 'icon-a-shield-check',
        label: 'VPN',
    },
    other: {
        icon: 'icon-a-question',
        label: translate('Other'),
    },
};

export const deviceActions = {
    reloadLitApp: 'RELOAD_LIT_APP',
    installLitApp: 'INSTALL_LIT_APP',
    installLitNative: 'INSTALL_LIT_NATIVE',
    launchLitNative: 'LAUNCH_LIT_NATIVE',
    reloadBionicApp: 'RELOAD_BIONIC_APP',
    installBionicApp: 'INSTALL_BIONIC_APP',
    installBionicNative: 'INSTALL_BIONIC_NATIVE',
    launchBionicNative: 'LAUNCH_BIONIC_NATIVE',
    installHostNative: 'INSTALL_HOST_NATIVE',
    launchHostNative: 'LAUNCH_HOST_NATIVE',
    launchChrome: 'LAUNCH_CHROME',
    launchBrowser: 'LAUNCH_BROWSER',
    launchNetworkSettings: 'LAUNCH_NETWORK_SETTINGS',
    launchEIPSettings: 'LAUNCH_EIP_SETTINGS',
    launchIIPSettings: 'LAUNCH_IIP_SETTINGS',
    launchPlatformSettings: 'LAUNCH_PLATFORM_SETTINGS',
    launchNativeSettings: 'LAUNCH_NATIVE_SETTINGS',
    launchDateTimeSettings: 'LAUNCH_DATE_TIME_SETTINGS',
    turnOnScreen: 'TURN_ON_SCREEN',
    turnOffScreen: 'TURN_OFF_SCREEN',
    restartDevice: 'RESTART_DEVICE',
    runCommand: 'RUN_COMMAND',
    enableADB: 'ENABLE_ADB',
    disableADB: 'DISABLE_ADB',
    enableMediaDebug: 'ENABLE_MEDIA_DEBUG',
    disableMediaDebug: 'DISABLE_MEDIA_DEBUG',
    enableKioskMode: 'ENABLE_KIOSK_MODE',
    disableKioskMode: 'DISABLE_KIOSK_MODE',
    enableSocketDebug: 'ENABLE_SOCKET_DEBUG',
    disableSocketDebug: 'DISABLE_SOCKET_DEBUG',
    enablePlaylistDebug: 'ENABLE_PLAYLIST_DEBUG',
    disablePlaylistDebug: 'DISABLE_PLAYLIST_DEBUG',
};

export const getDeviceActionLoadingIcon = (item, loadingActions) => {
    const actions = [
        item.action,
        ...conditionalSpread([...(item.menu?.map(item => item.action) || [])], item.menu?.length),
    ].filter(i => i);

    if (item.loading || loadingActions.some(action => actions.includes(action))) {
        return 'icon-a-loader';
    }
};

export const getDeviceActionSubIcon = (item, loadingActions) => {
    const loadingIcon = getDeviceActionLoadingIcon({ action: item }, loadingActions);

    if (loadingIcon) {
        return loadingIcon;
    }

    const action = item?.action || item || '';

    if (item?.menu) {
        return 'icon-a-more-horiz';
    }

    if (action.toLowerCase().includes('install')) {
        if (action.toLowerCase().includes('app')) {
            return 'icon-a-import';
        }

        return 'icon-import-alt';
    }

    switch (action) {
        case deviceActions.runCommand:
            return 'icon-maximize-alt';
        case deviceActions.turnOffScreen:
        case deviceActions.turnOnScreen:
        case deviceActions.restartDevice:
            return 'icon-a-arrow-right';
        case deviceActions.reloadLitApp:
        case deviceActions.reloadBionicApp:
            return 'icon-a-rotate-cw';
        case deviceActions.launchLitNative:
        case deviceActions.launchBionicNative:
            return 'icon-scale';
        default:
            return 'icon-a-external-link';
    }
};

export const getDeviceActionLeadingIcon = (item, loadingActions) => getDeviceActionLoadingIcon(item, loadingActions) ?? item.icon;

export const getScreenConsoleUIData = props => {
    const {
        hasLit,
        online,
        hasHost,
        onAction,
        screenOn,
        hasBionic,
        hasChrome,
        adbEnabled,
        hasBrowser,
        externalIp,
        internalIp,
        loadingActions,
        connectionType,
        hasLitAppUpdate,
        kioskModeEnabled,
        mediaDebugEnabled,
        hasLitNativeUpdate,
        socketDebugEnabled,
        hasBionicAppUpdate,
        hasPlatformSettings,
        playlistDebugEnabled,
        hasBionicNativeUpdate,
    } = props;

    const constructConsoleProductSubmenu = props => {
        const {
            key,
        } = props;

        return ([
            {
                name: translate('Reload'),
                action: deviceActions[`reload${key}App`],
                onClick: () => onAction(deviceActions[`reload${key}App`]),
                icon: getDeviceActionSubIcon(deviceActions[`reload${key}App`], loadingActions),
            },
            {
                divider: true,
            },
            {
                action: deviceActions[`install${key}App`],
                onClick: () => onAction(deviceActions[`install${key}App`]),
                badge: key === 'Lit' ? hasLitAppUpdate : hasBionicAppUpdate,
                icon: getDeviceActionSubIcon(deviceActions[`install${key}App`], loadingActions),
                name: (key === 'Lit' ? hasLitAppUpdate : hasBionicAppUpdate) ? translate('Update App') : translate('Reinstall App'),
            },
            {
                action: deviceActions[`install${key}Native`],
                onClick: () => onAction(deviceActions[`install${key}Native`]),
                icon: getDeviceActionSubIcon(deviceActions[`install${key}Native`], loadingActions),
                badge: key === 'Lit' ? hasLitNativeUpdate : hasBionicNativeUpdate,
                name: key === 'Lit' ? (
                    hasLitNativeUpdate ? translate('Update Lit') : translate('Reinstall Lit')
                ) : (
                    hasBionicNativeUpdate ? translate('Update Bionic') : translate('Reinstall Bionic')
                ),
            },
            {
                divider: true,
            },
            {
                action: deviceActions[`launch${key}Native`],
                onClick: () => onAction(deviceActions[`launch${key}Native`]),
                icon: getDeviceActionSubIcon(deviceActions[`launch${key}Native`], loadingActions),
                name: key === 'Lit' ? translate('Open Lit') : translate('Open Bionic'),
            },
        ]);
    };

    const deviceActionMapping = {
        lit: {
            label: hasLit ? 'Lit' : translate('Get Lit'),
            icon: products.lit.icon,
        },
        bionic: {
            label: hasBionic ? 'Bionic' : translate('Get Bionic'),
            icon: products.bionic.icon,
        },
        host: {
            label: hasHost ? 'Host' : translate('Get Host'),
            icon: products.host.icon,
        },
        browser: {
            label: translate('Browser'),
            icon: 'icon-a-compass',
        },
        chrome: {
            label: 'Chrome',
            icon: 'icon-chrome',
        },
        externalIp: {
            label: externalIp,
            icon: 'icon-fingerprint',
        },
        internalIp: {
            label: internalIp,
            icon: 'icon-route1',
        },
        adb: {
            icon: 'icon-devices',
            label: 'ADB',
        },
        mediaDebug: {
            icon: 'icon-grid-columns',
            label: translate('Media Debug'),
        },
        kioskMode: {
            icon: 'icon-a-slider-horizontal',
            label: translate('Kiosk Mode'),
        },
        socketDebug: {
            icon: 'icon-apperture',
            label: translate('Socket Debug'),
        },
        playlistDebug: {
            icon: 'icon-playlist-play',
            label: translate('Playlist Debug'),
        },
        platformSettings: {
            label: translate('Platform Settings'),
            icon: 'icon-a-nut',
        },
        nativeSettings: {
            label: translate('Native Settings'),
            icon: 'icon-a-gear',
        },
        restartDevice: {
            label: translate('Restart Device'),
            icon: 'icon-a-refresh-cw',
        },
        runCommand: {
            label: translate('Run Command'),
            icon: 'icon-terminal',
        },
        time: {
            icon: 'icon-a-time',
            type: 'time',
        },
        screen: {
            label: screenOn ? translate('Turn off Screen') : translate('Turn on Screen'),
            icon: screenOn ? 'icon-a-monitor-slash' : 'icon-a-monitor',
        },
    };

    return ([
        {
            order: 5,
            icon: 'icon-toggle-on',
            label: translate('Preferences'),
            children: [
                {
                    slash: adbEnabled,
                    checked: adbEnabled,
                    label: deviceActionMapping.adb.label,
                    icon: deviceActionMapping.adb.icon,
                    action: adbEnabled ? deviceActions.disableADB : deviceActions.enableADB,
                    onChange: () => onAction(adbEnabled ? deviceActions.disableADB : deviceActions.enableADB),
                },
                {
                    slash: mediaDebugEnabled,
                    checked: mediaDebugEnabled,
                    icon: deviceActionMapping.mediaDebug.icon,
                    label: deviceActionMapping.mediaDebug.label,
                    action: mediaDebugEnabled ? deviceActions.disableMediaDebug : deviceActions.enableMediaDebug,
                    onChange: () => onAction(mediaDebugEnabled ? deviceActions.disableMediaDebug : deviceActions.enableMediaDebug),
                },
                {
                    slash: kioskModeEnabled,
                    checked: kioskModeEnabled,
                    icon: deviceActionMapping.kioskMode.icon,
                    label: deviceActionMapping.kioskMode.label,
                    action: kioskModeEnabled ? deviceActions.disableKioskMode : deviceActions.enableKioskMode,
                    onChange: () => onAction(kioskModeEnabled ? deviceActions.disableKioskMode : deviceActions.enableKioskMode),
                },
                {
                    slash: socketDebugEnabled,
                    checked: socketDebugEnabled,
                    icon: deviceActionMapping.socketDebug.icon,
                    label: deviceActionMapping.socketDebug.label,
                    action: socketDebugEnabled ? deviceActions.disableSocketDebug : deviceActions.enableSocketDebug,
                    onChange: () => onAction(socketDebugEnabled ? deviceActions.disableSocketDebug : deviceActions.enableSocketDebug),
                },
                {
                    slash: playlistDebugEnabled,
                    checked: playlistDebugEnabled,
                    icon: deviceActionMapping.playlistDebug.icon,
                    label: deviceActionMapping.playlistDebug.label,
                    action: playlistDebugEnabled ? deviceActions.disablePlaylistDebug : deviceActions.enablePlaylistDebug,
                    onChange: () => onAction(playlistDebugEnabled ? deviceActions.disablePlaylistDebug : deviceActions.enablePlaylistDebug),
                },
            ],
        },
        {
            order: 1,
            label: translate('Apps'),
            children: [
                {
                    label: deviceActionMapping.lit.label,
                    icon: deviceActionMapping.lit.icon,
                    badge: hasLitAppUpdate || hasLitNativeUpdate,
                    ...(hasLit ? ({
                        menu: constructConsoleProductSubmenu({
                            key: 'Lit',
                        }),
                    }) : ({
                        action: deviceActions.installLitNative,
                    })),
                },
                ...conditionalSpread([
                    {
                        label: deviceActionMapping.bionic.label,
                        icon: deviceActionMapping.bionic.icon,
                        badge: hasBionicAppUpdate || hasBionicNativeUpdate,
                        ...(hasBionic ? ({
                            menu: constructConsoleProductSubmenu({
                                key: 'Bionic',
                            }),
                        }) : ({
                            action: deviceActions.installBionicNative,
                        })),
                    },
                ], !!features.bionic),
                {
                    label: deviceActionMapping.host.label,
                    icon: deviceActionMapping.host.icon,
                    action: hasHost ? deviceActions.launchHostNative : deviceActions.installHostNative,
                },
                ...conditionalSpread([
                    {
                        label: deviceActionMapping.browser.label,
                        icon: deviceActionMapping.browser.icon,
                        action: deviceActions.launchBrowser,
                    },
                ], hasBrowser),
                ...conditionalSpread([
                    {
                        label: deviceActionMapping.chrome.label,
                        icon: deviceActionMapping.chrome.icon,
                        action: deviceActions.launchChrome,
                    },
                ], hasChrome),
            ],
        },
        {
            order: 2,
            flat: true,
            label: translate('Network'),
            children: [
                {
                    slash: !online,
                    icon: connectionTypeMapping[connectionType]?.icon || connectionTypeMapping.none.icon,
                    label: connectionTypeMapping[connectionType]?.label || connectionTypeMapping.none.label,
                    action: deviceActions.launchNetworkSettings,
                },
                {
                    label: deviceActionMapping.externalIp.label,
                    icon: deviceActionMapping.externalIp.icon,
                    action: deviceActions.launchEIPSettings,
                    copyText: getAdbConnectCommand(externalIp),
                },
                ...conditionalSpread([
                    {
                        label: deviceActionMapping.internalIp.label,
                        icon: deviceActionMapping.internalIp.icon,
                        action: deviceActions.launchIIPSettings,
                        copyText: getAdbConnectCommand(internalIp),
                    },
                ], externalIp !== internalIp),
            ],
        },
        {
            order: 3,
            flat: true,
            label: translate('System'),
            children: [
                ...conditionalSpread([
                    {
                        icon: deviceActionMapping.platformSettings.icon,
                        label: deviceActionMapping.platformSettings.label,
                        action: deviceActions.launchPlatformSettings,
                    },
                ], hasPlatformSettings),
                {
                    icon: deviceActionMapping.nativeSettings.icon,
                    label: deviceActionMapping.nativeSettings.label,
                    action: deviceActions.launchNativeSettings,
                },
                {
                    icon: deviceActionMapping.time.icon,
                    type: deviceActionMapping.time.type,
                    label: deviceActionMapping.time.label,
                    action: deviceActions.launchDateTimeSettings,
                },
            ],
        },
        {
            order: 4,
            flat: true,
            label: translate('Device'),
            children: [
                {
                    icon: deviceActionMapping.screen.icon,
                    label: deviceActionMapping.screen.label,
                    action: screenOn ? deviceActions.turnOffScreen : deviceActions.turnOnScreen,
                },
                {
                    icon: deviceActionMapping.restartDevice.icon,
                    label: deviceActionMapping.restartDevice.label,
                    action: deviceActions.restartDevice,
                },
                {
                    icon: deviceActionMapping.runCommand.icon,
                    label: deviceActionMapping.runCommand.label,
                    action: deviceActions.runCommand,
                },
            ],
        },
    ].sort((a, b) => b.children.length - a.children.length));
};
