import { useRecoilValue, useResetRecoilState } from 'recoil';
import React, { Suspense, useEffect } from 'react';

import SyncScreenWizard from '../syncScreenWizard';

import useFullLocation from '../../hooks/useFullLocation';

import lazyWithRetry from '../../utils/lazyWithRetry';

import { campaignDetailsState, reviewCampaignState, signedInState } from '../../state';

const ReviewCampaign = lazyWithRetry(() => import('../reviewCampaign').then());
const CampaignDetails = lazyWithRetry(() => import('../campaignDetails').then());

const SharedComponents = () => {
    const signedIn = useRecoilValue(signedInState);
    const reviewCampaign = useRecoilValue(reviewCampaignState);
    const campaignDetails = useRecoilValue(campaignDetailsState);
    const resetReviewCampaign = useResetRecoilState(reviewCampaignState);
    const resetCampaignDetails = useResetRecoilState(campaignDetailsState);

    const location = useFullLocation();

    useEffect(() => {
        resetReviewCampaign();
        resetCampaignDetails();
    }, [location]); // eslint-disable-line

    return (
        <>
            {signedIn && (
                <>
                    <Suspense fallback={null}>
                        <ReviewCampaign
                            id={reviewCampaign.id}
                            mode={reviewCampaign.mode}
                            active={reviewCampaign.active}
                            onClose={(hasChange, declined) => {
                                (hasChange && typeof reviewCampaign.callBack === 'function') && reviewCampaign.callBack(declined);
                                resetReviewCampaign();
                            }}
                        />
                    </Suspense>
                    <Suspense fallback={null}>
                        <CampaignDetails
                            id={campaignDetails.id}
                            mode={campaignDetails.mode}
                            onClose={resetCampaignDetails}
                            active={campaignDetails.active}
                        />
                    </Suspense>
                </>
            )}
            <SyncScreenWizard />
        </>
    );
};

export default SharedComponents;
