import React, { Fragment, useEffect, useState } from 'react';
import { conditionalSpread } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import Loader from '../loader';
import CopyText from '../copyText';
import Translate from '../translate';
import ScreenLocation from '../screenLocation';

import { dynamicDataTriggerState, workspaceIdState } from '../../state';

import { toSentenceCase, capitalizeFirstLetter } from '../../helpers';

import translate from '../../utils/translate';
import bytesToOtherSizes from '../../utils/bytesToOtherSizes';
import durationCalculator from '../../utils/durationCalculator';
import getAdbConnectCommand from '../../utils/getAdbConnectCommand';
import humanizeDurationWithTranslation from '../../utils/humanizeDurationWithTranslation';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

import './index.scss';

const DeviceInformation = props => {
    const {
        cache,
        aliveAt,
        isOnline,
        screenId,
        deviceUid,
    } = props;

    const { isMobile } = useDevice();

    const workspaceId = useRecoilValue(workspaceIdState);
    const dynamicDataTrigger = useRecoilValue(dynamicDataTriggerState);

    const [localCache, setLocalCache] = useState(0);

    const { data, initialLoading } = useConnector(`/workspaces/${workspaceId}/screens/${screenId}/device/${deviceUid}`, {}, {}, false, true, 'replace', localCache);

    useEffect(() => {
        !initialLoading && setLocalCache(val => val + 1);
    }, [cache, dynamicDataTrigger]); // eslint-disable-line

    const ip = data?.lit?.network?.ip;
    const lastConnected = data?.lit?.network?.lastConnected;
    const lastDisconnected = data?.lit?.network?.lastDisconnected;

    const storageRenderer = (capacity, freeSpace) => {
        const disabled = !capacity && !freeSpace;

        return (
            <div
                className={classNames(
                    `storage-status-holder`,
                    {
                        disabled,
                    }
                )}
            >
                {!disabled && (
                    <small
                        style={{
                            width: `${100 - (freeSpace * 100 / capacity)}%`,
                        }}
                    />
                )}
                <div className='absolutely-splash storage-capacity'>
                    {disabled ? (
                        <Translate>Not Connected</Translate>
                    ) : (
                        `${bytesToOtherSizes(freeSpace)} ${translate('available of')} ${bytesToOtherSizes(capacity)}`
                    )}
                </div>
            </div>
        );
    };

    const formatData = (item, key) => typeof item === 'object' ? item : typeof item === 'boolean' ? (
        key === 'screen' ? (item ? translate('On') : translate('Off')) : (item ? translate('Yes') : translate('No'))
    ) : item;

    const content = [
        {
            cols: data?.lit?.resources?.external?.capacity && data?.lit?.resources?.internal?.capacity !== data?.lit?.resources?.external?.capacity ? 2 : 1,
            span: true,
            name: translate('Storage'),
            data: [
                {
                    name: translate('Internal'),
                    children: storageRenderer(data?.lit?.resources?.internal?.capacity, data?.lit?.resources?.internal?.available),
                },
                ...conditionalSpread([
                    {
                        name: translate('Removable Number'),
                        children: storageRenderer(data?.lit?.resources?.external?.capacity, data?.lit?.resources?.external?.available),
                    },
                ], data?.lit?.resources?.external?.capacity && data?.lit?.resources?.internal?.capacity !== data?.lit?.resources?.external?.capacity),
            ],
        },
        {
            span: true,
            children: (
                <div className='v-divider' />
            ),
        },
        ...conditionalSpread([
            {
                name: translate('General'),
                data: Object.keys(data?.lit || {}).filter(key => typeof data?.lit[key] !== 'object' && !Array.isArray(data?.lit[key]))?.map(key => ({
                    name: key,
                    textModification: true,
                    children: formatData(data?.lit[key], key),
                })),
            },
            ...Object.keys(data?.lit || {}).filter(key => typeof data?.lit[key] === 'object' && !Array.isArray(data?.lit[key])).map(key => ({
                name: key,
                data: Object.keys(data?.lit[key] || {}).map(itemKey => ({
                    name: itemKey,
                    textModification: true,
                    children: formatData(data?.lit[key][itemKey], key),
                })),
            })),
        ].sort((a, b) => b?.data?.length - a?.data?.length), Object.values(data?.lit || {}).length),
    ];

    const valueRenderer = (value, key) => (
        <CopyText text={key === 'adbEnabled' && ip ? getAdbConnectCommand(ip) : value}>
            <span className='text-ellipsis'>
                {(() => {
                    const data = () => {
                        switch (key) {
                            case 'aliveAt':
                            case 'current':
                            case 'changed':
                            case 'createdAt':
                            case 'updatedAt':
                            case 'reportedAt':
                            case 'lastConnected':
                            case 'lastDisconnected':
                                return moment(value).format('DD MMM YYYY HH:mm:ss');
                            case 'connectionUptime':
                                return !!lastConnected ? durationCalculator(lastConnected, isOnline ? new Date() : aliveAt, true) : null;
                            case 'connectionDowntime':
                                const data = Math.max.apply(Math, [lastConnected, new Date(aliveAt).valueOf()].filter(Boolean));
                                return data?.length ? durationCalculator(lastDisconnected, isOnline ? lastConnected : data, true) : null;
                            case 'uptime':
                                return humanizeDurationWithTranslation(value);
                            case 'trigger':
                                return toSentenceCase(value);
                            case 'percentage':
                                return `${value}%`;
                            case 'capacity':
                            case 'available':
                            case 'memoryUsed':
                            case 'totalMemory':
                                return bytesToOtherSizes(value);
                            case 'slotDuration':
                            case 'smilRefreshInterval':
                            case 'statsSubmitInterval':
                                return humanizeDurationWithTranslation(value * 1000);
                            default:
                                return value;
                        }
                    };

                    const formatted = `${data() || ''}`;

                    return formatted ? translate([...formatted].every(i => i === i.toUpperCase()) ? capitalizeFirstLetter(formatted) : formatted) : (
                        <Translate>Unknown</Translate>
                    );
                })()}
            </span>
        </CopyText>
    );

    const subItemRenderer = (key, value) => (key !== 'level' && value) && (
        <div
            key={JSON.stringify({ key, value })}
            className='device-info-kv-sub-item'
        >
            <h5 className='text-ellipsis'>{translate(toSentenceCase(key))}</h5>
            {valueRenderer(value, key)}
        </div>
    );

    return (initialLoading || !Object.keys(data || {}).length) ? (
        <Loader placement='static' />
    ) : (
        <div
            className={classNames(
                'device-information-holder',
                'grid',
            )}
        >
            {!!(data?.lit?.location?.latitude && data?.lit?.location?.longitude) && (
                <div className='span'>
                    <ScreenLocation
                        size='wide'
                        icon='icon-a-processor'
                        latitude={data?.lit?.location?.latitude}
                        longitude={data?.lit?.location?.longitude}
                    />
                </div>
            )}
            {content.map((card, index) => !!card.data?.some(section => section.children) && (
                <Fragment key={index}>
                    {!!index && (
                        <div className='v-divider' />
                    )}
                    <div
                        className={classNames({
                            'span': card.span,
                        })}
                    >
                        {card.name ? (
                            <ul className='device-information'>
                                <li>
                                    <h2>
                                        {translate(card.name)}
                                    </h2>
                                </li>
                                <li>
                                    <div
                                        className={classNames(
                                            `grid`,
                                            'gap-2',
                                            isMobile ? card.span ? 'cols-1' : 'cols-2' : (card.cols ? `cols-${card.cols}` : 'cols-5'),
                                        )}
                                    >
                                        {card.data.map((section, index) => !!section.children && section.children !== '0.0.0.0' && section.name !== 'duid' && (
                                            <ul
                                                key={index}
                                                className='device-info-kv'
                                            >
                                                {!!section.name && (
                                                    <li>
                                                        <h4 className='text-ellipsis'>
                                                            {translate(toSentenceCase(section.name))}
                                                        </h4>
                                                    </li>
                                                )}
                                                <li
                                                    className={classNames(
                                                        'text-ellipsis',
                                                        {
                                                            'not-capitalize': section.textModification && !['type'].includes(section.name),
                                                        }
                                                    )}
                                                >
                                                    {initialLoading ? (
                                                        <div className='skeleton-wave' />
                                                    ) : Array.isArray(section.children) ? valueRenderer(section.children.join(', '), section.name) : typeof section.children === 'object' ? (section.children.props ? section.children : (
                                                        section.children.array ? (
                                                            <div
                                                                className={classNames(
                                                                    'device-info-kv-nested-item',
                                                                    {
                                                                        'autofill': card.span,
                                                                    }
                                                                )}
                                                            >
                                                                {section.children.array.map(item => Object.keys(item).map(key => subItemRenderer(key, Array.isArray(item[key]) ? item[key].map(word => translate(toSentenceCase(word))).join(', ') : item[key])))}
                                                            </div>
                                                        ) : Object.keys(section.children).map(key => subItemRenderer(key, section.children[key]))
                                                    )) : valueRenderer(section.children, section.name)}
                                                </li>
                                            </ul>
                                        ))}
                                    </div>
                                </li>
                            </ul>
                        ) : (
                            card.children
                        )}
                    </div>
                </Fragment>
            ))}
        </div>
    );
};

DeviceInformation.propTypes = {
    cache: PropTypes.number,
    aliveAt: PropTypes.string,
    isOnline: PropTypes.bool,
    screenId: PropTypes.number,
    deviceUid: PropTypes.string,
};

export default DeviceInformation;
