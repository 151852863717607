import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { dynamicDataState } from '../../state';

import { versionCheck, versionToNum } from '../../helpers';

import './index.scss';

const VersionReport = props => {
    const {
        app,
        native,
        product,
        simulator,
    } = props;

    const dynamicData = useRecoilValue(dynamicDataState);

    const availableVersion = dynamicData?.availableVersions?.[product];

    const hasNativeUpdate = versionToNum(native) < versionToNum(availableVersion?.native);
    const hasAppUpdate = !hasNativeUpdate && versionToNum(app) < versionToNum(availableVersion?.app);

    return versionCheck(native) && (
        <div className='version-report-holder'>
            <p>
                {product === 'lit' ? 'Lit' : 'Bionic'}
            </p>
            <strong
                className={classNames(
                    {
                        'active': hasNativeUpdate,
                    },
                )}
            >
                {versionCheck(app) && (
                    <span
                        className={classNames(
                            {
                                'color-warning': hasAppUpdate && !simulator,
                            },
                        )}
                    >
                        {app}
                    </span>
                )}
                {(versionCheck(app) && versionCheck(native)) && (
                    <span>/</span>
                )}
                {versionCheck(native) && (
                    <span
                        className={classNames(
                            {
                                'color-warning': hasNativeUpdate && !simulator,
                            },
                        )}
                    >
                        {native}
                    </span>
                )}
            </strong>
        </div>
    );
};

VersionReport.propTypes = {
    app: PropTypes.string,
    native: PropTypes.string,
    product: PropTypes.oneOf(['lit', 'bionic']),
    simulator: PropTypes.bool,
};

export default VersionReport;
