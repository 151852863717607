import translate from '../utils/translate';

const resizeModesStruct = [
    {
        label: translate('Cover'),
        value: 3,
        fit: 'cover',
        icon: 'icon-crop',
    },
    {
        label: translate('Contain'),
        value: 1,
        fit: 'contain',
        icon: 'icon-selection-rec',
    },
    {
        label: translate('Stretch'),
        value: 2,
        fit: 'fill',
        icon: 'icon-scale',
    },
];

export default resizeModesStruct;
