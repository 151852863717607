import { BehaviorSubject, from, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { getLSItem, setLSItem, removeLSItem, successStatus, geoLocationKey } from '../helpers';

import { LOGIN, LOGIN_VERIFY, LOGOUT, RECOVER_PASSWORD, REFRESH, REGISTER, RESEND_RECOVER_PASSWORD_EMAIL, RESEND_SIGNUP_VERIFY_EMAIL, RESET_PASSWORD, URLS } from '../constants/apiKeys';

import createAxios from '../utils/createAxios';

import connectionService from './connectionService';

class AuthService {
    constructor() {
        const authorized = getLSItem('authorized');

        this.axios = createAxios();
        this._authData = new BehaviorSubject(!!authorized);
        this.refreshTimer = null;
        let authCheckTimer = null;

        if (authorized && process.env.REACT_APP_API_ENV === 'production') {
            this._getRefreshToken();
        }

        this.axios.get('/auth/authorized', {
            params: {
                t: Date.now(),
            },
        }).then(res => {
            if (!!successStatus(res) && !!res?.data?.data?.expiration) {
                this._authData.next(!!res?.data?.data?.expiration);
                setLSItem('authorized', res?.data?.data?.expiration);
            }
        }).catch(() => {
            removeLSItem('authorized');
            this._authData.next(false);
        });

        this._authData.subscribe(val => {
            if (val) {
                const authorized = getLSItem('authorized');
                authCheckTimer && clearInterval(authCheckTimer);
                if (authorized) {
                    this._refresh();
                    authCheckTimer = setInterval(() => {
                        if (!getLSItem('authorized')) {
                            clearInterval(authCheckTimer);
                        }
                    }, 5000);
                }
            } else {
                authCheckTimer && clearInterval(authCheckTimer);
                this.refreshTimer && clearInterval(this.refreshTimer);
                this.refreshTimer = authCheckTimer = null;
            }
        });
    }

    signOut = (tryReconnect = false) => {
        const authorized = getLSItem('authorized');
        !!authorized && this.axios.post(URLS[LOGOUT], null, {
            withCredentials: true,
        });
        const geoLocation = getLSItem(geoLocationKey);
        const currency = geoLocation?.data?.currency;
        currency && setLSItem('currency', currency);
        connectionService.resetAllConnectors();
        this._authData.next(false);
        if (tryReconnect) {
            this._refresh();
        }
    };

    _getRefreshToken() {
        this.axios.post(URLS[REFRESH]).then(res => {
            const {
                data,
                status,
            } = res;

            if (status === 200 && data?.status) {
                const expiration = data?.expiration || data?.data?.expiration;
                if (expiration) {
                    setLSItem('authorized', (data?.expiration || data?.data?.expiration));
                    this._authData.next(true);
                    this._refresh();
                } else {
                    removeLSItem('authorized');
                    this._authData.next(false);
                }
            } else {
                this.refreshTimer && clearInterval(this.refreshTimer);
                this.refreshTimer = null;
                this._authData.getValue() && this.signOut();
            }
        }).catch(() => {
        });
    }

    _onSignIn(res, resolve) {
        const { status, data } = res;
        if (status === 200) {
            this._authData.next(true);
            setLSItem('authorized', data?.expiration);
        } else {
            this._authData.next(false);
        }
        resolve(res);
    }

    _refresh() {
        this.refreshTimer && clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
        const authorized = getLSItem('authorized');

        this.refreshTimer = setTimeout(() => {
            this._getRefreshToken();
        }, Math.max(0, (authorized || 0) - new Date().valueOf()));
    }

    signIn = props => from(new Promise(resolve => {
        this.axios.post(URLS[LOGIN], props, {
            withCredentials: true,
            headers: {
                'X-Device-ID': getLSItem('deviceId'),
            },
        }).then(res => this._onSignIn(res, resolve)).catch(err => resolve(err.response));
    }));

    signInVerify = props => from(new Promise(resolve => {
        this.axios.post(URLS[LOGIN_VERIFY], props, {
            withCredentials: true,
        }).then(res => this._onSignIn(res, resolve)).catch(err => resolve(err.response));
    }));

    signUp = props => from(this.axios.post(URLS[REGISTER], props, {
        headers: {
            'X-Currency': getLSItem('currency') || 'AMD',
            'X-Timezone': getLSItem('/geolocationV1')?.data?.time_zone?.id || Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Asia/Yerevan',
        },
    })).pipe(catchError(val => of(val.response)));

    recoverPassword = props => from(this.axios.post(URLS[RECOVER_PASSWORD], {
        ...props
    })).pipe(catchError(val => of(val.response)));

    resetPassword = props => from(this.axios.put(URLS[RESET_PASSWORD], props)).pipe(catchError(val => of(val.response)));

    resendRecoverPasswordEmail = props => from(this.axios.post(URLS[RESEND_RECOVER_PASSWORD_EMAIL], props)).pipe(catchError(val => of(val.response)));

    resendSignUpVerifyEmail = props => from(this.axios.post(URLS[RESEND_SIGNUP_VERIFY_EMAIL], props)).pipe(catchError(val => of(val.response)));

    get isSignedIn() {
        return this._authData;
    }
}

export default new AuthService(); // eslint-disable-line
