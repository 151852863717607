import { conditionalSpread, fakeArray, rem } from 'clyne-core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icon';

import useColor from '../../hooks/useColor';

import './index.scss';

const Card = props => {
    const {
        url,
        fit,
        icon,
        text,
        head,
        title,
        brand,
        border,
        children,
        skeleton,
        padding = 24,
        shadow = true,
        overflow = true,
        skeletonTextCount = 1,
        appearance = 'expanded',
    } = props;

    const style = conditionalSpread(useColor(brand, 'brand'), brand);
    const calculatedPadding = Array.isArray(padding) ? padding.map(num => rem(num)).join(' ') : rem(padding);

    return (
        <>
            {((appearance === 'clean' || appearance === 'light') && (!!title || !!head)) && (
                <ul className='card-external-head'>
                    <li className='text-ellipsis card-eh-title'>
                        {title?.children ? (
                            <div className='text-ellipsis'>
                                {title.children}
                                <small>{title.count}</small>
                            </div>
                        ) : title}
                    </li>
                    <li>
                        {!!head && (
                            <div className='card-head-holder'>
                                {head}
                            </div>
                        )}
                    </li>
                </ul>
            )}
            {appearance === 'light' ? (
                children
            ) : (
                <ul
                    className={classNames(
                        `card-holder`,
                        `a-${appearance}`,
                        overflow ? 'overflow' : 'overflow-visible',
                        {
                            fit,
                            shadow,
                            border,
                            'clickable': !!url,
                            'pointer-events-none': skeleton,
                        }
                    )}
                    {...conditionalSpread({
                        style: {
                            padding: calculatedPadding,
                            '--card-padding': calculatedPadding,
                            ...style,
                        },
                    }, appearance === 'clean')}
                >
                    {appearance !== 'clean' ? (
                        <>
                            <li>
                                {appearance === 'expanded' ? (
                                    <div className='card-icon'>
                                        {skeleton ? (
                                            <div className='card-icon-skeleton skeleton-blink' />
                                        ) : (
                                            <Icon type={icon} />
                                        )}
                                    </div>
                                ) : (
                                    <div className='card-native-icon' />
                                )}
                            </li>
                            <li>
                                <h2 className='card-holder-title'>
                                    {skeleton ? (
                                        <div className='card-title-skeleton skeleton-wave' />
                                    ) : title}
                                </h2>
                                {skeleton ? (
                                    <div className='skeleton-group'>
                                        {fakeArray(skeletonTextCount).map((_, index) => (
                                            <div
                                                key={index}
                                                className='card-text-skeleton skeleton-wave'
                                            />
                                        ))}
                                    </div>
                                ) : (
                                    !!text && <p>{typeof text === 'function' ? text() : text}</p>
                                )}
                                {children && (
                                    <div className='card-content'>
                                        {children}
                                    </div>
                                )}
                                {(!skeleton && !!url) && (
                                    <NavLink to={url} className='absolutely-splash' aria-label={title} />
                                )}
                            </li>
                            {appearance === 'expanded' && (
                                <li className='card-cta-holder pointer-events-none'>
                                    {!skeleton && (
                                        <Icon type='icon-a-arrow-right' />
                                    )}
                                </li>
                            )}
                        </>
                    ) : (
                        <li>
                            {children}
                        </li>
                    )}
                </ul>
            )}
        </>
    );
};

Card.propTypes = {
    url: PropTypes.string,
    fit: PropTypes.bool,
    icon: PropTypes.string,
    text: PropTypes.any,
    head: PropTypes.any,
    title: PropTypes.oneOfType([
        PropTypes.any,
        PropTypes.shape({
            children: PropTypes.any,
            count: PropTypes.number,
        })
    ]),
    brand: PropTypes.string,
    shadow: PropTypes.bool,
    border: PropTypes.bool,
    padding: PropTypes.any,
    overflow: PropTypes.bool,
    children: PropTypes.any,
    skeleton: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'expanded',
        'minimal',
        'clean',
        'light',
    ]),
    skeletonTextCount: PropTypes.number,
};

export default Card;
