import { conditionalSpread } from 'clyne-core';
import React, { useMemo } from 'react';
import classNames from 'classnames';

import Button from '../button';
import DropDown from '../dropDown';

import useDevice from '../../hooks/useDevice';

import './index.scss';

import { paginationProps, paginationConfig } from './props';

const offset = 2;
const drawCount = 5;
const diff = drawCount - offset;

const Pagination = props => {
    const {
        total,
        setPerPage,
        setCurrentPage,
        currentPage = 1,
        perPage = paginationConfig.options[0].value,
    } = props;

    const { isMobile } = useDevice();

    const sharedProps = {
        flexibility: 'fit',
        color: 'grayscale',
        size: isMobile ? 'medium' : 'big',
    };

    const pages = Math.ceil(total / perPage);

    const representingNums = useMemo(() => {
        if (pages) {
            const start = currentPage * perPage - perPage + 1;
            const end = currentPage * perPage > total ? total : currentPage * perPage;

            return `${start} - ${end} of ${total}`;
        }
        return '';
    }, [pages, currentPage, perPage, total]);

    const canShowDots = pages > drawCount;
    const areLeftDotsVisible = canShowDots && currentPage > diff;
    const areRightDotsVisible = canShowDots && currentPage + diff <= pages;

    const leftButtons = [1];
    const rightButtons = pages > 1 ? [pages] : [];
    let centerButtons = [];

    if (pages > offset) {
        if (pages - offset > diff) {
            const customPage = currentPage < diff ?
                diff
                :
                currentPage + diff > pages ? pages - offset : currentPage;
            centerButtons = [
                ...conditionalSpread([
                    customPage - 1,
                ], currentPage < 4 || !isMobile),
                customPage,
                ...conditionalSpread([
                    customPage + 1,
                ], currentPage > pages - 3 || !isMobile),
            ];
        } else {
            for (let i = offset; i < pages; i++) {
                centerButtons.push(i);
            }
        }
    }

    const dotedButtonRenderer = () => (
        <Button
            disabled
            {...sharedProps}
            className='pointer-events-none'
            icon={{
                size: 24,
                type: 'icon-a-more-horiz',
            }}
        />
    );

    const buttonRenderer = (number, index) => (
        <Button
            {...sharedProps}
            key={index}
            onClick={() => setCurrentPage(number)}
            color={number === currentPage ? 'brand' : 'grayscale'}
            flexibility='fit'
            className={classNames({
                'pointer-events-none': number === currentPage,
            })}
        >
            {number}
        </Button>
    );

    return (
        <div className='pagination-holder'>
            {!isMobile && (
                <ul className='pagination-props'>
                    <li>
                        <DropDown
                            {...sharedProps}
                            search={false}
                            checkmark={false}
                            valueSelector='value'
                            cornerRadius='full'
                            defaultSelected={perPage}
                            data={paginationConfig.options}
                            onChange={val => {
                                setPerPage(val);
                                setCurrentPage(1);
                            }}
                        />
                    </li>
                    <li>
                        {representingNums}
                    </li>
                </ul>
            )}
            <div className='pagination-actions'>
                <Button
                    {...sharedProps}
                    onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                    color='grayscale'
                    icon={{
                        size: 28,
                        type: 'icon-a-caret-left',
                    }}
                />
                <div className='h-divider' />
                <div className='pagination-numbers-holder flex-center'>
                    {leftButtons.map((number, index) => buttonRenderer(number, index))}
                    {areLeftDotsVisible && dotedButtonRenderer()}
                    {centerButtons.map((number, index) => buttonRenderer(number, index))}
                    {areRightDotsVisible && dotedButtonRenderer()}
                    {rightButtons.map((number, index) => buttonRenderer(number, index))}
                </div>
                <div className='h-divider' />
                <Button
                    {...sharedProps}
                    onClick={() => currentPage < pages && setCurrentPage(currentPage + 1)}
                    disabled={!pages || currentPage === pages}
                    color='grayscale'
                    icon={{
                        size: 28,
                        type: 'icon-a-caret-right',
                    }}
                />
            </div>
        </div>
    );
};

Pagination.propTypes = paginationProps;

export default Pagination;
