import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { getLSItem } from '../../helpers';

import { crState, currencyState, favouritesState, genericState, isProfileCompleteState, languageState, metasState, modeState, preciseLocationState, signedInState, systemModeState, userModeState, userRoleState, userState, wizardState, workspaceIdState, workspaceModeState } from '../../state';

import { fallbackRoute } from '../../routes';

import { userRoles, NIL } from '../../constants/construct';
import { FAVOURITES, GENERIC, GEOLOCATION, PROFILE } from '../../constants/apiKeys';

import translate from '../../utils/translate';
import getRouteFromURL from '../../utils/getRouteFromURL';
import identityHubSpotUser from '../../utils/identityHubSpotUser';
import connectStreamChatUser from '../../utils/connectStreamChatUser';

import useConnector from '../../hooks/useConnector';

import authService from '../../services/authService';

import whiteLabel from '../../constants/whiteLabel';

const StateHydrate = memo(props => {
    const {
        children,
    } = props;

    const [cr, setCR] = useRecoilState(crState);
    const generic = useRecoilValue(genericState);
    const language = useRecoilValue(languageState);
    const setMetas = useSetRecoilState(metasState);
    const userMode = useRecoilValue(userModeState);
    const setWizard = useSetRecoilState(wizardState);
    const [mode, setMode] = useRecoilState(modeState);
    const [user, setUser] = useRecoilState(userState);
    const setCurrency = useSetRecoilState(currencyState);
    const setUserRole = useSetRecoilState(userRoleState);
    const setWorkspaceId = useSetRecoilState(workspaceIdState);
    const preciseLocation = useRecoilValue(preciseLocationState);
    const [signedIn, setSignedIn] = useRecoilState(signedInState);
    const setWorkspaceMode = useSetRecoilState(workspaceModeState);
    const [systemMode, setSystemMode] = useRecoilState(systemModeState);
    const setIsProfileComplete = useSetRecoilState(isProfileCompleteState);

    const location = useLocation();

    useConnector(PROFILE, null, null, true, signedIn, 'replace', undefined, false, userState);
    useConnector(GENERIC, {}, null, true, undefined, 'replace', undefined, true, genericState);
    useConnector(FAVOURITES, [], {}, true, signedIn, 'replace', undefined, false, favouritesState);
    useConnector(GEOLOCATION, {}, null, true, true, 'replace', undefined, false, preciseLocationState);

    const workspaceId = user?.workspace?.id;
    const isWorkspaceMode = getLSItem('workspaceMode');

    useEffect(() => {
        setCurrency(generic?.currencies?.find(currency => currency?.code === (user?.currency || preciseLocation?.currency)) || '');
    }, [generic]); // eslint-disable-line

    useEffect(() => {
        (!!workspaceId && isWorkspaceMode) && setWorkspaceMode(true);
    }, [workspaceId, isWorkspaceMode]); // eslint-disable-line

    useEffect(() => {
        if (!signedIn && signedIn !== null) {
            setUser(null);
            setUserRole(null);
        }
    }, [signedIn]); // eslint-disable-line

    useEffect(() => {
        const subscription = authService.isSignedIn.subscribe(setSignedIn);
        return () => {
            subscription.unsubscribe();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        setUserRole(!!workspaceId ? workspaceId === NIL ? userRoles.client : userRoles.partner : null);
        setWorkspaceId(workspaceId && workspaceId !== NIL ? workspaceId : '');
    }, [user]); // eslint-disable-line

    useEffect(() => {
        identityHubSpotUser(true);
    }, [JSON.stringify(user || {})]); // eslint-disable-line

    useEffect(() => {
        if (signedIn && user?.id) {
            !!window?.gtag && window?.gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS, {
                'user_id': `${user?.id}`
            });
        }
    }, [signedIn, user]); // eslint-disable-line

    useEffect(() => {
        setCR(getRouteFromURL(location.pathname) || fallbackRoute);
    }, [location]); // eslint-disable-line

    useEffect(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }, [window.location.href]); // eslint-disable-line

    useEffect(() => {
        const title = translate(cr?.meta?.title || cr?.name);
        const appTitle = translate(whiteLabel?.meta?.title || process.env.REACT_APP_META_TITLE);
        setWizard(val => ({ ...val, key: cr?.wizard?.key || '', enabled: !!cr?.wizard }));
        setMetas(val => ({
            ...val,
            title: cr?.slug === 'home' ? title : `${title ? `${title} | ` : ''}${appTitle}`,
            keywords: translate(cr?.meta?.keywords || whiteLabel?.meta?.keywords || process.env.REACT_APP_META_KEYWORDS),
            description: translate(cr?.meta?.description || whiteLabel?.meta?.description || process.env.REACT_APP_META_DESCRIPTION),
        }));
    }, [cr, language?.code]); // eslint-disable-line

    useEffect(() => {
        const query = '(prefers-color-scheme: dark)';
        setSystemMode(window.matchMedia(query).matches ? 'dark' : 'light');
        const listener = e => setSystemMode(e.matches ? 'dark' : 'light');
        window?.matchMedia(query)?.addEventListener('change', listener);
    }, []); // eslint-disable-line

    useEffect(() => {
        setMode(userMode === 'automatic' ? systemMode : userMode);
    }, [userMode, systemMode]); // eslint-disable-line

    useEffect(() => {
        document.documentElement.setAttribute('mode', mode);
        const element = document.querySelector('meta[name="theme-color"]');
        !!element && element.setAttribute('content', mode === 'dark' ? '#0c0e11' : '#ffffff');
    }, [mode]);

    useEffect(() => {
        setIsProfileComplete(!!user?.billingDetails?.eligible);
    }, [user?.billingDetails?.eligible]); // eslint-disable-line

    useEffect(() => {
        connectStreamChatUser(user).catch(() => {
        });
    }, [user?.id]); // eslint-disable-line

    return children;
});

StateHydrate.propTypes = {
    children: PropTypes.any,
};

export default StateHydrate;
