import { createPortal } from 'react-dom';

import MorphingLogo from '../morphingLogo';

import KitActions from '../kitActions';

import './index.scss';

const Splash = () => {
    return createPortal((
        <ul className='splash-holder fixed-splash'>
            <li>
                <MorphingLogo />
            </li>
            <li className='animation-fadeIn'>
                <KitActions />
            </li>
        </ul>
    ), document.body);
};

export default Splash;
