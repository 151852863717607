import { number } from 'yup';
import React from 'react';

import Translate from '../translate';

import { COUNTRIES, TIMEZONES } from '../../constants/apiKeys';

import { timezoneOffsetHumanizer } from '../../helpers';

import translate from '../../utils/translate';
import getCountryStateCityFetchUrl from '../../utils/getCountryStateCityFetchUrl';

export const addEditScreenFields = [
    {
        name: <Translate>General</Translate>,
        fields: [
            {
                name: 'name',
                label: translate('Name'),
            },
            {
                name: 'description',
                label: translate('Description'),
            },
            {
                name: 'typeId',
                dataKey: 'screenTypes',
                type: 'dropDown',
                label: translate('Type'),
            },
            {
                name: 'placement',
                dataKey: 'placements',
                type: 'dropDown',
                label: translate('Placement'),
            },
            {
                name: 'placeTypeId',
                dataKey: 'locationTypes',
                type: 'dropDown',
                label: translate('Location Type'),
            },
        ],
    },
    {
        name: <Translate>Images</Translate>,
        fields: [
            {
                name: 'images',
                type: 'images',
                initialValue: [
                    {
                        url: '',
                    },
                    {
                        url: '',
                    },
                    {
                        url: '',
                    },
                ],
            },
        ],
    },
    {
        name: <Translate>Properties</Translate>,
        fields: [
            {
                name: 'orientationId',
                dataKey: 'orientations',
                type: 'dropDown',
                label: translate('Orientation'),
            },
            {
                name: 'pWidth',
                type: 'number',
                dataType: 'number',
                label: translate('Physical width (m)'),
            },
            {
                name: 'pHeight',
                type: 'number',
                dataType: 'number',
                label: translate('Physical height (m)'),
            },
            {
                name: 'pxWidth',
                type: 'number',
                dataType: 'number',
                label: translate('Resolution width (px)'),
            },
            {
                name: 'pxHeight',
                type: 'number',
                dataType: 'number',
                label: translate('Resolution height (px)'),
            },
        ],
    },
    {
        name: <Translate>Working Schedule</Translate>,
        cols: 4,
        fields: [
            {
                name: 'weekdayStartTime',
                type: 'time',
                label: translate('Weekday Start Time'),
            },
            {
                name: 'weekdayEndTime',
                type: 'time',
                label: translate('Weekday End Time'),
            },
            {
                name: 'weekendStartTime',
                type: 'time',
                label: translate('Weekend Start Time'),
            },
            {
                name: 'weekendEndTime',
                type: 'time',
                label: translate('Weekend End Time'),
            },
        ],
    },
    {
        name: <Translate>Timing</Translate>,
        cols: 3,
        fields: [
            {
                type: 'number',
                dataType: 'number',
                name: 'loopDuration',
                modifierKey: 'slotCount',
                label: translate('Slot Count'),
                modifier: (data, mode) => data ? mode === 'edit' ? data?.loopDuration / data?.slotDuration : data?.slotDuration * data?.slotCount : 0,
            },
            {
                name: 'slotDuration',
                type: 'number',
                dataType: 'number',
                label: translate('Slot Duration (s)'),
            },
            {
                name: 'maxVirtualSlots',
                type: 'number',
                dataType: 'number',
                label: translate('Campaign Max Repetition'),
                validation: number().required(true).min(1, 'Must be equal or more than 1').integer(),
            },
        ],
    },
    {
        cols: 4,
        name: <Translate>Pricing</Translate>,
        fields: [
            {
                name: 'monthlyPrice',
                type: 'price',
                dataType: 'number',
                label: translate('Monthly Price'),
            },
            {
                name: 'weeklyPrice',
                type: 'price',
                dataType: 'number',
                label: translate('Weekly Price'),
            },
            {
                name: 'dailyPrice',
                type: 'price',
                dataType: 'number',
                label: translate('Daily Price'),
            },
            {
                name: 'pricePerSlot',
                type: 'price',
                dataType: 'number',
                label: translate('Price Per Slot'),
            },
        ],
    },
    {
        name: <Translate>Location</Translate>,
        cols: 4,
        fields: [
            {
                name: 'timezone',
                label: translate('Timezone'),
                type: 'dropDown',
                valueSelector: 'name',
                fetchFrom: () => TIMEZONES,
                renderer: val => (
                    <>
                        <strong>
                            {timezoneOffsetHumanizer(Number(val?.offsetValue))}
                        </strong>
                        {` ${translate(val.name)}`}
                    </>
                )
            },
            {
                hidden: true,
                name: 'latitude',
                label: translate('Latitude'),
            },
            {
                hidden: true,
                name: 'longitude',
                label: translate('Longitude'),
            },
            {
                name: 'countryId',
                label: translate('Country'),
                fetchFrom: () => COUNTRIES,
                type: 'dropDown',
            },
            {
                name: 'stateCode',
                label: translate('State'),
                fetchFrom: values => values.countryId ? getCountryStateCityFetchUrl(values.countryId) : '',
                disabled: values => !values.countryId,
                type: 'dropDown',
                valueSelector: 'region',
            },
            {
                name: 'cityId',
                label: translate('City'),
                fetchFrom: values => !!values.countryId && !!values.stateCode ? getCountryStateCityFetchUrl(values.countryId, values.stateCode) : '',
                disabled: values => !values.countryId || !values.stateCode,
                type: 'dropDown',
                valueSelector: 'geoNameId',
            },
            {
                type: 'map',
            },
        ],
    },
];
