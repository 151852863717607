import PropTypes from 'prop-types';

import { knobAbleProps } from '../../props';

export const datePickerConfig = {
    type: [
        'datePicker',
        'dateRangePicker',
    ],
    appearance: knobAbleProps,
};

export const datePickerProps = {
    type: PropTypes.oneOf(datePickerConfig.type),
    date: PropTypes.any,
    ranges: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    onChange: PropTypes.func,
    retainStartDateOnSelection: PropTypes.bool,
};
