import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { setLSItem } from '../helpers';

import { authenticationRoutes } from '../containers/authentication/data';

const useRedirectToSignIn = (url = window.location.href) => {
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (shouldRedirect) {
            if (!Object.values(authenticationRoutes).includes(location.pathname)) {
                setLSItem('droppedFrom', url.replace(window.location.origin, ''));
            }
            navigate(authenticationRoutes.signIn);
        }
    }, [location, shouldRedirect]); // eslint-disable-line

    return setShouldRedirect;
};

export default useRedirectToSignIn;
