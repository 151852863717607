import PropTypes from 'prop-types';
import { rem } from 'clyne-core';

import './index.scss';

const Skeleton = props => {
    const {
        width,
        height,
        trueWidth,
        trueHeight,
        borderRadius,
    } = props;

    const holderWidth = trueWidth || width;
    const holderHeight = trueHeight || height;
    const finalBorderRadius = borderRadius || height;

    return (
        <span
            className='skeleton-holder'
            style={{
                width: typeof holderWidth === 'string' ? holderWidth : rem(holderWidth),
                height: typeof holderHeight === 'string' ? holderHeight : rem(holderHeight),
            }}
        >
            <span
                className='skeleton-item'
                style={{
                    width: typeof width === 'string' ? width : rem(width),
                    height: typeof height === 'string' ? height : rem(height),
                    borderRadius: typeof finalBorderRadius === 'string' ? finalBorderRadius : rem(finalBorderRadius),
                }}
            />
        </span>
    );
};

Skeleton.propTypes = {
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    trueWidth: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    trueHeight: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    borderRadius: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

export default Skeleton;
