import { createPortal } from 'react-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './index.scss';

const Loader = props => {
    const {
        middle,
        loading,
        absolute,
        placement,
        type = 'spinner',
        portalElement = document.body,
    } = props;

    const spinnerRenderer = middle => (
        <div
            className={classNames(
                `loader-spinner`,
                {
                    middle,
                    absolute,
                }
            )}
        />
    );

    return type === 'spinner' ? (
        placement ? (
            <div
                className={classNames(
                    `loader-placement-holder`,
                    `pt-${placement}`,
                )}
            >
                {spinnerRenderer(true)}
            </div>
        ) : (
            absolute ? (
                <div className='absolute-loader'>
                    {spinnerRenderer(middle)}
                </div>
            ) : (
                spinnerRenderer(middle)
            )
        )
    ) : (
        createPortal((
            <div
                className={classNames(
                    `loader-bar-holder`,
                    {
                        'active': loading,
                    }
                )}
            />
        ), portalElement)
    );
};

Loader.propTypes = {
    type: PropTypes.oneOf([
        'spinner',
        'bar',
    ]),
    middle: PropTypes.bool,
    loading: PropTypes.bool,
    absolute: PropTypes.bool,
    placement: PropTypes.oneOf([
        'popover',
        'static',
        'module-content',
    ]),
    portalElement: PropTypes.any,
};

export default Loader;
