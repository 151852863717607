import { conditionalSpread, isFunction } from 'clyne-core';
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { uiKitSizes } from '../../props';

import './index.scss';

const Switch = props => {
    const {
        label,
        checked,
        onChange,
        readOnly,
        description,
        relationSize,
        size = 'big',
        color = 'default',
    } = props;

    const [localChecked, setLocalChecked] = useState(!!checked);

    useEffect(() => {
        setLocalChecked(!!checked);
    }, [checked]);

    return (
        <label
            tabIndex={0}
            onKeyDown={e => {
                if (e.code === 'Space' || e.code === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    setLocalChecked(val => {
                        isFunction(onChange, false) && onChange({
                            target: {
                                checked: !val,
                            },
                        });

                        return !val;
                    });
                }
            }}
            className={classNames(
                `switch-holder`,
                `c-${color}`,
                `a-${size}`,
                {
                    [`relation-size`]: relationSize,
                    'read-only pointer-events-none': readOnly,
                }
            )}
            style={conditionalSpread({
                '--relation-size': `var(--ui-kit-${relationSize}-height)`,
            }, relationSize)}
        >
            <div
                className={classNames(
                    `switch`,
                    {
                        'active': localChecked,
                    }
                )}
            >
                <small />
                <input
                    tabIndex={-1}
                    type='checkbox'
                    checked={localChecked}
                    aria-label={label}
                    onChange={e => {
                        setLocalChecked(e.target.checked);
                        isFunction(onChange, false) && onChange(e);
                    }}
                    readOnly={readOnly}
                />
            </div>
            {description ? (
                <div className='switch-l-d'>
                    <div className='switch-l'>
                        {label}
                    </div>
                    <div className='switch-d'>
                        {description}
                    </div>
                </div>
            ) : (
                <div className='text-ellipsis'>
                    {label}
                </div>
            )}
        </label>
    );
};

Switch.propTypes = {
    size: PropTypes.oneOf([
        'big',
        'medium',
    ]),
    label: PropTypes.any,
    color: PropTypes.oneOf([
        'default',
        'theme',
    ]),
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    description: PropTypes.any,
    relationSize: PropTypes.oneOf(uiKitSizes),
};

export default Switch;
