import { useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';

import Translate from '../../components/translate';
import ScreenCard from '../../components/screenCard';
import ScreenActions from '../../components/screenActions';
import ModuleContent from '../../components/moduleContent';
import RoleProtection from '../../components/roleProtection';
import AddEditScreenModal from '../../components/addEditScreenModal';

import translate from '../../utils/translate';

import { addEditScreenModalState, dynamicDataState, dynamicDataTriggerState, myScreensModuleContentCacheState } from '../../state';

import { userRoles } from '../../constants/construct';

import { myScreensConfig } from './props';

const MyScreens = props => {
    const {
        onSyncSuccess,
        appearance = 'default',
    } = props;

    const dynamicData = useRecoilValue(dynamicDataState);
    const setDynamicDataTrigger = useSetRecoilState(dynamicDataTriggerState);
    const setAddEditScreenModal = useSetRecoilState(addEditScreenModalState);
    const myScreensModuleContentCache = useRecoilValue(myScreensModuleContentCacheState);

    return (
        <RoleProtection role={userRoles.partner}>
            <ModuleContent
                workspaces
                dataKey='screens'
                cache={myScreensModuleContentCache}
                onTabClick={() => setDynamicDataTrigger(val => val + 1)}
                hideUnneededPagination={appearance === 'select'}
                filters={[
                    {
                        keys: ['query'],
                        component: 'Input',
                        placeholder: translate('Search by Name or ID'),
                        type: 'search',
                    },
                    {
                        keys: ['status'],
                        component: 'DropDown',
                        placeholder: translate('Status'),
                        data: Object.values(myScreensConfig.screenStatusMapping),
                    },
                    {
                        keys: ['visibility'],
                        component: 'DropDown',
                        placeholder: translate('Visibility'),
                        data: Object.values(myScreensConfig.screenVisibilityMapping),
                    },
                    ...conditionalSpread([
                        {
                            keys: ['deviceState'],
                            component: 'Tabs',
                            data: [
                                {
                                    id: '',
                                    key: 'all',
                                    name: translate('All'),
                                },
                                {
                                    id: 'online',
                                    key: 'online',
                                    name: translate('Online'),
                                    badge: {
                                        badge: dynamicData.deviceStatuses?.online?.length,
                                    },
                                },
                                {
                                    id: 'offline',
                                    key: 'offline',
                                    name: translate('Offline'),
                                    badge: {
                                        badge: dynamicData.deviceStatuses?.offline?.length,
                                    },
                                },
                            ],
                        },
                    ], appearance === 'default'),
                ]}
                noData={{
                    type: 'screen',
                    nothing: {
                        title: <Translate>You don't have any screens... yet!</Translate>,
                        subTitle: <Translate>You should add a screen to see this content</Translate>,
                        cta: {
                            color: 'accent',
                            children: <Translate>Add Screen</Translate>,
                            itemsDirection: 'start',
                            onClick: () => setAddEditScreenModal(val => ({
                                ...val,
                                mode: 'add',
                                active: true,
                            })),
                            icon: {
                                type: 'icon-a-plus',
                            },
                        },
                    },
                    filtered: {
                        title: <Translate>No screens found for your request</Translate>,
                        subTitle: <Translate>Try changing or resetting filters.</Translate>,
                    },
                }}
                {...conditionalSpread({
                    cta: {
                        onClick: () => setAddEditScreenModal(val => ({
                            ...val,
                            mode: 'add',
                            active: true,
                        })),
                        children: <Translate>Add Screen</Translate>,
                    },
                }, appearance === 'default')}
            >
                {({ data, updateById }) => (
                    <>
                        {data.map((screen, index) => screen.skeleton ? (
                            <ScreenCard
                                skeleton
                                key={index}
                                appearance={appearance}
                            />
                        ) : (
                            <ScreenActions
                                id={screen.id}
                                key={screen.id}
                                name={screen.name}
                                updateById={updateById}
                                visible={screen.visible}
                                device={screen.device?.litDevice}
                                synced={!!screen?.device?.deviceUid}
                            >
                                {({ menu, cache, updatedNeeded }) => (
                                    <ScreenCard
                                        menu={menu}
                                        cache={cache}
                                        id={screen.id}
                                        name={screen.name}
                                        typeId={screen.typeId}
                                        cityId={screen.cityId}
                                        rating={screen.rating}
                                        images={screen.images}
                                        pWidth={screen.pWidth}
                                        linked={screen.linked}
                                        updateById={updateById}
                                        appearance={appearance}
                                        visible={screen.visible}
                                        pHeight={screen.pHeight}
                                        latitude={screen.latitude}
                                        cityName={screen.cityName}
                                        timezone={screen.timezone}
                                        deviceUid={screen.deviceUid}
                                        countryId={screen.countryId}
                                        placement={screen.placement}
                                        longitude={screen.longitude}
                                        onSyncSuccess={onSyncSuccess}
                                        updatedNeeded={updatedNeeded}
                                        description={screen.description}
                                        placeTypeId={screen.placeTypeId}
                                        pricePerSlot={screen.pricePerSlot}
                                        slotDuration={screen.slotDuration}
                                        loopDuration={screen.loopDuration}
                                        workingHours={screen.workingHours}
                                        orientationId={screen.orientationId}
                                        runningCampaigns={screen.runningCampaigns}
                                        averageDailyPlayback={screen.averageDailyPlayback}
                                        averagePricePerMonth={screen.averagePricePerMonth}
                                        isOnline={dynamicData.deviceStatuses?.online?.some(item => item?.screenId === screen.id)}
                                        aliveAt={dynamicData.deviceStatuses?.all?.find(item => item?.screenId === screen.id)?.aliveAt}
                                    />
                                )}
                            </ScreenActions>
                        ))}
                    </>
                )}
            </ModuleContent>
            <AddEditScreenModal />
        </RoleProtection>
    );
};

MyScreens.propTypes = {
    appearance: PropTypes.oneOf([
        'default',
        'select',
    ]),
    onSyncSuccess: PropTypes.func,
};

export default MyScreens;
