import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Input from '../input';

import translate from '../../utils/translate';

import { inputValue } from '../../helpers';

const RunCommand = props => {
    const {
        error,
        setCommand,
    } = props;

    const [data, setData] = useState('');

    useEffect(() => {
        setCommand(data || '');
    }, [data]); // eslint-disable-line

    return (
        <Input
            value={data}
            error={error}
            size='extra-big'
            cornerRadius='full'
            onChange={e => setData(inputValue(e))}
            placeholder={translate('Shell Command')}
        />
    );
};

RunCommand.propTypes = {
    error: PropTypes.bool,
    setCommand: PropTypes.func,
};

export default RunCommand;
