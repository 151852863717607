import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Point from '../point';
import GoogleMap from '../googleMap';

import { genericState } from '../../state';

import { getScreenType } from '../../helpers';

import './index.scss';

const ScreenLocation = props => {
    const {
        icon,
        typeId,
        latitude,
        longitude,
        zoom = 16,
        size = 'compact',
    } = props;

    const generic = useRecoilValue(genericState);

    return (
        <div
            className={classNames(
                `screen-location-map-holder`,
                `s-${size}`,
            )}
        >
            <div className='screen-location-map'>
                <GoogleMap
                    controls
                    geolocation
                    zoom={zoom}
                    lat={latitude}
                    lng={longitude}
                    enableItemLocate
                >
                    <Point
                        lat={latitude}
                        lng={longitude}
                        color={getScreenType(typeId, generic)?.color}
                        icon={icon || getScreenType(typeId, generic)?.icon}
                    />
                </GoogleMap>
            </div>
        </div>
    );
};

ScreenLocation.propTypes = {
    size: PropTypes.oneOf([
        'compact',
        'wide',
    ]),
    icon: PropTypes.string,
    zoom: PropTypes.number,
    typeId: PropTypes.number,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
};

export default ScreenLocation;
