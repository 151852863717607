import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import Icon from '../icon';

import useDevice from '../../hooks/useDevice';

const HoldableButton = props => {
    const {
        icon,
        onHold,
        disabled,
        onRelease,
        ariaLabel,
        className,
        speed = 20,
        delay = 200,
    } = props;

    const ref = useRef(null);

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        let intervalId = null, timeoutId = null;
        const { current: element } = ref;

        const clearTimers = () => {
            onRelease && onRelease();
            intervalId && clearInterval(intervalId);
            timeoutId && clearTimeout(timeoutId);
            element.removeEventListener('mouseout', clearTimers);
            if (isMobile) {
                element.removeEventListener('touchend', clearTimers);
                element.addEventListener('touchstart', handleHoldCounter);
            } else {
                element.removeEventListener('mouseup', clearTimers);
                element.addEventListener('mousedown', handleHoldCounter);
            }
        };

        const handleHoldCounter = () => {
            if (isMobile) {
                element.addEventListener('touchend', clearTimers);
                element.removeEventListener('touchstart', handleHoldCounter);
            } else {
                element.removeEventListener('mousedown', handleHoldCounter);
                element.addEventListener('mouseup', clearTimers);
            }
            element.addEventListener('mouseout', clearTimers);

            onHold();
            timeoutId = setTimeout(() => {
                intervalId = setInterval(() => {
                    if (element.disabled) {
                        clearTimers();
                    } else {
                        onHold();
                    }
                }, speed);
            }, delay);
        };

        if (isMobile) {
            element.addEventListener('touchstart', handleHoldCounter);
        } else {
            element.addEventListener('mousedown', handleHoldCounter);
        }

        return () => {
            if (isMobile) {
                element.removeEventListener('touchstart', clearTimers);
                element.removeEventListener('touchend', clearTimers);
            } else {
                element.removeEventListener('mousedown', clearTimers);
                element.removeEventListener('mouseup', clearTimers);
            }

            element.removeEventListener('mouseout', clearTimers);

            clearTimers();
        };
    }, [isMobile]); // eslint-disable-line

    return (
        <button
            ref={ref}
            type='button'
            disabled={disabled}
            className={className}
            aria-label={ariaLabel}
        >
            <Icon
                size={icon.size}
                type={icon.type}
            />
        </button>
    );
};

HoldableButton.propTypes = {
    icon: PropTypes.shape({
        size: PropTypes.number,
        type: PropTypes.string,
    }),
    speed: PropTypes.number,
    delay: PropTypes.number,
    onHold: PropTypes.func,
    disabled: PropTypes.bool,
    onRelease: PropTypes.func,
    ariaLabel: PropTypes.string,
    className: PropTypes.string,
};

export default HoldableButton;
