import { conditionalSpread } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Price from '../price';
import Metrics from '../metrics';
import Translate from '../translate';
import MobileStack from '../mobileStack';
import ScreenDetail from '../screenDetail';
import HorizontalScroll from '../horizontalScroll';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

import { genericState } from '../../state';

import humanizeDurationWithTranslation from '../../utils/humanizeDurationWithTranslation';

import { getLocationType, getScreenOrientation, getScreenType } from '../../helpers';

import { COUNTRIES, URLS } from '../../constants/apiKeys';

import './index.scss';

const ScreenDetails = props => {
    const {
        data,
        skeleton,
        appearance = 'expanded',
        includeRunningCampaigns,
    } = props;

    const generic = useRecoilValue(genericState);

    const { isMobile } = useDevice();

    const { data: countriesData } = useConnector(URLS[COUNTRIES]);

    const formattedData = [
        ...conditionalSpread([
            {
                icon: 'icon-a-megaphone',
                name: <Translate>Campaigns</Translate>,
                children: data?.runningCampaigns || 0,
            },
        ], includeRunningCampaigns),
        {
            icon: 'icon-a-eye',
            name: <Translate>Avg. Daily Views</Translate>,
            children: data.averageDailyPlayback,
        },
        {
            icon: 'icon-a-repeat',
            name: <Translate>Slot Duration</Translate>,
            children: humanizeDurationWithTranslation(data.slotDuration * 1000),
        },
        {
            icon: 'icon-a-repeat-on',
            name: <Translate>Loop Duration</Translate>,
            children: humanizeDurationWithTranslation(data.loopDuration * 1000),
        },
        {
            icon: 'icon-a-dollar',
            name: <Translate>Price Per Slot</Translate>,
            children: <Price number={data.pricePerSlot} />,
        },
        {
            icon: 'icon-a-calendar',
            name: <Translate>Avg. Monthly Price</Translate>,
            children: <Price number={data.averagePricePerMonth} />,
        },
        {
            icon: 'icon-a-ruler',
            name: <Translate>Screen Size</Translate>,
            children: (
                <Metrics
                    physicalWidth={data.pWidth}
                    physicalHeight={data.pHeight}
                />
            ),
        },
        {
            icon: getScreenType(data.typeId, generic)?.icon,
            name: <Translate>Screen Type</Translate>,
            children: getScreenType(data.typeId, generic)?.name,
        },
        {
            icon: 'icon-a-pin-light',
            name: <Translate>Placement</Translate>,
            children: <Metrics placementID={data.placement} />,
        },
        {
            icon: 'icon-a-frame-bounds',
            name: <Translate>Screen orientation</Translate>,
            children: getScreenOrientation(data.orientationId, generic)?.name,
        },
        {
            icon: 'icon-a-pin',
            name: <Translate>Location Type</Translate>,
            children: getLocationType(data.placeTypeId, generic)?.name,
        },
        {
            icon: 'icon-a-map-view',
            name: <Translate>City, Country</Translate>,
            children: `${data.cityName}, ${countriesData?.find(val => val.id === data.countryId)?.name}`,
        },
    ];

    const content = formattedData.map(({ icon, name, children }, index) => (
        <ScreenDetail
            key={index}
            icon={icon}
            name={name}
            skeleton={skeleton}
            appearance={appearance}
        >
            {children}
        </ScreenDetail>
    ));

    return appearance === 'expanded' ? (
        <div
            className={classNames(
                `screen-details-holder`,
                `a-${appearance}`,
                `grid`,
                isMobile ? `cols-2` : `cols-4`,
            )}
        >
            {content}
        </div>
    ) : isMobile ? (
        <MobileStack>
            <div className='screen-details-m-stack'>
                {content}
            </div>
        </MobileStack>
    ) : (
        <HorizontalScroll gap={isMobile ? 30 : 50}>
            {content}
        </HorizontalScroll>
    );
};

ScreenDetails.propTypes = {
    data: PropTypes.object,
    skeleton: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'expanded',
        'scrollable',
    ]),
    includeRunningCampaigns: PropTypes.bool,
};

export default ScreenDetails;
