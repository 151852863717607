import React, { Fragment, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';
import RecoilNexus from 'recoil-nexus';
import { RecoilRoot } from 'recoil';

import Splash from './components/splash';
import ErrorFallback from './components/errorFallback';

import useDevice from './hooks/useDevice';
import useWindowResize from './hooks/useWindowResize';

import Layout from './containers/layout';
import HookHydrate from './hydrators/hookHydrate';
import StateHydrate from './hydrators/stateHydrate';
import WhiteLabelHydrate from './hydrators/whiteLabelHydrate';

import { isWhiteLabel, subDomain } from './helpers';

import './assets/scss/skeleton.scss';

const kit = subDomain === 'kit';

const App = () => {
    const {
        screenHeight,
    } = useWindowResize();

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        document.documentElement.style.setProperty('--vh', screenHeight + 'px');
    }, [screenHeight, isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--vih', window.innerHeight + 'px');
    }, [isMobile]);

    useEffect(() => {
        document.documentElement.style.setProperty('--screen-height', window.screen.height + 'px');
    }, [isMobile]);

    const ConditionalWhiteLabelHydrate = isWhiteLabel ? WhiteLabelHydrate : Fragment;

    return (
        <BrowserRouter>
            <RecoilRoot>
                <RecoilNexus />
                <ErrorBoundary
                    fallback={(
                        <ErrorFallback />
                    )}
                >
                    <ConditionalWhiteLabelHydrate>
                        <StateHydrate>
                            <HookHydrate>
                                {kit ? (
                                    <Splash />
                                ) : (
                                    <Layout />
                                )}
                            </HookHydrate>
                        </StateHydrate>
                    </ConditionalWhiteLabelHydrate>
                </ErrorBoundary>
            </RecoilRoot>
        </BrowserRouter>
    );
};

export default App;
