import { useInView } from 'react-intersection-observer';
import { conditionalSpread } from 'clyne-core';
import { useSetRecoilState } from 'recoil';
import React, { useEffect } from 'react';
import classNames from 'classnames';

import To from '../to';
import Logo from '../logo';
import Guide from '../guide';
import Button from '../button';
import Translate from '../translate';
import SubscribeForm from '../subscribeForm';

import translate from '../../utils/translate';
import getRoutesBySlug from '../../utils/getRoutesBySlug';

import { footerVisibleState } from '../../state';

import whiteLabel from '../../constants/whiteLabel';

import { isWhiteLabel } from '../../helpers';

import './index.scss';

const socials = [
    {
        title: 'Instagram',
        url: process.env.REACT_APP_INSTAGRAM,
        icon: 'icon-instagram',
        color: '#bc2a8d',
    },
    {
        title: 'Facebook',
        url: process.env.REACT_APP_FACEBOOK,
        icon: 'icon-facebook-f',
        color: '#0866ff',
    },
    {
        title: 'LinkedIn',
        url: process.env.REACT_APP_LINKEDIN,
        icon: 'icon-linkedin-in',
        color: '#0a66c2',
    },
];

const Footer = () => {
    const setFooterVisible = useSetRecoilState(footerVisibleState);

    const content = [
        {
            name: whiteLabel?.name || <Translate>Advelit</Translate>,
            children: [
                ...getRoutesBySlug(['help']),
                {
                    name: <Translate>Terms & Conditions</Translate>,
                    url: '/terms/terms-and-conditions',
                    component: true,
                },
                {
                    name: <Translate>Privacy Policy</Translate>,
                    url: '/terms/privacy-policy',
                    component: true,
                },
            ],
        },
        {
            name: <Translate>Quick links</Translate>,
            children: getRoutesBySlug([
                'home',
                ...conditionalSpread([
                    'becomeAPartner',
                ], !isWhiteLabel),
                'myCampaigns',
            ]),
        }
    ];

    const [inViewRef, inView] = useInView({
        threshold: 0,
        triggerOnce: false,
    });

    useEffect(() => {
        setFooterVisible(inView);
    }, [inView]); // eslint-disable-line

    return (
        <footer
            ref={inViewRef}
        >
            <Guide padding={false}>
                <div className='v-divider' />
                <ul
                    className={classNames(
                        `grid`,
                        `footer-content`,
                    )}
                    style={{
                        '--gridTemplateColumns': `repeat(${content.length}, 1fr) 1.3fr`,
                    }}
                >
                    {content.map(({ name, children }, index) => (
                        <li key={index}>
                            <div className='f-nav-title'>
                                {name}
                            </div>
                            <ul className='f-nav'>
                                {children.map((route, index) => (
                                    <li key={index}>
                                        <To
                                            url={route?.externalOnWhiteLabel && isWhiteLabel ? `${process.env.REACT_APP_FRONTEND_URL}${route.url}` : route.url}
                                            onClick={route.onClick}
                                            {...conditionalSpread({
                                                target: '_blank',
                                            }, route?.externalOnWhiteLabel && isWhiteLabel)}
                                            {...conditionalSpread({ href: route.url }, !route.component)}
                                        >
                                            {translate(route.name)}
                                        </To>
                                    </li>
                                ))}
                            </ul>
                        </li>
                    ))}
                    <li>
                        <div className='f-nav-title'>
                            <Translate>Follow Us</Translate>
                        </div>
                        <div className='footer-form-holder'>
                            <SubscribeForm />
                        </div>
                        {!!(whiteLabel?.socials || socials).length && (
                            <div className='social-links-holder'>
                                {(whiteLabel?.socials || socials).map((social, index) => (
                                    <Button
                                        key={index}
                                        icon={{
                                            size: 32,
                                            type: social.icon,
                                        }}
                                        shadow
                                        color='color'
                                        target='_blank'
                                        href={social.url}
                                        hero={social.color}
                                        cornerRadius='smooth'
                                        ariaLabel={`Connect with us on ${social.title}`}
                                    />
                                ))}
                            </div>
                        )}
                        {isWhiteLabel && (
                            <div className='powered-by-holder'>
                                <p>
                                    <Translate>Powered by</Translate>
                                </p>
                                <Logo og />
                            </div>
                        )}
                    </li>
                </ul>
                <div className='v-divider' />
                <div className='copyright-holder'>
                    <p>
                        {`© `}
                        <Translate>All Right Reserved.</Translate>
                        {` Advelit.${window.location.hostname.split('.').at(-1)}`}
                    </p>
                </div>
            </Guide>
        </footer>
    );
};

export default Footer;
