import { useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as QR } from './media/qr.svg';
import { ReactComponent as Text } from './media/text.svg';
import { ReactComponent as Mark } from './media/mark.svg';
import { ReactComponent as Website } from './media/website.svg';

import './index.scss';

const MorphingLogo = props => {
    const {
        scale = true,
        animate = true,
        appearance = 'box',
        loopDuration = 2000,
        transitionDuration = 500,
    } = props;

    const [startAnimate, setStartAnimate] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setStartAnimate(true);
        }, loopDuration);
    }, [animate]); // eslint-disable-line

    return (
        <div
            className={classNames(
                'morphing-logo-c-holder',
                `a-${appearance}`,
                {
                    scale,
                    'animate': startAnimate,
                }
            )}
            style={{
                '--loopDuration': `${loopDuration}ms`,
                '--transitionDuration': `${transitionDuration}ms`,
            }}
        >
            <ul className='morphing-logo-mark-holder animation-fadeIn'>
                <li className='lm-shape' />
                <li className='lm-qr'>
                    <QR />
                </li>
                <li
                    className='lm-mark'
                    onTransitionEnd={() => setTimeout(() => {
                        setStartAnimate(val => !val);
                    }, (loopDuration + 2000))}
                >
                    <Mark />
                </li>
            </ul>
            {appearance !== 'mark' && (
                <div className='morphing-logo-c-text animation-fadeIn'>
                    {appearance === 'box' ? (
                        <Website />
                    ) : (
                        <Text />
                    )}
                </div>
            )}
        </div>
    );
};

MorphingLogo.propTypes = {
    scale: PropTypes.bool,
    animate: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'box',
        'mark',
    ]),
    loopDuration: PropTypes.number,
    transitionDuration: PropTypes.number,
};

export default MorphingLogo;
