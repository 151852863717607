import React, { useEffect, useRef, useState } from 'react';
import useDraggableScroll from 'use-draggable-scroll';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { rem } from 'clyne-core';

import './index.scss';

const scrollStates = {
    start: 'start',
    middle: 'middle',
    end: 'end',
};

const HorizontalScroll = props => {
    const {
        children,
        gap = 12,
    } = props;

    const [scrollState, setScrollState] = useState(scrollStates.start);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (scrollRef.current) {
            const element = scrollRef.current;
            const scrollHandler = () => {
                const scrolled = element.scrollWidth - element.clientWidth;
                switch (Number(element.scrollLeft.toFixed())) {
                    case scrolled + 1:
                    case scrolled - 1:
                    case scrolled:
                        return setScrollState(scrollStates.end);
                    case 0:
                        return setScrollState(scrollStates.start);
                    default:
                        return setScrollState(scrollStates.middle);
                }
            };
            element.addEventListener('scroll', scrollHandler);
        }
    }, [scrollRef]);

    const { onMouseDown } = useDraggableScroll(scrollRef, { direction: 'horizontal' });

    return (
        <div
            ref={scrollRef}
            className={classNames(
                `horizontal-scroll-holder`,
                `ss-${scrollState}`,
            )}
            onMouseDown={onMouseDown}
            style={{
                '--horizontal-scroll-gap': rem(gap),
            }}
        >
            <div className='horizontal-scroll-c'>
                {children}
            </div>
        </div>
    );
};

HorizontalScroll.propTypes = {
    gap: PropTypes.number,
    children: PropTypes.any,
};

export default HorizontalScroll;
