import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import React from 'react';

import { genericState } from '../../state';

const Metrics = props => {
    const {
        typeID,
        placementID,
        physicalWidth,
        physicalHeight,
    } = props;

    const generic = useRecoilValue(genericState);

    const physicalSize = `${physicalWidth}m x ${physicalHeight}m`;

    return (
        <>
            {(!!physicalWidth && !!physicalWidth) && physicalSize}
            {!!typeID && (
                `${generic?.screenTypes?.find(type => type.id === typeID)?.name}`
            )}
            {!!placementID && (
                `${generic?.placements?.find(placement => placement.id === placementID)?.name}`
            )}
        </>
    );
};

Metrics.propTypes = {
    typeID: PropTypes.number,
    placementID: PropTypes.number,
    physicalWidth: PropTypes.number,
    physicalHeight: PropTypes.number,
};

export default Metrics;
