import { Blurhash as ReactBlurHash } from 'react-blurhash';
import React, { memo, useState } from 'react';
import { isBlurhashValid } from 'blurhash';
import PropTypes from 'prop-types';
import clasNames from 'classnames';

import './index.scss';

const BlurHash = memo(props => {
    const {
        hash,
        active = true,
    } = props;

    const [canUnmount, setCanUnmount] = useState(false);

    return isBlurhashValid(`${hash}`).result && (
        <div
            onTransitionEnd={e => {
                if (e.target.classList.value.includes('blur-hash-c-holder') && !active) {
                    setCanUnmount(true);
                }
            }}
            className={clasNames(
                'blur-hash-c-holder',
                {
                    active,
                }
            )}
        >
            {!canUnmount && (
                <ReactBlurHash
                    punch={1}
                    hash={hash}
                    width='100%'
                    height='100%'
                    resolutionX={320}
                    resolutionY={320}
                    className='blur-hash'
                />
            )}
        </div>
    );
});

BlurHash.propTypes = {
    hash: PropTypes.string,
    active: PropTypes.bool,
};

export default BlurHash;
