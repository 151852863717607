import { useEffect } from 'react';

const RedirectTo404 = () => {
    useEffect(() => {
        window.location.pathname = '/404';
    }, []);

    return null;
};

export default RedirectTo404;
