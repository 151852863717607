import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useFullLocation = () => {
    const [fullLocation, setFullLocation] = useState('');
    const location = useLocation();

    useEffect(() => {
        setFullLocation(`${location.pathname}${location.hash}${location.search}`);
    }, [location]); // eslint-disable-line

    return fullLocation;
};

export default useFullLocation;
