import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../button';
import Popover from '../../../popover';

import useDevice from '../../../../hooks/useDevice';

const Actions = props => {
    const {
        subItem,
        actions,
        skeleton,
    } = props;

    const { isMobile } = useDevice();

    const buttonSharedProps = {
        size: 'small',
        color: isMobile ? 'contrast' : 'accent',
    };

    return (!!actions.length || !!subItem) && (
        skeleton ? (
            <div className="skeleton-blink full-radius s-32"/>
        ) : (isMobile && (!!subItem || !actions.length)) ? (
            <Popover
                content={subItem}
                {...conditionalSpread({
                    menu: actions,
                }, !!actions.length)}
            >
                <Button
                    {...buttonSharedProps}
                    icon={{
                        size: 22,
                        type: 'icon-a-more-horiz'
                    }}
                />
            </Popover>
        ) : (
            <>
                {actions.length > 1 ? (
                    <Popover menu={actions}>
                        <Button
                            {...buttonSharedProps}
                            icon={{
                                size: 22,
                                type: 'icon-a-more-horiz'
                            }}
                        />
                    </Popover>
                ) : (
                    <Button
                        {...buttonSharedProps}
                        to={actions[0].url}
                        icon={actions[0].icon}
                        onClick={actions[0].onClick}
                    />
                )}
            </>
        )
    );
};

Actions.propTypes = {
    subItem: PropTypes.any,
    actions: PropTypes.array,
    skeleton: PropTypes.bool,
};

export default Actions;
