import React, { useRef, useEffect, useState } from 'react';
import { fakeArray } from 'clyne-core';
import PropTypes from 'prop-types';

import Input from '../input';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const SplitInput = props => {
    const {
        onChange,
        length = 6,
        autoComplete,
        placeholder = '-',
    } = props;

    const pattern = '[a-zA-Z0-9]{1}';

    const inputsRef = useRef([]);
    const [hadValue, setHadValue] = useState(false);
    const [touched, setTouched] = useState(Array(length).fill(false));
    const [localCode, setLocalCode] = useState(Array(length).fill(''));

    const {
        isMobile,
    } = useDevice();

    useEffect(() => {
        if (!isMobile) {
            inputsRef.current[0].focus();
        }
    }, [isMobile]);

    useEffect(() => {
        const value = localCode.join('');
        !!onChange && onChange(value);
        if (value) {
            setHadValue(true);
        }
    }, [localCode]); // eslint-disable-line

    const sendResult = () => {
        setLocalCode(inputsRef.current.map(input => input.value));
    };

    const handleOnChange = (e, nextElementSibling) => {
        const {
            target: { value }
        } = e;
        if (value.length > 1) {
            e.target.value = value.charAt(0);
            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else {
            if (value.match(pattern)) {
                if (nextElementSibling) {
                    nextElementSibling.focus();
                }
            } else {
                e.target.value = '';
            }
        }
        sendResult();
    };

    const handleOnKeyDown = (e, previousElementSibling, nextElementSibling) => {
        const { key } = e;
        const target = e.target;

        if (!e?.shiftKey) {
            switch (key) { // eslint-disable-line
                case 'Backspace': {
                    if (target.value === '') {
                        if (previousElementSibling) {
                            previousElementSibling.value = '';
                            previousElementSibling.focus();
                            e.preventDefault();
                        }
                    } else {
                        target.value = '';
                    }
                    sendResult();
                    break;
                }
                case 'ArrowLeft': {
                    if (previousElementSibling) {
                        previousElementSibling?.focus();
                    }
                    const input = previousElementSibling || target;
                    if (input.setSelectionRange) {
                        setTimeout(() => {
                            input.value && input.setSelectionRange(1, 1);
                        }, 0);
                    }

                    break;
                }
                case 'ArrowRight': {
                    if (target.value ? target.selectionStart : true) {
                        nextElementSibling?.focus();
                    }

                    break;
                }
            }
        }
    };

    const handleOnFocus = e => {
        e.target.select();
    };

    const handleOnPaste = (e, nextElementSibling) => {
        const pastedValue = e.clipboardData.getData('Text');

        let currentInput = 0;

        for (let i = 0; i < length; i++) {
            const pastedCharacter = pastedValue.charAt(i);
            const currentValue = inputsRef.current[currentInput].value;
            if (pastedCharacter.match(pattern)) {
                if (!currentValue) {
                    inputsRef.current[currentInput].value = pastedCharacter;
                    if (nextElementSibling) {
                        nextElementSibling.focus();
                        currentInput++;
                    }
                }
            }
        }
        sendResult();

        e.preventDefault();
    };

    return (
        <div className='split-input-holder'>
            {fakeArray(length).map((_, i) => (
                <Input
                    key={i}
                    maxLength={1}
                    size='extra-big'
                    inputMode='text'
                    autoCapitalize='off'
                    value={localCode[i]}
                    placeholder={placeholder}
                    autoComplete={autoComplete}
                    error={hadValue ? touched[i] && !localCode?.[i] : false}
                    onPaste={e => handleOnPaste(e, inputsRef.current[i + 1])}
                    onChange={e => handleOnChange(e, inputsRef.current[i + 1])}
                    onKeyDown={e => handleOnKeyDown(e, inputsRef.current[i - 1], inputsRef.current[i + 1])}
                    inputRef={el => {
                        inputsRef.current[i] = el;
                    }}
                    onFocus={e => {
                        handleOnFocus(e);
                        setTouched(val => (val.map((input, index) => index === i ? true : input)));
                    }}
                />
            ))}
        </div>
    );
};

SplitInput.propTypes = {
    length: PropTypes.number,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    autoComplete: PropTypes.string,
};

export default SplitInput;
