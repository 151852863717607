import PropTypes from 'prop-types';
import { memo } from 'react';

import useAppState from '../../hooks/useAppState';
import useModernizr from '../../hooks/useModernizr';
import useLoadToaster from '../../hooks/useLoadToaster';
import useDynamicData from '../../hooks/useDynamicData';
import useLanguageDetect from '../../hooks/useLanguageDetect';
import useLoadGoogleScript from '../../hooks/useLoadGoogleScript';
import useViewportMaximumScale from '../../hooks/useViewportMaximumScale';

const HookHydrate = memo(props => {
    const {
        children,
    } = props;

    useAppState();
    useModernizr();
    useLoadToaster();
    useDynamicData();
    useLanguageDetect();
    useLoadGoogleScript();
    useViewportMaximumScale();

    return children;
});

HookHydrate.propTypes = {
    children: PropTypes.any,
};

export default HookHydrate;
