import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import React from 'react';

import Modal from '../modal';
import Translate from '../translate';

import translate from '../../utils/translate';

import { addEditScreenModalState, myScreensModuleContentCacheState } from '../../state';

import AddEditScreen from '../addEditScreen';

const AddEditScreenModal = () => {
    const resetAddEditScreenModal = useResetRecoilState(addEditScreenModalState);
    const setMyScreensModuleContentCache = useSetRecoilState(myScreensModuleContentCacheState);
    const [addEditScreenModal, setAddEditScreenModal] = useRecoilState(addEditScreenModalState);

    return (
        <Modal
            closeOnBackdropClick={false}
            active={addEditScreenModal.active}
            onClose={() => {
                if (!addEditScreenModal.isDirty || window.confirm(translate('Are you sure want to leave? You have unsaved changes.'))) {
                    resetAddEditScreenModal();
                }
            }}
            title={addEditScreenModal.mode === 'add' ? (
                <Translate>Add Screen</Translate>
            ) : addEditScreenModal.title || (
                <Translate>Edit Screen</Translate>
            )}
            primaryButton={{
                ...addEditScreenModal.primaryButton,
                children: addEditScreenModal.mode === 'add' ? (
                    <Translate>Add</Translate>
                ) : (
                    <Translate>Save</Translate>
                ),
            }}
            topDivider={false}
            width={980}
        >
            <AddEditScreen
                id={addEditScreenModal.id}
                mode={addEditScreenModal.mode}
                setModal={setAddEditScreenModal}
                onComplete={() => {
                    setMyScreensModuleContentCache(val => val + 1);
                    resetAddEditScreenModal();
                }}
            />
        </Modal>
    );
};

export default AddEditScreenModal;
