import clasNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Icon from '../icon';
import Badge from '../badge';
import Tooltip from '../tooltip';
import Translate from '../translate';

import useCopy from '../../hooks/useCopy';

import './index.scss';

const ScreenConsoleItem = forwardRef(function ScreenConsoleItem(props, ref) {
    const {
        badge,
        label,
        onClick,
        subIcon,
        className,
        copyValue,
        leadingIcon,
    } = props;

    const {
        icon,
        canCopy,
        className: copyClassName,
        handleCopy,
    } = useCopy();

    return (
        <div
            ref={ref}
            onClick={e => {
                onClick(e);
                document.activeElement?.blur();
            }}
            onKeyDown={e => {
                if (e.code === 'Space' || e.code === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                    onClick(e);
                    document.activeElement?.blur();
                }
            }}
            tabIndex={0}
            className={clasNames(
                'screen-console-item',
                className,
            )}
        >
            <Icon
                size={18}
                type={leadingIcon}
                className='sci-leading-icon'
            />
            <p>{label}</p>
            {badge && (
                <Badge
                    badge
                    size='small'
                    position='static'
                />
            )}
            {canCopy(copyValue) && (
                <Tooltip
                    content={
                        <Translate>Copy</Translate>
                    }
                >
                    <Icon
                        size={18}
                        type={icon}
                        className={clasNames(
                            copyClassName,
                            'sci-sub-icon',
                        )}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleCopy(copyValue);
                        }}
                    />
                </Tooltip>
            )}
            <Icon
                size={18}
                type={subIcon}
                className='sci-sub-icon'
            />
        </div>
    );
});

ScreenConsoleItem.propTypes = {
    badge: PropTypes.bool,
    label: PropTypes.any,
    onClick: PropTypes.func,
    subIcon: PropTypes.string,
    className: PropTypes.string,
    copyValue: PropTypes.string,
    leadingIcon: PropTypes.string,
};

export default ScreenConsoleItem;
