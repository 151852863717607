import { conditionalSpread, isFunction } from 'clyne-core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import React, { useState, memo } from 'react';
import PropTypes from 'prop-types';

import Modal from '../modal';
import Translate from '../translate';
import RunCommand from '../runCommand';
import ScreenConsole from '../screenConsole';
import SyncScreenForm from '../syncScreenForm';

import { addEditScreenModalState, dynamicDataState, myScreensModuleContentCacheState, workspaceIdState } from '../../state';

import addToast from '../../utils/addToast';

import { idFormatter, successStatus, versionToNum } from '../../helpers';

import useScreenConsole from '../../hooks/useScreenConsole';

import connectionService from '../../services/connectionService';

import { verificationHashLength } from '../../constants/construct';

const modalInitialState = {
    active: false,
    loading: false,
};

const ScreenActions = memo(props => {
    const {
        id,
        name,
        device,
        synced,
        visible,
        children,
        updateById,
    } = props;

    const title = [idFormatter(id), name].filter(Boolean).join(' - ');

    const dynamicData = useRecoilValue(dynamicDataState);
    const workspaceId = useRecoilValue(workspaceIdState);
    const setAddEditScreenModal = useSetRecoilState(addEditScreenModalState);
    const setMyScreensModuleContentCache = useSetRecoilState(myScreensModuleContentCacheState);

    const [code, setCode] = useState('');
    const [cache, setCache] = useState(0);
    const [modal, setModal] = useState(modalInitialState);
    const [screenConsoleActive, setScreenConsoleActive] = useState(false);

    const litAppVersion = device?.device?.litAppVersion;
    const litNativeVersion = device?.device?.litNativeVersion;
    const bionicAppVersion = device?.device?.bionicAppVersion;
    const bionicNativeVersion = device?.device?.bionicNativeVersion;

    const hasLit = !!litNativeVersion;
    const hasLitNativeUpdate = !!hasLit && versionToNum(litNativeVersion) < versionToNum(dynamicData?.availableVersions?.lit?.native);
    const hasLitAppUpdate = !!hasLit && !hasLitNativeUpdate && versionToNum(litAppVersion) < versionToNum(dynamicData?.availableVersions?.lit?.app);

    const hasBionic = !!bionicNativeVersion;
    const hasBionicNativeUpdate = !!hasBionic && versionToNum(bionicNativeVersion) < versionToNum(dynamicData?.availableVersions?.bionic?.native);
    const hasBionicAppUpdate = !!hasBionic && !hasBionicNativeUpdate && versionToNum(bionicAppVersion) < versionToNum(dynamicData?.availableVersions?.bionic?.app);

    const updatedNeeded = (hasLitAppUpdate || hasLitNativeUpdate || hasBionicAppUpdate || hasBionicNativeUpdate) && !device?.device?.simulator;

    const handleActionClick = (id, item) => {
        if (item.action === 'edit') {
            setAddEditScreenModal(val => ({
                ...val,
                active: true,
                mode: 'edit',
                id,
            }));
        } else {
            item.modal ? setModal(val => ({
                ...val,
                item,
                ...item.modal,
                id: id,
                active: true,
                action: item.action,
                primaryButton: {
                    ...item.modal.primaryButton,
                },
            })) : handleModalSubmit(id, item);
        }
    };

    const handleModalSubmit = (id, item) => {
        const {
            action,
            onSuccess: {
                toast,
                callBack,
            },
        } = item;

        if (action === 'sync' && code.length !== verificationHashLength) {
            return addToast({
                type: 'info',
                message: <Translate>Please provide verification hash</Translate>,
            });
        } else {
            setModal(val => ({ ...val, loading: true }));
            const url = `/workspaces/${workspaceId}/screens/${id}`;

            if (action === 'delete') {
                connectionService.deleteJson(url).subscribe(res => {
                    setModal(val => ({ ...val, loading: false }));
                    if (successStatus(res)) {
                        addToast({
                            type: 'info',
                            message: (
                                <Translate replaceMap={{ _NAME_: title }}>_NAME_ has been successfully deleted</Translate>
                            ),
                        });
                        setMyScreensModuleContentCache(val => val + 1);
                    } else {
                        if (res?.data?.message) {
                            switch (res?.data?.message) {
                                case 'SCREEN_HAS_ACTIVE_CAMPAIGNS':
                                    addToast({
                                        type: 'error',
                                        title: <Translate>Can't delete the screen</Translate>,
                                        message: <Translate>This screen has active campaigns, therefore you can't delete it.</Translate>,
                                    });
                                    break;
                                default:
                                    addToast({
                                        type: 'error',
                                        message: res?.data?.message,
                                    });
                                    break;
                            }
                        } else {
                            addToast({
                                type: 'error',
                                message: <Translate>Something went wrong, please try again</Translate>,
                            });
                        }
                    }
                    setModal(modalInitialState);
                });
            } else {
                const params = {
                    action,
                    ...conditionalSpread({
                        data: {
                            code,
                        }
                    }, action === 'sync'),
                };

                connectionService.putJson(`${url}/device`, params).subscribe(res => {
                    if (successStatus(res)) {
                        addToast(toast);
                        setCode('');
                        setCommand('');
                        setModal(modalInitialState);
                        typeof callBack === 'function' && callBack();
                    }
                    setModal(val => ({ ...val, loading: false }));
                });
            }
        }
    };

    const menu = [
        {
            action: 'edit',
            icon: 'icon-a-edit',
            name: <Translate>Edit</Translate>,
        },
        ...conditionalSpread([
            {
                divider: true,
            },
            {
                badge: updatedNeeded,
                icon: 'icon-a-processor',
                name: <Translate>Console</Translate>,
                onClick: () => setScreenConsoleActive(true),
            },
        ], synced),
        {
            divider: true,
        },
        (synced ? {
            action: 'un-sync',
            icon: 'icon-a-cloud-cross',
            name: <Translate>Un-Sync</Translate>,
            modal: {
                primaryButton: {
                    children: <Translate>Un-Sync</Translate>,
                },
                message: {
                    type: 'un-sync',
                    title: <Translate>Un-Sync Screen</Translate>,
                    children: <Translate>Are you sure you want to un-sync the screen?</Translate>,
                },
            },
            onSuccess: {
                callBack: () => isFunction(updateById, false) && updateById(id),
                toast: {
                    type: 'info',
                    message: <Translate replaceMap={{ _NAME_: title }}>_NAME_ has been successfully un-synced</Translate>,
                },
            },
        } : {
            action: 'sync',
            icon: 'icon-a-cloud',
            name: <Translate>Sync</Translate>,
            modal: {
                primaryButton: {
                    children: <Translate>Sync</Translate>,
                },
                message: {
                    type: 'sync',
                    title: <Translate>Sync Screen</Translate>,
                    children: <Translate>Enter verification hash from the screen</Translate>,
                    node: (
                        <SyncScreenForm
                            setCode={setCode}
                        />
                    ),
                },
            },
            onSuccess: {
                callBack: () => isFunction(updateById, false) && updateById(id),
                toast: {
                    type: 'success',
                    message: <Translate replaceMap={{ _NAME_: title }}>_NAME_ has been successfully synced</Translate>,
                },
            },
        }),
        {
            divider: true,
        },
        (visible ? {
            action: 'hide',
            icon: 'icon-a-eye-slash',
            name: <Translate>Hide</Translate>,
            modal: {
                primaryButton: {
                    children: <Translate>Hide</Translate>,
                },
                message: {
                    type: 'hide',
                    title: <Translate>Hide Screen</Translate>,
                    children: <Translate>Are you sure you want to hide the screen? It won't be visible on the map.</Translate>,
                },
            },
            onSuccess: {
                callBack: () => isFunction(updateById, false) && updateById(id),
                toast: {
                    type: 'info',
                    message: <Translate replaceMap={{ _NAME_: title }}>_NAME_ has been hidden from the map</Translate>,
                },
            },
        } : {
            action: 'show',
            icon: 'icon-a-eye',
            name: <Translate>Show</Translate>,
            onSuccess: {
                callBack: () => isFunction(updateById, false) && updateById(id),
                toast: {
                    type: 'success',
                    message: <Translate replaceMap={{ _NAME_: title }}>_NAME_ is now visible on the map</Translate>,
                },
            },
        }),
        {
            divider: true,
        },
        {
            danger: true,
            action: 'delete',
            icon: 'icon-a-trash',
            name: <Translate>Delete</Translate>,
            modal: {
                primaryButton: {
                    children: <Translate>Delete</Translate>,
                },
                message: {
                    type: 'delete',
                    title: <Translate>Delete Screen</Translate>,
                    children: <Translate>Are you sure you want to delete the screen? This can't be undone.</Translate>,
                },
            },
            onSuccess: {
                toast: {
                    type: 'info',
                    message: <Translate replaceMap={{ _NAME_: title }}>_NAME_ has been successfully deleted</Translate>,
                },
            },
        }
    ].map(item => ({
        ...item,
        ...conditionalSpread({
            onClick: () => {
                handleActionClick(id, item);
            },
        }, item?.action),
    }));

    const {
        command,
        loading,
        deviceData,
        setCommand,
        onActionSelect,
        setCache: setConsoleCache,
    } = useScreenConsole({
        id,
        deviceUid: device?.duid,
        active: screenConsoleActive,
    });

    return (
        <>
            {children({
                menu,
                cache,
                setCommand,
                updatedNeeded,
            })}
            <Modal
                {...modal}
                title={title}
                topDivider={false}
                primaryButton={{
                    loading: modal.loading,
                    onClick: () => handleModalSubmit(modal.id, modal.item),
                    ...modal.primaryButton,
                }}
                onClose={() => setModal(modalInitialState)}
            />
            <ScreenConsole
                id={id}
                name={name}
                command={command}
                loading={loading}
                setCommand={setCommand}
                setCache={setConsoleCache}
                active={screenConsoleActive}
                onActionSelect={onActionSelect}
                RunCommandComponent={RunCommand}
                deviceData={Object.keys(deviceData || {}).length ? deviceData : { lit: device }}
                onClose={() => {
                    setCache(val => val + 1);
                    setScreenConsoleActive(false);
                    isFunction(updateById, false) && updateById(id);
                }}
            />
        </>
    );
});

ScreenActions.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    device: PropTypes.object,
    synced: PropTypes.bool,
    visible: PropTypes.bool,
    children: PropTypes.func,
    updateById: PropTypes.func,
};

export default ScreenActions;
