import { conditionalSpread } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import { useState } from 'react';

import addToast from '../utils/addToast';
import translate from '../utils/translate';

import { workspaceIdState } from '../state';

import useConnector from './useConnector';

import connectionService from '../services/connectionService';

import { successStatus } from '../helpers';

const useScreenConsole = props => {
    const {
        id,
        active,
        deviceUid,
    } = props;

    const workspaceId = useRecoilValue(workspaceIdState);

    const [cache, setCache] = useState(0);
    const [command, setCommand] = useState('');

    const {
        loading,
        initialLoading,
        data: deviceData,
    } = useConnector(`/workspaces/${workspaceId}/screens/${id}/device/${deviceUid}`, {}, {}, false, active, 'replace', cache);

    const onActionSelect = action => new Promise((resolve, reject) => {
        if (action === 'RUN_COMMAND' && !command.length) {
            reject();
            return addToast({
                type: 'info',
                message: translate(`You can't send an empty command`),
            });
        }

        const url = `/workspaces/${workspaceId}/screens/${id}`;

        const params = {
            action,
            ...conditionalSpread({
                data: {
                    command,
                }
            }, action === 'RUN_COMMAND'),
        };

        connectionService.putJson(`${url}/device`, params).subscribe(res => {
            if (successStatus(res)) {
                resolve();
            } else {
                reject();
            }
        });
    });

    return ({
        command,
        loading,
        setCache,
        deviceData,
        setCommand,
        initialLoading,
        onActionSelect,
    });
};

export default useScreenConsole;
