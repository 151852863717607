import React from 'react';

import features from '../configs/features';

import { reportsRoutes } from '../containers/reports/data';
import { userRoutes, userRoutesData } from '../containers/user/data';
import { authenticationData, authenticationRoutes } from '../containers/authentication/data';

import { userRoles } from '../constants/construct';

import { supportEmail } from '../helpers';

import translate from '../utils/translate';
import lazyWithRetry from '../utils/lazyWithRetry';
import identityHubSpotUser from '../utils/identityHubSpotUser';

import languages from '../configs/languages';

import whiteLabel from '../constants/whiteLabel';

const Home = lazyWithRetry(() => import('../containers/home').then());
const User = lazyWithRetry(() => import('../containers/user').then());
const Help = lazyWithRetry(() => import('../containers/help').then());
const Terms = lazyWithRetry(() => import('../containers/terms').then());
const LogOut = lazyWithRetry(() => import('../containers/logOut').then());
const Reports = lazyWithRetry(() => import('../containers/reports').then());
const Messages = lazyWithRetry(() => import('../containers/messages').then());
const Calendar = lazyWithRetry(() => import('../containers/calendar').then());
const AddMedia = lazyWithRetry(() => import('../containers/addMedia').then());
const Schedule = lazyWithRetry(() => import('../containers/schedule').then());
const Checkout = lazyWithRetry(() => import('../containers/checkout').then());
const OneStatus = lazyWithRetry(() => import('../containers/oneStatus').then());
const Campaigns = lazyWithRetry(() => import('../containers/campaigns').then());
const Dashboard = lazyWithRetry(() => import('../containers/dashboard').then());
const MyScreens = lazyWithRetry(() => import('../containers/myScreens').then());
const ScreenView = lazyWithRetry(() => import('../containers/screenView').then());
const OneConsole = lazyWithRetry(() => import('../containers/oneConsole').then());
const HelpArticle = lazyWithRetry(() => import('../containers/helpArticle').then());
const PageNotFound = lazyWithRetry(() => import('../containers/pageNotFound').then());
const SearchResults = lazyWithRetry(() => import('../containers/searchResults').then());
const CampaignReview = lazyWithRetry(() => import('../containers/campaignReview').then());
const BecomeAPartner = lazyWithRetry(() => import('../containers/becomeAPartner').then());
const Authentication = lazyWithRetry(() => import('../containers/authentication').then());
const FinalizeCampaign = lazyWithRetry(() => import('../containers/finalizeCampaign').then());

const sharedProps = {
    footer: () => true,
    header: {
        search: true,
        appearance: 'default',
    },
    wizard: false,
    protected: false,
    guide: {
        fit: false,
        rubber: false,
        middle: false,
        enabled: true,
    },
    meta: {
        title: '',
    },
};

export const routes = [
    {
        ...sharedProps,
        name: translate('Home'),
        slug: 'home',
        url: [
            '/',
            ...languages.flatMap(language => `/${language.code}`),
            '/yerevan',
            '/j',
            '/d',
            '/e',
        ],
        icon: 'icon-a-home',
        component: <Home />,
        header: {
            ...sharedProps.header,
            search: false,
        },
        instantRender: true,
        meta: {
            title: whiteLabel?.meta?.title || process.env.REACT_APP_META_TITLE,
            description: whiteLabel?.meta?.description || process.env.REACT_APP_META_DESCRIPTION,
        },
    },
    {
        ...sharedProps,
        url: Object.values(authenticationRoutes),
        icon: 'icon-a-user',
        subRoutes: Object.keys(authenticationRoutes).map(key => ({
            ...authenticationData[authenticationRoutes[key]],
            ...sharedProps,
            meta: {
                ...sharedProps.meta,
                ...authenticationData[authenticationRoutes[key]]?.meta || {},
            },
            url: authenticationRoutes[key],
            header: {
                ...sharedProps.header,
                appearance: 'logo',
            },
            guide: {
                ...sharedProps.guide,
                middle: true,
            },
            footer: () => false,
        })),
        component: <Authentication />,
    },
    {
        ...sharedProps,
        name: translate('Me'),
        icon: 'icon-a-user',
        url: 'user/*',
        virtualUrl: Object.values(userRoutes),
        subRoutes: userRoutesData.map(route => ({
            ...route,
            ...sharedProps,
            footer: isMobile => isMobile,
            protected: true,
            guide: {
                ...sharedProps.guide,
                fit: true,
            },
        })),
        component: <User />,
    },
    {
        ...sharedProps,
        name: translate('Become a Partner'),
        slug: 'becomeAPartner',
        icon: 'icon-a-briefcase',
        url: '/become-a-partner',
        instantRender: true,
        externalOnWhiteLabel: true,
        component: <BecomeAPartner />,
        meta: {
            description: translate('Start a partnership with Advelit now! Anyone who owns a public screen can monetize it by showing ads. Fill out the form and start expanding your network globally.'),
        },
    },
    {
        ...sharedProps,
        name: translate('Advelit One Status'),
        slug: 'oneStatus',
        url: '/one-status',
        instantRender: true,
        externalOnWhiteLabel: true,
        component: <OneStatus />,
        header: {
            ...sharedProps.header,
            appearance: 'logo',
        },
        footer: () => false,
    },
    {
        ...sharedProps,
        name: translate('My Campaigns'),
        slug: 'myCampaigns',
        url: '/my-campaigns',
        icon: 'icon-a-megaphone',
        component: <Campaigns mode={userRoles.client} />,
        protected: true,
    },
    {
        ...sharedProps,
        url: '/terms/:slug',
        icon: 'icon-a-note',
        instantRender: true,
        component: <Terms />,
    },
    {
        ...sharedProps,
        name: translate('Log Out'),
        slug: 'logOut',
        url: '/log-out',
        icon: 'icon-a-sign-out',
        protected: false,
        component: <LogOut />,
    },
    {
        ...sharedProps,
        name: translate('Messages'),
        url: ['/messages', '/messages/:scope', '/messages/:scope/:id'],
        protected: true,
        component: <Messages />,
        icon: 'icon-a-email',
        footer: () => false,
        slug: 'messages',
        guide: {
            ...sharedProps.guide,
            fit: true,
            padding: false,
        },
    },
    {
        ...sharedProps,
        name: translate('One Console'),
        url: '/one-console',
        protected: true,
        component: <OneConsole />,
        icon: 'icon-a-processor',
        footer: () => false,
        slug: 'oneConsole',
        externalOnWhiteLabel: true,
        guide: {
            ...sharedProps.guide,
            fit: true,
            padding: false,
        },
    },
    {
        ...sharedProps,
        name: translate('Search Screens'),
        url: [
            '/s',
            '/demo',
        ],
        component: <SearchResults />,
        icon: 'icon-a-search',
        footer: () => false,
        guide: {
            ...sharedProps.guide,
            rubber: true,
            enabled: false,
        },
        header: {
            ...sharedProps.header,
            appearance: 'compact',
        },
        wizard: {
            key: 'campaign',
            step: 0,
            footer: () => false,
        },
        meta: {
            description: translate('Search for Digital Screens in your area or anywhere in the world, filter by your budget, rating, placement, working hours, or type, add your media, and run your campaign!'),
        },
    },
    {
        ...sharedProps,
        name: translate('Timing Options'),
        url: '/campaign/schedule',
        component: <Schedule />,
        icon: 'icon-a-time',
        footer: () => false,
        protected: true,
        guide: {
            ...sharedProps.guide,
            fit: true,
        },
        wizard: {
            key: 'campaign',
            step: 1,
            footer: () => true,
            cta: {
                disabled: campaignData => !campaignData?.id,
                children: translate('Add Media'),
            },
        },
    },
    {
        ...sharedProps,
        name: translate('Upload Media'),
        url: '/campaign/add-media',
        component: <AddMedia />,
        icon: 'icon-a-image',
        footer: () => false,
        protected: true,
        wizard: {
            key: 'campaign',
            step: 2,
            footer: () => true,
            cta: {
                children: translate('Review'),
            },
        },
    },
    {
        ...sharedProps,
        name: translate('Review'),
        url: '/campaign/review',
        component: <CampaignReview />,
        footer: () => false,
        icon: 'icon-a-checkbox-square',
        protected: true,
        guide: {
            ...sharedProps.guide,
            fit: true,
        },
        wizard: {
            key: 'campaign',
            step: 3,
            footer: () => true,
            cta: {
                children: translate('Checkout'),
            },
        },
    },
    {
        ...sharedProps,
        name: translate('Checkout'),
        url: '/campaign/checkout',
        component: <Checkout />,
        footer: () => false,
        protected: true,
        icon: 'icon-a-dollar',
        guide: {
            ...sharedProps.guide,
            fit: true,
        },
        wizard: {
            key: 'campaign',
            step: 4,
            footer: () => true,
            cta: {
                children: translate('Submit for Review'),
            },
        },
    },
    {
        ...sharedProps,
        name: translate('Finalize'),
        url: '/campaign/finalize',
        component: <FinalizeCampaign />,
        footer: () => false,
        icon: 'icon-a-checkbox-circle',
        protected: true,
        guide: {
            ...sharedProps.guide,
            middle: true,
            fit: true,
        },
        wizard: {
            key: 'campaign',
            step: 5,
            footer: () => false,
        },
    },
    {
        ...sharedProps,
        name: translate('Screen View'),
        url: '/screen/:id',
        instantRender: true,
        component: <ScreenView />,
        icon: 'icon-a-monitor',
    },
    {
        ...sharedProps,
        slug: 'wpDashboard',
        name: translate('Dashboard'),
        url: '/dashboard',
        protected: true,
        icon: 'icon-a-elements',
        externalOnWhiteLabel: true,
        component: <Dashboard />,
        guide: {
            ...sharedProps.guide,
            middle: true,
        },
    },
    {
        ...sharedProps,
        icon: 'icon-a-monitor',
        slug: 'wpScreens',
        name: translate('Screens'),
        url: '/screens',
        component: <MyScreens />,
        protected: true,
        externalOnWhiteLabel: true,
    },
    {
        ...sharedProps,
        slug: 'wpCampaigns',
        name: translate('Campaigns'),
        url: '/campaigns',
        icon: 'icon-a-megaphone',
        component: <Campaigns mode={userRoles.partner} />,
        protected: true,
        externalOnWhiteLabel: true,
    },
    {
        ...sharedProps,
        slug: 'wpCalendar',
        name: translate('Calendar'),
        url: '/calendar',
        component: <Calendar />,
        protected: true,
        icon: 'icon-a-calendar',
        externalOnWhiteLabel: true,
    },
    {
        ...sharedProps,
        slug: 'wpReports',
        name: translate('Reports'),
        url: '/reports',
        protected: true,
        component: <Reports />,
        subMenu: reportsRoutes,
        icon: 'icon-a-note',
        externalOnWhiteLabel: true,
    },
    ...reportsRoutes.map(route => ({
        ...route,
        ...sharedProps,
        protected: true,
        externalOnWhiteLabel: true,
        guide: {
            ...sharedProps.guide,
            ...route.guide,
        },
    })),
    {
        ...sharedProps,
        name: translate('Help'),
        slug: 'help',
        url: '/help',
        component: <Help />,
        icon: 'icon-a-question',
        onClick: e => {
            const element = e.target;
            const className = 'loading';
            element.classList.remove(className);
            element.classList.add(className);
            const hs = window?.HubSpotConversations;
            const hsWidget = hs?.widget;

            const removeClassName = () => element.classList.remove(className);

            if (hsWidget) {
                hs.on('widgetClosed', removeClassName);
                hs.on('widgetLoaded', removeClassName);

                if (document.documentElement.classList.contains('hs-messages-widget-open')) {
                    hsWidget.close();
                    element.classList.remove(className);
                } else {
                    identityHubSpotUser();
                    hsWidget.load({ widgetOpen: true });
                    hsWidget.open();
                }
            } else {
                element.classList.remove(className);
                window.location.href = `mailto:${supportEmail}`;
            }
        },
    },
    ...(features.help.enabled ? [
        {
            ...sharedProps,
            name: 'HelpItem',
            url: '/help/:category/:topic',
            icon: 'icon-a-question',
            component: <HelpArticle />,
            externalOnWhiteLabel: true,
        },
    ] : []),
    {
        ...sharedProps,
        url: '/404',
        name: translate('Page Not Found'),
        component: <PageNotFound />,
    },
];

export const fallbackRoute = {
    ...routes[0],
    url: routes[0].url[0],
};
