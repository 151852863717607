import { conditionalSpread } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import classNames from 'classnames';

import Button from '../button';

import features from '../../configs/features';
import products from '../../configs/products';

import { dynamicDataState } from '../../state';

import './index.scss';

const KitActions = () => {
    const dynamicData = useRecoilValue(dynamicDataState);

    const sharedProps = {
        loading: !dynamicData?.availableVersions,
        download: true,
        size: 'extra-big',
        flexibility: 'fit'
    };

    return (
        <ul className='kit-ctas-holder'>
            {!!features.bionic && (
                <li>
                    <Button
                        {...sharedProps}
                        href={dynamicData?.availableVersions?.bionic?.url}
                        icon={{
                            type: products.bionic.icon,
                        }}
                    >
                        {`Get ${products.bionic.label}`}
                    </Button>
                </li>
            )}
            <li
                className={classNames(
                    {
                        'animation-fadeIn': !!features.bionic,
                    }
                )}
            >
                <Button
                    {...sharedProps}
                    href={dynamicData?.availableVersions?.lit?.url}
                    {...conditionalSpread({
                        color: 'accent',
                    }, !!features.bionic)}
                    icon={{
                        type: products.lit.icon,
                    }}
                >
                    {`Get ${products.lit.label}`}
                </Button>
            </li>
        </ul>
    );
};

export default KitActions;
