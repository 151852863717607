import PropTypes from 'prop-types';

import { cellConfig } from '../cell/props';

export const rowProps = {
    row: PropTypes.object,
    type: PropTypes.oneOf(cellConfig.type),
    total: PropTypes.bool,
    subItem: PropTypes.any,
    actions: PropTypes.any,
    children: PropTypes.any,
    skeleton: PropTypes.bool,
    collapsed: PropTypes.bool,
    cardProps: PropTypes.shape({
        shadow: PropTypes.bool,
        border: PropTypes.bool,
    }),
    colSpanTotal: PropTypes.number,
};
