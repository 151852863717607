import { conditionalSpread } from 'clyne-core';

import { userRoles } from '../constants/construct';

import { isWhiteLabel } from '../helpers';

const getNavigation = (userRole, type) => {
    switch (type) {
        case 'guest':
            return ([
                ...conditionalSpread([
                    'becomeAPartner',
                ], !isWhiteLabel),
                'help',
            ]);
        case 'user':
            return ([
                ...conditionalSpread(['becomeAPartner'], userRole === userRoles.client),
                'myCampaigns',
                'help',
            ]);
        case 'partner':
            return ([
                'wpDashboard',
                'wpScreens',
                'wpCampaigns',
                'wpCalendar',
                'wpReports',
                'help',
            ]);
        default:
            return [];
    }
};

export default getNavigation;
