import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Knob from '../knob';
import Input from '../input';
import Popover from '../popover';
import Translate from '../translate';
import DatePicker from '../datePicker';

import translate from '../../utils/translate';

import { dateFormat } from '../../constants/construct';

import { isWhiteLabel } from '../../helpers';

import { popperConfig } from '../../props';

import { datePickerConfig } from '../datePicker/props';

import { datePickerInputConfig, datePickerInputProps } from './props';

import './index.scss';

const dateFormatter = date => moment(date).format(dateFormat);

const DatePickerInput = props => {
    const {
        end,
        size,
        start,
        error,
        label,
        minDate,
        maxDate,
        required,
        onChange,
        disabled,
        cornerRadius,
        retainStartDateOnSelection,
        type = datePickerConfig.type[0],
        position = popperConfig.position[10],
        appearance = datePickerInputConfig.appearance[0],
        placeholder = translate('Select Dates'),
    } = props;

    const [openedState, setOpenedState] = useState(false);
    const [shouldSetDates, setShouldSetDates] = useState(false);
    const isRangePicker = type === datePickerConfig.type[1];

    const [range, setRange] = useState({
        startDate: start || new Date(),
        endDate: end || new Date(),
        key: 'selection',
    });

    useEffect(() => {
        start && end && setRange(val => ({
            ...val,
            startDate: start,
            endDate: end
        }));
    }, [start, end]);

    const isRange = start && end;
    const hasValue = isRangePicker ? isRange : !!start;

    const value = hasValue && (
        isRangePicker ? (
            <>
                {dateFormatter(range.startDate)}
                <span className='datepicker-range-separator'>
                    {` `}
                    <Translate>to</Translate>
                    {` `}
                </span>
                {dateFormatter(range.endDate)}
            </>
        ) : dateFormatter(start || new Date())
    );

    const sharedProps = {
        label,
        value,
        error,
        required,
        disabled,
        placeholder: translate(placeholder),
        onClick: () => setOpenedState(val => !val),
    };

    return (
        <Popover
            padding
            position={position}
            opened={openedState}
            onClose={() => setOpenedState(false)}
            onContentClick={() => setShouldSetDates(true)}
            onCloseState={() => {
                if (shouldSetDates && isRangePicker && onChange) {
                    onChange([range.startDate, range.endDate]);
                    setShouldSetDates(false);
                }
            }}
            content={
                <DatePicker
                    type={type}
                    date={start}
                    ranges={[range]}
                    minDate={minDate}
                    maxDate={maxDate}
                    retainStartDateOnSelection={retainStartDateOnSelection}
                    onChange={isRangePicker ? val => setRange(val.selection) : val => {
                        onChange(val);
                        setOpenedState(false);
                    }}
                />
            }
        >
            {appearance === 'knob' ? (
                <Knob
                    {...sharedProps}
                    startDivider={false}
                    clearable={!!hasValue}
                    endDivider={isWhiteLabel}
                />
            ) : (
                <Input
                    type='date'
                    size={size}
                    {...sharedProps}
                    cornerRadius={cornerRadius}
                />
            )}
        </Popover>
    );
};

DatePickerInput.propTypes = datePickerInputProps;

export default DatePickerInput;
