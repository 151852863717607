import { conditionalSpread } from 'clyne-core';
import React, { Fragment } from 'react';
import classNames from 'classnames';

import To from '../to';
import Icon from '../icon';
import Button from '../button';
import Avatar from '../avatar';
import Popover from '../popover';
import Translate from '../translate';
import Favourites from '../favourites';
import MiniMessages from '../miniMessages';
import Notifications from '../notifications';

import { isWhiteLabel, successStatus } from '../../helpers';

import features from '../../configs/features';

import translate from '../../utils/translate';
import getRoutesBySlug from '../../utils/getRoutesBySlug';

import { userRoles, notificationKeys } from '../../constants/construct';
import { NOTIFICATIONS, PROFILE, URLS } from '../../constants/apiKeys';

import connectionService from '../../services/connectionService';

import { userRoutes, userRoutesData } from '../../containers/user/data';
import { authenticationRoutes } from '../../containers/authentication/data';

const popoverProps = {
    sticky: true,
    position: 'bottom-end',
    offsetBounding: [0, 25],
    maxItems: 3,
    itemHeight: 86,
};

const popoverButtonProps = {
    size: 'default',
    color: 'accent',
    cornerRadius: 'smooth',
};

export const headerNavigation = props => {
    const {
        user,
        isMobile,
        pathname,
        navigation,
        setNavigation,
        setMobileNavigationOpened,
    } = props;

    const routeContentRenderer = route => (
        <>
            {isMobile && (
                <Icon
                    size={22}
                    type={`${route.icon}${route.active ? '-active' : ''}`}
                    className='route-icon'
                />
            )}
            <span className='pointer-events-none'>{translate(route.name)}</span>
            {(isMobile || !!route.subMenu) && (
                <Icon
                    size={isMobile ? 24 : 18}
                    type={isMobile ? 'icon-a-chevron-right' : 'icon-a-chevron-down'}
                />
            )}
        </>
    );

    return (
        <nav
            className={classNames(
                {
                    'reduce-gap': navigation?.length > 4,
                }
            )}
        >
            {navigation.map(route => {
                const subMenu = route?.subMenu?.filter(route => user?.sa ? true : !route.superAdminOnly);

                return (
                    <Fragment key={route.url}>
                        {subMenu ? (
                            <div
                                className={classNames(
                                    'sub-menu-holder',
                                    {
                                        'opened': route.opened,
                                    }
                                )}
                            >
                                <div
                                    className={classNames(
                                        `route`,
                                        {
                                            'active': subMenu.some(route => route.url === pathname),
                                        }
                                    )}
                                    onClick={() => setNavigation(val => val.map(item => ({ ...item, opened: item.url === route.url ? !item.opened : false })))}
                                >
                                    {routeContentRenderer({ ...route, active: subMenu.some(route => route.url === pathname) })}
                                </div>
                                <div
                                    className='route-submenu-positioner'
                                    style={{
                                        '--items-count': subMenu.length,
                                    }}
                                >
                                    <ul>
                                        {subMenu.map((route, index) => (
                                            <li key={index}>
                                                <To
                                                    url={route.url}
                                                    onClick={route.onClick}
                                                    className={classNames({
                                                        'route': isMobile,
                                                    })}
                                                    onTap={() => setMobileNavigationOpened(false)}
                                                >
                                                    <span>{translate(route.name)}</span>
                                                </To>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ) : (
                            <To
                                url={route?.externalOnWhiteLabel && isWhiteLabel ? `${process.env.REACT_APP_FRONTEND_URL}${route.url}` : route.url}
                                className='route'
                                onClick={route.onClick}
                                onTap={() => setMobileNavigationOpened(false)}
                                {...conditionalSpread({ href: route.url }, !route.component)}
                                {...conditionalSpread({
                                    target: '_blank',
                                }, route?.externalOnWhiteLabel && isWhiteLabel)}
                            >
                                <Icon
                                    size={16}
                                    type='icon-a-loader'
                                />
                                {routeContentRenderer({ ...route, active: pathname === route.url })}
                            </To>
                        )}
                    </Fragment>
                );
            })}
        </nav>
    );
};

export const headerAuthentication = props => {
    const {
        isMobile,
    } = props;

    const sharedProps = {
        size: isMobile ? 'big' : 'medium',
        flexibility: isMobile ? 'full' : 'fit',
    };

    return (
        <div className='header-cta'>
            <Button
                color='grayscale'
                {...sharedProps}
                to={authenticationRoutes.signIn}
            >
                <Translate>Sign In</Translate>
            </Button>
            <Button
                {...conditionalSpread({
                    hero: 'pureWhite',
                }, isMobile)}
                {...sharedProps}
                to={authenticationRoutes.signUp}
            >
                <Translate>Sign Up</Translate>
            </Button>
        </div>
    );
};

export const headerMenu = props => {
    const {
        user,
        isMobile,
        userRole,
        favourites,
        workspaceId,
        dynamicData,
        hasConversation,
        setHasConversation,
        notificationsAreRead,
        setDynamicDataTrigger,
        setNotificationsAreRead,
        unitedNotificationsCount,
        notificationsSelectedTab,
        setNotificationsSelectedTab,
        readAllNotificationsLoading,
        miniNotificationsScrollerRef,
        setReadAllNotificationsLoading,
    } = props;

    const miniNotificationsData = {
        [notificationKeys.notifications]: {
            title: user?.givenName,
        },
        ...conditionalSpread({
            [notificationKeys.workspaceNotifications]: {
                title: user?.workspace?.name,
            },
        }, userRole === userRoles.partner),
    };

    const selectedMiniNotificationsId = Object.keys(miniNotificationsData).indexOf(notificationsSelectedTab);

    const handleReadAllNotifications = () => {
        setReadAllNotificationsLoading(true);
        const url = `${selectedMiniNotificationsId ? `/workspaces/${workspaceId}` : ''}${NOTIFICATIONS}/read/all`;
        connectionService.putJson(url).subscribe(res => {
            if (successStatus(res)) {
                setNotificationsAreRead(val => ({
                    ...val,
                    [notificationsSelectedTab]: true,
                }));
            }
            setReadAllNotificationsLoading(false);
        });
    };

    const notificationsSharedProps = {
        notificationsAreRead,
        miniNotificationsData,
        setNotificationsSelectedTab,
        selectedMiniNotificationsId,
        miniNotificationsScrollerRef,
    };

    const mobileContent = [
        {
            icon: 'icon-a-heart',
            badge: favourites?.length,
            maxItems: 2,
            itemHeight: 303,
            ...conditionalSpread({
                module: {
                    header: {
                        title: <Translate>Favorites</Translate>,
                        subTitle: (
                            <Translate replaceMap={{ '_COUNT_': favourites?.length }}>You have _COUNT_ favorite screens</Translate>
                        ),
                    },
                },
            }, !!favourites?.length),
            children: (
                <Favourites />
            ),
        },
        ...conditionalSpread([
            {
                icon: 'icon-a-email',
                badge: dynamicData.messages.count,
                itemHeight: 100,
                width: 400,
                position: 'bottom',
                module: {
                    header: {
                        title: <Translate>Messages</Translate>,
                        subTitle: (
                            dynamicData.messages.count ? (
                                <Translate replaceMap={{ '_COUNT_': dynamicData.messages.count }}>You have _COUNT_ unread messages</Translate>
                            ) : (
                                hasConversation ? (
                                    <Translate>You don't have any unread messages</Translate>
                                ) : (
                                    <Translate>You don't have any messages</Translate>
                                )
                            )
                        ),
                        ...conditionalSpread({
                            cta: {
                                to: '/messages',
                                tooltip: <Translate>View All Messages</Translate>,
                                icon: {
                                    type: 'icon-scale',
                                },
                            },
                        }, hasConversation),
                    },
                },
                children: (
                    <MiniMessages setHasConversation={setHasConversation} />
                ),
            },
        ], features.messages),
        {
            icon: 'icon-a-bell',
            width: 400,
            maxItems: 5,
            badge: unitedNotificationsCount,
            onOpen: () => setDynamicDataTrigger(val => val + 1),
            scrollerRef: val => miniNotificationsScrollerRef.current = val,
            module: {
                header: {
                    title: <Translate>Notifications</Translate>,
                    subTitle: (
                        unitedNotificationsCount ? (
                            <Translate replaceMap={{ '_COUNT_': unitedNotificationsCount }}>You have _COUNT_ unread notifications</Translate>
                        ) : (
                            <Translate>You don't have any unread notifications</Translate>
                        )
                    ),
                    ...conditionalSpread({
                        cta: {
                            loading: readAllNotificationsLoading,
                            onClick: () => handleReadAllNotifications(),
                            tooltip: <Translate replaceMap={{ '_COUNT_': dynamicData?.[notificationsSelectedTab]?.count }}>Mark _COUNT_ as read</Translate>,
                            icon: {
                                type: 'icon-a-checkbox-square',
                            },
                        },
                    }, dynamicData?.[notificationsSelectedTab]?.count),
                    ...conditionalSpread({
                        children: (
                            <Notifications
                                body={false}
                                {...notificationsSharedProps}
                            />
                        ),
                    }, userRole === userRoles.partner),
                },
            },
            children: (
                <Notifications
                    head={false}
                    {...notificationsSharedProps}
                />
            ),
        },
    ];

    return mobileContent.map((item, index) => (
        <Popover
            key={index}
            {...popoverProps}
            width={item.width}
            onOpen={item.onOpen}
            content={item.children}
            maxItems={item.maxItems}
            module={item?.module}
            {...conditionalSpread({
                itemHeight: item.itemHeight,
            }, !!item.itemHeight)}
            {...conditionalSpread({
                scrollerRef: item.scrollerRef,
            }, !!item.scrollerRef)}
        >
            <Button
                badge={{
                    badge: item.badge,
                    ...conditionalSpread({
                        size: 'small',
                        transform: false,
                    }, isMobile),
                }}
                hero='white'
                {...conditionalSpread(popoverButtonProps, !isMobile)}
                icon={{
                    type: item.icon,
                }}
            />
        </Popover>
    ));
};

export const headerUserProfile = props => {
    const {
        user,
        setUser,
        userRole,
        isMobile,
        navigate,
        workspaceMode,
        locationConfig,
        firstTimePartner,
        setWorkspaceMode,
    } = props;

    const userRoutesMenu = ([
        ...userRoutesData.map(route => !user?.verified && route.url === userRoutes?.personalInformation ? ({
            ...route,
            badge: true,
        }) : route),
        { divider: true },
        {
            icon: 'icon-a-nut',
            name: <Translate>Application Settings</Translate>,
            children: locationConfig,
        },
        { divider: true },
        ...getRoutesBySlug(['logOut']),
    ]);

    const handleWorkspaceChange = () => {
        setWorkspaceMode(val => !val);
        firstTimePartner && connectionService.putJson(URLS[PROFILE], {
            onboarding: {
                firstTimePartner: true,
            },
        }).subscribe(res => setUser(val => ({
            ...val,
            onboarding: res?.data?.data?.onboarding || val?.onboarding || {},
        })));
        navigate(workspaceMode ? '/' : getRoutesBySlug(['wpDashboard'])?.[0]?.url, { replace: true });
    };

    return (
        <Popover
            {...popoverProps}
            maxItems={10}
            menu={[
                ...conditionalSpread([
                    {
                        badge: firstTimePartner,
                        avatar: (
                            <Avatar
                                size='small'
                                fallbackIcon={workspaceMode ? 'icon-a-user' : 'icon-a-users'}
                                url={workspaceMode ? user?.avatarURL : user?.workspace?.avatarURL}
                            />
                        ),
                        onClick: handleWorkspaceChange,
                        name: (
                            <Translate replaceMap={{ '_NAME_': workspaceMode ? (user?.givenName || user?.name || user?.email) : user?.workspace?.name }}>Switch to _NAME_</Translate>
                        ),
                    },
                    { divider: true },
                ], userRole === userRoles.partner),
                ...userRoutesMenu,
            ]}
            offsetBounding={[0, 20]}
        >
            <Avatar
                {...conditionalSpread({
                    onDoubleClick: handleWorkspaceChange,
                }, userRole === userRoles.partner)}
                size={isMobile ? 'medium' : 'big'}
                {...conditionalSpread({
                    badge: !user?.verified || firstTimePartner,
                }, !!Object.keys(user || {}).length)}
                fallbackIcon={workspaceMode ? 'icon-a-users' : 'icon-a-user'}
                url={workspaceMode ? user?.workspace?.avatarURL : user?.avatarURL}
            />
        </Popover>
    );
};

export const headerLocationButton = props => {
    const {
        isMobile,
        locationConfig,
    } = props;

    return (
        <Popover
            {...popoverProps}
            maxItems={10}
            offsetBounding={[0, 20]}
            position='bottom-end'
            menu={locationConfig}
        >
            <Button
                {...popoverButtonProps}
                cornerRadius='full'
                color='color'
                size='big'
                ariaLabel={translate('Application Settings')}
                hero={isMobile ? 'b-sc' : 'brand'}
                icon={{
                    size: isMobile ? 22 : 24,
                    type: 'icon-a-nut',
                }}
            />
        </Popover>
    );
};
