import PropTypes from 'prop-types';

import { knobAbleProps, popperConfig } from '../../props';

import { inputConfig } from '../input/props';

import { datePickerConfig } from '../datePicker/props';

export const datePickerInputConfig = {
    appearance: knobAbleProps,
};

export const datePickerInputProps = {
    end: PropTypes.any,
    type: PropTypes.oneOf(datePickerConfig.type),
    size: PropTypes.oneOf(inputConfig.size),
    start: PropTypes.any,
    error: PropTypes.any,
    label: PropTypes.any,
    minDate: PropTypes.any,
    maxDate: PropTypes.any,
    disabled: PropTypes.bool,
    position: PropTypes.oneOf(popperConfig.position),
    required: PropTypes.bool,
    onChange: PropTypes.func,
    appearance: PropTypes.oneOf(datePickerInputConfig.appearance),
    placeholder: PropTypes.any,
    cornerRadius: PropTypes.oneOf(inputConfig.cornerRadius),
    retainStartDateOnSelection: PropTypes.bool,
};
