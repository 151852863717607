import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread, isFunction } from 'clyne-core';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Tag from '../tag';
import Icon from '../icon';
import Price from '../price';
import Rating from '../rating';
import Tooltip from '../tooltip';
import Metrics from '../metrics';
import Checkbox from '../checkbox';
import Favorite from '../favorite';
import LazyImage from '../lazyImage';
import Translate from '../translate';
import CountInput from '../countInput';
import LinkedScreen from '../linkedScreen';
import LockedScreenInfo from '../lockedScreenInfo';

import { genericState, linkedScreenModalState, selectedVirtualSlotsState, userState } from '../../state';

import { campaignPeriods, priceCalculationTypes } from '../../constants/construct';

import translate from '../../utils/translate';

import { getScreenType, idFormatter } from '../../helpers';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const iconsSharedProps = {
    size: 18,
};

const Screen = props => {
    const {
        id,
        to,
        name,
        views,
        count,
        price,
        image,
        rating,
        locked,
        linked,
        typeID,
        mapRef,
        hovered,
        selected,
        onRemove,
        blurHash,
        periodId,
        onSelect,
        skeleton,
        available,
        comingSoon,
        placementID,
        description,
        workspaceId,
        locateOnMap,
        favoriteAble,
        slotDuration,
        loopDuration,
        physicalWidth,
        physicalHeight,
        setFocusedItem,
        maxVirtualSlots,
        minVirtualSlots,
        calculationType,
        target = '_self',
        setScrollToOnById,
        appearance = 'featured',
        canReduceVirtualSlot = true,
    } = props;

    const user = useRecoilValue(userState);
    const generic = useRecoilValue(genericState);
    const setLinkedScreenModal = useSetRecoilState(linkedScreenModalState);
    const [selectedVirtualSlots, setSelectedVirtualSlots] = useRecoilState(selectedVirtualSlotsState);

    const hasAvailable = props.hasOwnProperty('available');
    const [slotLoopReplaceMap, setSlotLoopReplaceMap] = useState({});

    const {
        isMobile,
    } = useDevice();

    const countInputRenderer = (selected && maxVirtualSlots > 1) && (
        <CountInput
            value={selectedVirtualSlots[id] || 1}
            onChange={value => setSelectedVirtualSlots(val => {
                if (linked?.length) {
                    return linked.reduce((prev, current) => ({
                        ...val,
                        ...prev,
                        [current]: value,
                    }), {});
                } else {
                    return ({
                        ...val,
                        [id]: value,
                    });
                }
            })}
            canReduce={canReduceVirtualSlot}
            canAdd={hasAvailable ? !!available : true}
            title={translate('Repetition in 1 loop')}
            min={minVirtualSlots < 1 ? 1 : (minVirtualSlots || 1)}
            max={maxVirtualSlots < 1 ? 1 : (maxVirtualSlots || 1)}
        />
    );

    const imageRenderer = () => (
        <figure>
            <div
                className={classNames(
                    'figure-image',
                    'absolutely-splash',
                    {
                        'skeleton-blink': !blurHash && skeleton,
                    }
                )}
            >
                <LazyImage
                    key={id}
                    alt={name}
                    blurHash={blurHash}
                    blurCut={appearance === 'stack'}
                    {...(typeof image === 'string' ? ({
                        source: image,
                    }) : image)}
                />
            </div>
            {!skeleton && (
                <>
                    {(!!rating && appearance === 'featured') && (
                        <div className='screen-rating-holder'>
                            <Rating rate={rating} />
                        </div>
                    )}
                    {appearance === 'stack' && (
                        <div className='sc-stack-sp-holder'>
                            {screenProperties}
                        </div>
                    )}
                    <NavLink
                        to={to}
                        target={target}
                        aria-label={name}
                        className='absolutely-splash'
                    />
                    {hasAvailable && (
                        <Tooltip
                            content={available ? (
                                <Translate>Matches for your dates</Translate>
                            ) : (
                                <Translate>No slots available for this screen</Translate>
                            )}
                        >
                            <div
                                className={classNames(
                                    `screen-status-holder`,
                                    {
                                        's-available': available,
                                    }
                                )}
                            >
                                <Icon
                                    size={20}
                                    type={available ? 'icon-a-check' : 'icon-a-cross'}
                                />
                            </div>
                        </Tooltip>
                    )}
                    {favoriteAble && (
                        <div className='screen-favorite-holder'>
                            <Favorite id={id} />
                        </div>
                    )}
                </>
            )}
        </figure>
    );

    const tagRenderer = (
        <Tag
            {...conditionalSpread({
                url: to,
            }, isMobile ? !linked?.length : true)}
            {...conditionalSpread({
                onClick: () => setLinkedScreenModal({
                    id,
                    linked,
                    source: 'tag',
                    title: [idFormatter(id), name].filter(Boolean).join(' - '),
                }),
            }, isMobile ? !!linked?.length : false)}
            size='default'
            target={target}
            name={idFormatter(id)}
            {...conditionalSpread({
                icon: {
                    size: 16,
                    type: 'icon-linked',
                },
                ...conditionalSpread({
                    tooltip: (
                        <LinkedScreen
                            linked={linked}
                        />
                    ),
                }, !isMobile),
            }, linked?.length)}
        />
    );

    const titleRenderer = () => (
        <h3 title={name}>
            {skeleton ? (
                <div className='skeleton-wave' />
            ) : (
                <NavLink
                    to={to}
                    target={target}
                    className='text-ellipsis'
                >
                    {name}
                </NavLink>
            )}
        </h3>
    );

    const hasDescription = props.hasOwnProperty('description');

    useEffect(() => {
        appearance !== 'featured' && import('../../utils/humanizeDurationWithTranslation').then(({ default: humanizeDurationWithTranslation }) => setSlotLoopReplaceMap({
            '_SLOT_D_': humanizeDurationWithTranslation(slotDuration * 1000),
            '_LOOP_D_': humanizeDurationWithTranslation(loopDuration * 1000),
        }));
    }, []); // eslint-disable-line

    const getPriceTextByPeriod = periodId => {
        switch (periodId) {
            case campaignPeriods.daily:
                return (
                    <Translate>Daily</Translate>
                );
            case campaignPeriods.weekly:
                return (
                    <Translate>Weekly</Translate>
                );
            case campaignPeriods.monthly:
                return (
                    <Translate>Monthly</Translate>
                );
            default:
                return (
                    <Translate>Monthly</Translate>
                );
        }
    };

    const screenPriceAndRating = (
        <ul className='sed-rating-price-holder'>
            {((appearance === 'compact' || appearance === 'expanded' || appearance === 'stack') && (isFunction(setScrollToOnById, false) || isFunction(locateOnMap, false))) && (
                <>
                    <li
                        className={classNames(
                            {
                                'disabled': !!comingSoon,
                            }
                        )}
                    >
                        <Tooltip
                            key={mapRef?.current?.getCenter()}
                            content={appearance === 'compact' ? (
                                <Translate>Locate on List</Translate>
                            ) : (
                                <Translate>Locate on Map</Translate>
                            )}
                        >
                            <button
                                onClick={() => appearance === 'compact' ? setScrollToOnById(id) : locateOnMap()}
                            >
                                <Icon
                                    size={18}
                                    type={appearance === 'compact' ? 'icon-slider-vertical-fill' : 'icon-compass-fill'}
                                />
                            </button>
                        </Tooltip>
                    </li>
                    <li className='h-divider' />
                </>
            )}
            <li>
                <Rating rate={rating} />
                <p>
                    <strong>
                        <Price number={price} />
                    </strong>
                    {(appearance === 'expanded' || appearance === 'stack' || appearance === 'compressed') && (
                        <>
                            {` / `}
                            <span>
                                {calculationType === priceCalculationTypes.fixed ? getPriceTextByPeriod(periodId) : appearance === 'compressed' ? (
                                    <Translate>Price Per Slot</Translate>
                                ) : (
                                    <Translate>monthly</Translate>
                                )}
                            </span>
                        </>
                    )}
                </p>
            </li>
        </ul>
    );

    const checkboxRenderer = (
        <Checkbox
            key={id}
            locked={locked}
            linked={!!linked}
            checked={selected}
            onChange={e => {
                if (linked?.length && e.target.checked && !user?.onboarding?.linkedScreenNoticeDismissed) {
                    setLinkedScreenModal({
                        id,
                        linked,
                        source: 'checkbox',
                        title: [idFormatter(id), name].filter(Boolean).join(' - '),
                    });
                } else {
                    !!onSelect && onSelect(e);
                }
            }}
            {...conditionalSpread({
                tooltip: <LockedScreenInfo workspaceId={workspaceId} />,
            }, locked)}
        />
    );

    const screenProperties = (
        <ul className='sed-properties-holder'>
            {(physicalWidth && physicalHeight) && (
                <li>
                    <Icon
                        type='icon-a-maximize'
                        {...iconsSharedProps}
                    />
                    <p className='text-ellipsis'>
                        <Metrics
                            physicalWidth={physicalWidth}
                            physicalHeight={physicalHeight}
                        />
                    </p>
                </li>
            )}
            <li>
                <Icon
                    type='icon-a-eye'
                    {...iconsSharedProps}
                />
                <p className='text-ellipsis'>
                    <Translate replaceMap={{ '_VIEWS_': views }}>_VIEWS_ avg. views daily</Translate>
                </p>
            </li>
            {!!typeID && (
                <li>
                    <Icon
                        type={getScreenType(typeID, generic)?.icon}
                        {...iconsSharedProps}
                    />
                    <p className='text-ellipsis'>
                        <Metrics typeID={typeID} />
                    </p>
                </li>
            )}
            {!!placementID && (
                <li>
                    <Icon
                        type='icon-a-pin-light'
                        {...iconsSharedProps}
                    />
                    <p className='text-ellipsis'>
                        <Metrics placementID={placementID} />
                    </p>
                </li>
            )}
            {!!(slotDuration && loopDuration) && (
                <li className='span-2'>
                    <Icon
                        type='icon-a-repeat-on'
                        {...iconsSharedProps}
                    />
                    <p className='text-ellipsis'>
                        <Translate replaceMap={slotLoopReplaceMap}>_SLOT_D_ slot every _LOOP_D_</Translate>
                    </p>
                </li>
            )}
        </ul>
    );

    return (
        <>
            <div
                className={classNames(
                    `screen-container-holder`,
                    `a-${appearance}`,
                    {
                        hovered,
                        skeleton,
                        'pointer-events-none': skeleton,
                    }
                )}
                onMouseEnter={() => appearance === 'expanded' && isFunction(setFocusedItem, false) && setFocusedItem({
                    id,
                    source: 'item',
                })}
                onMouseLeave={() => appearance === 'expanded' && isFunction(setFocusedItem, false) && setFocusedItem({})}
            >
                {appearance === 'featured' && (
                    <>
                        {imageRenderer()}
                        {titleRenderer()}
                        {!!hasDescription && (
                            <div className='screen-description'>
                                {skeleton ? (
                                    <div className='skeleton-wave medium' />
                                ) : (
                                    description
                                )}
                            </div>
                        )}
                        <div className='divider' />
                        <div className='screen-additional-information'>
                            {skeleton ? (
                                <div className='skeleton-wave small' />
                            ) : count && (
                                <p>
                                    <Translate replaceMap={{ '_COUNT_': count }}>_COUNT_ Screens</Translate>
                                </p>
                            )}
                            {skeleton ? (
                                <div className='skeleton-wave wide' />
                            ) : (
                                <p>
                                    <strong>
                                        <Price number={price} />
                                    </strong>
                                    <span>
                                    {` `}
                                        <Translate>average</Translate>
                                </span>
                                </p>
                            )}
                        </div>
                    </>
                )}
                {(appearance === 'expanded' || appearance === 'compact' || appearance === 'zip' || appearance === 'compressed') && (
                    <>
                        {imageRenderer()}
                        <div className='screen-expanded-details'>
                            <div className='sed-tag-name-check-holder'>
                                {(appearance === 'expanded' || appearance === 'compressed') && tagRenderer}
                                {titleRenderer()}
                                {(appearance === 'expanded' || appearance === 'compressed' || onRemove) && (
                                    <>
                                        <div className='sed-action-holder'>
                                            {countInputRenderer}
                                            {appearance === 'expanded' && (comingSoon ? (
                                                <p className='sed-coming-soon-badge'>
                                                    <Translate>Coming soon</Translate>
                                                </p>
                                            ) : checkboxRenderer)}
                                            {!!onRemove && (
                                                <Tooltip
                                                    {...conditionalSpread({
                                                        content: <LockedScreenInfo workspaceId={workspaceId} />,
                                                    }, locked)}
                                                >
                                                    <Icon
                                                        onClick={onRemove}
                                                        type={locked ? 'icon-a-lock' : 'icon-a-trash'}
                                                        size={20}
                                                    />
                                                </Tooltip>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            {(appearance === 'compact' || appearance === 'zip') ? (
                                <div className='v-divider' />
                            ) : screenProperties}
                            {!!(rating !== '' && price !== '') && screenPriceAndRating}
                        </div>
                    </>
                )}
                {appearance === 'stack' && (
                    <>
                        <div className='sc-stack-image'>
                            {imageRenderer()}
                            {(!!onSelect || !!onRemove) && (
                                <label
                                    className='sc-stack-s-action'
                                    {...conditionalSpread({
                                        onClick: onRemove,
                                    }, typeof onRemove === 'function' && !comingSoon)}
                                >
                                    {!!onSelect && comingSoon ? (
                                        <p className='sed-coming-soon-badge t-mobile'>
                                            <Translate>Coming soon</Translate>
                                        </p>
                                    ) : (
                                        <div>
                                            {checkboxRenderer}
                                        </div>
                                    )}
                                    {!!onRemove && (
                                        <Tooltip
                                            {...conditionalSpread({
                                                content: <LockedScreenInfo workspaceId={workspaceId} />,
                                            }, locked)}
                                        >
                                            <div onClick={onRemove}>
                                                <Icon
                                                    onClick={onRemove}
                                                    size={locked ? 20 : 18}
                                                    type={locked ? 'icon-a-lock' : 'icon-a-trash'}
                                                />
                                            </div>
                                        </Tooltip>
                                    )}
                                </label>
                            )}
                        </div>
                        <ul className='sc-stack-title'>
                            <li>
                                {tagRenderer}
                            </li>
                            <li>
                                {titleRenderer()}
                            </li>
                            {!!countInputRenderer && (
                                <li>
                                    {countInputRenderer}
                                </li>
                            )}
                        </ul>
                        <div className='v-divider' />
                        {screenPriceAndRating}
                    </>
                )}
            </div>
        </>
    );
};

Screen.propTypes = {
    id: PropTypes.number,
    to: PropTypes.string,
    name: PropTypes.any,
    views: PropTypes.number,
    count: PropTypes.number,
    price: PropTypes.number,
    image: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        alt: PropTypes.string,
        style: PropTypes.object,
        width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        source: PropTypes.string,
        source2x: PropTypes.string,
        sourceWEBP: PropTypes.string,
        source2xWEBP: PropTypes.string,
    })]),
    rating: PropTypes.number,
    linked: PropTypes.arrayOf(PropTypes.number),
    mapRef: PropTypes.object,
    target: PropTypes.oneOf([
        '_self',
        '_blank',
    ]),
    locked: PropTypes.bool,
    typeID: PropTypes.number,
    hovered: PropTypes.bool,
    selected: PropTypes.bool,
    onSelect: PropTypes.func,
    skeleton: PropTypes.bool,
    onRemove: PropTypes.func,
    blurHash: PropTypes.string,
    periodId: PropTypes.number,
    available: PropTypes.bool,
    comingSoon: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'featured',
        'expanded',
        'compressed',
        'detailed',
        'compact',
        'zip',
        'stack'
    ]),
    description: PropTypes.any,
    workspaceId: PropTypes.string,
    locateOnMap: PropTypes.func,
    placementID: PropTypes.number,
    favoriteAble: PropTypes.bool,
    slotDuration: PropTypes.number,
    loopDuration: PropTypes.number,
    setFocusedItem: PropTypes.func,
    calculationType: PropTypes.number,
    minVirtualSlots: PropTypes.number,
    maxVirtualSlots: PropTypes.number,
    setScrollToOnById: PropTypes.func,
    canReduceVirtualSlot: PropTypes.bool,
};

export default Screen;
