import React, { useEffect, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import clasNames from 'classnames';
import PropTypes from 'prop-types';

import Row from './kit/row';
import Cell from './kit/cell';
import NoData from '../noData';
import Translate from '../translate';

import useDevice from '../../hooks/useDevice';
import useElementResize from '../../hooks/useElementResize';

import './index.scss';

const Table = props => {
    const {
        data,
        keys,
        totals,
        subItem,
        cardProps,
        autoWidth,
    } = props;

    const { isMobile } = useDevice();
    const ref = useRef(null);
    const { width } = useElementResize(ref);
    const [inViewRef, inView] = useInView({
        threshold: 0,
        triggerOnce: false,
    });
    const [openedItems, setOpenedItems] = useState([]);

    useEffect(() => {
        setOpenedItems([]);
        if (data?.length && typeof subItem === 'function') {
            data.forEach((_, index) => {
                setOpenedItems(val => ([...val, { opened: false, index, }]));
            });
        }
    }, [data]); // eslint-disable-line

    const actions = item => ([
        ...(typeof props.actions === 'function' ? props.actions(item) : []),
        ...(typeof subItem === 'function' && !isMobile ? ([{
            icon: {
                type: openedItems?.[item?.rowIndex]?.opened ? 'icon-a-minus' : 'icon-a-plus',
                size: 22,
            },
            onClick: () => setOpenedItems(val => val.map(i => i.index === item.rowIndex ? ({ ...i, opened: !i.opened }) : i)),
            name: openedItems?.[item?.rowIndex]?.opened ? (
                <Translate>Close</Translate>
            ) : (
                <Translate>Open</Translate>
            ),
        }]) : [])
    ]);

    const colSpan = keys.length + (typeof actions === 'function' ? actions({}).length : 0);

    const rowSharedProps = row => ({
        row,
        cardProps,
        skeleton: row?.skeleton,
        actions: typeof actions === 'function' && actions(row),
        colSpanTotal: colSpan,
        type: isMobile ? 'card' : 'td',
    });

    const cellSharedProps = {
        autoWidth,
        type: isMobile ? 'card' : 'td',
    };

    const noData = (
        <NoData
            size='small'
            subTitle={
                <Translate>No Data to Display</Translate>
            }
        />
    );

    const content = (
        <>
            {data.map((row, rowIndex) => (
                <Row
                    key={rowIndex}
                    collapsed={!openedItems?.[rowIndex]?.opened}
                    subItem={typeof subItem === 'function' && subItem(row)}
                    {...rowSharedProps({ ...row, rowIndex, })}
                >
                    {keys.map((key, cellIndex) => (
                        <Cell
                            row={row}
                            cell={{
                                ...key,
                                key,
                                content: row[key.name],
                            }}
                            key={cellIndex}
                            rowIndex={rowIndex}
                            cellIndex={cellIndex}
                            {...cellSharedProps}
                        />
                    ))}
                </Row>
            ))}
            {(!!keys.some(obj => obj.totalKey) && !!totals?.length) && (
                <Row
                    total
                    {...rowSharedProps()}
                >
                    {keys.map((key, cellIndex) => (
                        <Cell
                            total
                            row={totals}
                            cell={{
                                ...key,
                                content: totals.find(obj => Object.keys(obj)?.[0] === key?.totalKey)?.[key?.totalKey],
                            }}
                            key={cellIndex}
                            cellIndex={cellIndex}
                            {...cellSharedProps}
                        />
                    ))}
                </Row>
            )}
        </>
    );

    return isMobile ? (
        <div className='mobile-card-table-holder'>
            {data?.length ? content : noData}
        </div>
    ) : (
        <>
            <div
                className={clasNames(
                    'table-c-holder',
                    {
                        'sticky-head': !inView,
                    }
                )}
            >
                <div className='table-head-sticky-intersect'>
                    <div
                        ref={inViewRef}
                        className='table-head-intersect'
                    />
                </div>
                <table
                    ref={ref}
                    className='table-c'
                    style={{
                        '--table-width': `${width}px`,
                    }}
                >
                    <thead>
                    <Row
                        {...rowSharedProps()}
                        type='th'
                    >
                        {keys.map((item, index) => (
                            <Cell
                                key={index}
                                cell={item}
                                {...cellSharedProps}
                                type='th'
                            />
                        ))}
                    </Row>
                    </thead>
                    <tbody>
                    {data?.length ? content : (
                        <Row>
                            <Cell colSpan={colSpan}>
                                <div className='table-no-data-holder'>
                                    {noData}
                                </div>
                            </Cell>
                        </Row>
                    )}
                    </tbody>
                </table>
            </div>
        </>
    );
};

Table.propTypes = {
    data: PropTypes.array,
    keys: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.any,
        label: PropTypes.any,
        renderer: PropTypes.func,
        style: PropTypes.object,
        totalKey: PropTypes.string,
        superAdminOnly: PropTypes.bool,
    })),
    totals: PropTypes.array,
    subItem: PropTypes.func,
    actions: PropTypes.func,
    cardProps: PropTypes.shape({
        shadow: PropTypes.bool,
        border: PropTypes.bool,
    }),
    autoWidth: PropTypes.bool,
};

export default Table;
