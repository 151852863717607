import { Calendar, DateRangePicker } from 'react-awesome-date-range';
import React from 'react';

import useDevice from '../../hooks/useDevice';

import { datePickerProps, datePickerConfig } from './props';

import './index.scss';

const DatePicker = props => {
    const {
        date,
        ranges,
        minDate,
        maxDate,
        onChange,
        type = 'datePicker',
        retainStartDateOnSelection,
    } = props;

    const { isMobile } = useDevice();

    return type === datePickerConfig.type[1] ? (
        <DateRangePicker
            months={isMobile ? 1 : 2}
            ranges={ranges}
            minDate={minDate}
            onChange={onChange}
            direction='horizontal'
            showMonthAndYearPickers={false}
            weekStartsOn={1}
            retainStartDateOnSelection={retainStartDateOnSelection}
            rangeColors={['var(--brand)', 'var(--brand)', 'var(--hero)']}
        />
    ) : (
        <Calendar
            date={date}
            onChange={onChange}
            color='var(--brand)'
            minDate={minDate}
            weekStartsOn={1}
            maxDate={maxDate}
            showMonthAndYearPickers={true}
        />
    );
};

DatePicker.propTypes = datePickerProps;

export default DatePicker;
