import { toProps } from 'clyne-core';
import PropTypes from 'prop-types';

import { tagConfig } from '../tag/props';

export const mergedTagValueProps = {
    url: PropTypes.string,
    href: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.oneOf(tagConfig.color),
    target: PropTypes.oneOf(toProps.target),
    children: PropTypes.any,
};
