import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../../../icon';
import Tooltip from '../../../tooltip';
import Translate from '../../../translate';

import useCopy from '../../../../hooks/useCopy';

import './index.scss';

const InlineCellAction = props => {
    const {
        onCopy,
        children,
        onQuickFilter,
    } = props;

    const {
        icon,
        canCopy,
        className,
        handleCopy,
    } = useCopy();

    return canCopy(children) ? (
        <div className='inline-cell-action-holder'>
            <div className='text-ellipsis'>{children}</div>
            {!!onCopy && (
                <Tooltip
                    content={
                        <Translate>Copy</Translate>
                    }
                >
                    <Icon
                        size={18}
                        type={icon}
                        tabIndex={0}
                        className={className}
                        onClick={() => onCopy(handleCopy)}
                    />
                </Tooltip>
            )}
            {!!onQuickFilter && (
                <Tooltip
                    content={
                        <Translate>Set Filter</Translate>
                    }
                >
                    <Icon
                        size={18}
                        tabIndex={0}
                        type='icon-a-filter'
                        onClick={onQuickFilter}
                    />
                </Tooltip>
            )}
        </div>
    ) : children;
};

InlineCellAction.propTypes = {
    onCopy: PropTypes.func,
    children: PropTypes.any,
    onQuickFilter: PropTypes.func,
};

export default InlineCellAction;
