import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import moment from 'moment/moment';

import Icon from '../icon';
import Modal from '../modal';
import Switch from '../switch';
import Tooltip from '../tooltip';
import Popover from '../popover';
import CopyText from '../copyText';
import Collapse from '../collapse';
import VersionReport from '../versionReport';
import TextTransition from '../textTransition';
import ScreenConsoleItem from '../screenConsoleItem';
import ScreenOnlineTooltip from '../screenOnlineTooltip';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';

import { versionToNum, idFormatter } from '../../helpers';

import useDevice from '../../hooks/useDevice';

import { dynamicDataState, dynamicDataTriggerState, remoteActionState } from '../../state';

import { screenConsolePrompts, deviceActions, getDeviceActionSubIcon, getScreenConsoleUIData, getDeviceActionLeadingIcon } from './data';

import './index.scss';

const ScreenConsole = memo(props => {
    const {
        id,
        name,
        active,
        loading,
        command,
        onClose,
        setCache,
        deviceData,
        setCommand,
        onActionSelect,
        RunCommandComponent = Fragment,
    } = props;

    const title = [idFormatter(id), name].filter(Boolean).join(' - ');

    const initialLoading = !Object.keys(deviceData || {}).length;

    const timeoutRef = useRef(null);

    const dynamicData = useRecoilValue(dynamicDataState);
    const remoteAction = useRecoilValue(remoteActionState);
    const resetRemoteAction = useResetRecoilState(remoteActionState);
    const setDynamicDataTrigger = useSetRecoilState(dynamicDataTriggerState);

    const [lastUpdate, setLastUpdate] = useState(new Date().valueOf());
    const [loadingActions, setLoadingActions] = useState([]);
    const [currentPrompt, setCurrentPrompt] = useState(null);
    const [runCommandActive, setRunCommandActive] = useState(false);

    const {
        isMobile,
    } = useDevice();

    const online = dynamicData.deviceStatuses?.online?.some(item => item?.screenId === id);
    const aliveAt = dynamicData.deviceStatuses?.all?.find(item => item?.screenId === id)?.aliveAt;

    const reFetchData = () => {
        !!setCache && setCache(val => val + 1);
        setDynamicDataTrigger(val => val + 1);
    };

    const idd = deviceData?.lit;

    useEffect(() => {
        setDynamicDataTrigger(val => val + 1);
    }, [active]); // eslint-disable-line

    const handleReFetchData = () => {
        reFetchData();
        setLastUpdate(new Date().valueOf());
    };

    useEffect(() => {
        setLastUpdate(new Date().valueOf());
    }, [JSON.stringify(idd)]); // eslint-disable-line

    const muid = idd?.muid;
    const uid = idd?.uid;

    const litAppVersion = idd?.device?.litAppVersion;
    const litNativeVersion = idd?.device?.litNativeVersion;
    const bionicAppVersion = idd?.device?.bionicAppVersion;
    const bionicNativeVersion = idd?.device?.bionicNativeVersion;

    const hasLit = !!litNativeVersion;
    const hasLitNativeUpdate = !idd?.device?.simulator && (hasLit && versionToNum(litNativeVersion) < versionToNum(dynamicData?.availableVersions?.lit?.native));
    const hasLitAppUpdate = !idd?.device?.simulator && (hasLit && !hasLitNativeUpdate && versionToNum(litAppVersion) < versionToNum(dynamicData?.availableVersions?.lit?.app));

    const hasBionic = !!bionicNativeVersion;
    const hasBionicNativeUpdate = !idd?.device?.simulator && (hasBionic && versionToNum(bionicNativeVersion) < versionToNum(dynamicData?.availableVersions?.bionic?.native));
    const hasBionicAppUpdate = !idd?.device?.simulator && (hasBionic && !hasBionicNativeUpdate && versionToNum(bionicAppVersion) < versionToNum(dynamicData?.availableVersions?.bionic?.app));

    const hasHost = !!idd?.device?.hostVersion;
    const hasChrome = !!idd?.device?.chromeVersion;
    const hasBrowser = !!idd?.device?.browserVersion;

    const screenOn = idd?.screen?.state;
    const adbEnabled = idd?.device?.adbEnabled;
    const kioskModeEnabled = idd?.device?.kioskModeEnabled;
    const mediaDebugEnabled = idd?.device?.mediaDebugEnabled;
    const socketDebugEnabled = idd?.device?.socketDebugEnabled;
    const playlistDebugEnabled = idd?.device?.playlistDebugEnabled;
    const hasPlatformSettings = !!idd?.device?.platformSettingsVersion;

    const externalIp = idd?.network?.ip || '000.000.000.000';
    const internalIp = idd?.network?.ipV4 || '000.000.000.000';
    const connectionType = idd?.network?.type;

    const prompts = screenConsolePrompts({
        hasLitAppUpdate,
        hasLitNativeUpdate,
        hasBionicAppUpdate,
        hasBionicNativeUpdate,
    });

    const onAction = action => {
        clearTimeout(timeoutRef.current);
        setCurrentPrompt({
            type: 'loading',
            message: prompts[action].verb,
        });
        setLoadingActions(val => ([...new Set([...val, action])]));

        onActionSelect(action, {
            prompts,
            setRunCommandActive,
        }).then(() => {
            const message = prompts[action].success;
            const data = {
                type: 'success',
                message: typeof message === 'function' ? message(title) : message,
            };
            action === deviceActions.runCommand ? addToast(data) : setCurrentPrompt(data);
        }).catch(() => {
            const message = prompts[action].error;
            const data = {
                type: 'error',
                message: typeof message === 'function' ? message(title) : message,
            };
            action === deviceActions.runCommand ? addToast(data) : setCurrentPrompt(data);
        }).finally(() => {
            setCommand('');
            setRunCommandActive(false);
            setLoadingActions(val => val.filter(item => item !== action));
            timeoutRef.current = setTimeout(() => {
                setCurrentPrompt(null);
                handleReFetchData();
                clearTimeout(timeoutRef.current);
            }, 3000);
        });
    };

    useEffect(() => {
        const action = remoteAction?.action;

        if (Object.values(deviceActions).includes(action)) {
            onAction(action);
            if (remoteAction.command) {
                setCommand(remoteAction.command);
            }
            resetRemoteAction();
        } else {
            resetRemoteAction();
        }
    }, [remoteAction.action]); // eslint-disable-line

    const screenConsoleUIData = getScreenConsoleUIData({
        hasLit,
        online,
        hasHost,
        onAction,
        screenOn,
        hasBionic,
        hasChrome,
        adbEnabled,
        hasBrowser,
        externalIp,
        internalIp,
        loadingActions,
        connectionType,
        hasLitAppUpdate,
        kioskModeEnabled,
        mediaDebugEnabled,
        hasLitNativeUpdate,
        socketDebugEnabled,
        hasBionicAppUpdate,
        hasPlatformSettings,
        playlistDebugEnabled,
        hasBionicNativeUpdate,
    });

    return (
        <>
            <Modal
                title={title}
                active={active}
                onClose={() => {
                    onClose();
                    setCurrentPrompt(null);
                }}
                position='center'
                appearance='clean'
                secondaryButton={null}
                alignHeader='start'
                subTitle={(
                    <div className='screen-console-meta-info'>
                        {(currentPrompt || initialLoading) ? (
                            <Icon
                                size={20}
                                className='scmi-leading-icon'
                                type={(currentPrompt?.type === 'loading' || initialLoading) ? 'icon-a-loader' : currentPrompt?.type === 'error' ? 'icon-a-cross-circle-fill' : 'icon-a-check-circle-fill'}
                            />
                        ) : (
                            <Tooltip
                                className='content-screen-online'
                                content={(
                                    <ScreenOnlineTooltip
                                        id={id}
                                        uid={uid}
                                        online={online}
                                        aliveAt={aliveAt}
                                    />
                                )}
                            >
                                <div
                                    className={classNames(
                                        'screen-online-dot-holder',
                                        {
                                            'offline': !online,
                                        }
                                    )}
                                >
                                    <small />
                                </div>
                            </Tooltip>
                        )}
                        <TextTransition
                            flexibility='fit'
                            text={initialLoading ? translate('Loading') : currentPrompt?.message || `${translate('Updated -')} ${moment(lastUpdate).format('HH:mm:ss')}`}
                        />
                        <div className='scmi-sub-icon'>
                            <Icon
                                size={16}
                                onClick={() => handleReFetchData()}
                                type={loading ? 'icon-a-loader' : 'icon-a-rotate-cw'}
                            />
                        </div>
                    </div>
                )}
            >
                <div className='screen-console-card'>
                    <ul className='screen-console-holder'>
                        {screenConsoleUIData.map((section, index) => (
                            <Fragment key={section.label}>
                                {!!index && (
                                    <li className={isMobile ? 'v-divider' : 'h-divider'} />
                                )}
                                <li className='screen-console-section'>
                                    <h5>{section.label}</h5>
                                    <div className='screen-console-items-holder'>
                                        {section.children.map((child, index) => (
                                            <Fragment key={`${child.label}${index}`}>
                                                {child.onChange ? (
                                                    <Switch
                                                        size='medium'
                                                        key={lastUpdate}
                                                        label={child.label}
                                                        checked={child.checked}
                                                        onChange={child.onChange}
                                                    />
                                                ) : child.menu ? (
                                                    <Popover
                                                        menu={child.menu}
                                                    >
                                                        <ScreenConsoleItem
                                                            badge={child.badge}
                                                            label={child.label}
                                                            subIcon={getDeviceActionSubIcon(child, loadingActions)}
                                                            leadingIcon={getDeviceActionLeadingIcon(child, loadingActions)}
                                                        />
                                                    </Popover>
                                                ) : (
                                                    <ScreenConsoleItem
                                                        badge={child.badge}
                                                        label={child.type === 'time' ? (
                                                            <Moment
                                                                interval={1000}
                                                                date={moment(idd?.time?.user?.current)}
                                                                format='D MMM YYYY, HH:mm:ss'
                                                                className={classNames(
                                                                    {
                                                                        'color-error': new Date().getFullYear() < 2023,
                                                                    },
                                                                )}
                                                            />
                                                        ) : child.label}
                                                        copyValue={child.copyText}
                                                        subIcon={getDeviceActionSubIcon(child, loadingActions)}
                                                        leadingIcon={getDeviceActionLeadingIcon(child, loadingActions)}
                                                        onClick={() => child.action === deviceActions.runCommand ? setRunCommandActive(true) : onAction(child.action)}
                                                    />
                                                )}
                                            </Fragment>
                                        ))}
                                    </div>
                                </li>
                            </Fragment>
                        ))}
                    </ul>
                    <Collapse open={!!muid || !!uid || !!bionicNativeVersion || (litAppVersion || litNativeVersion)}>
                        <div className='screen-console-card'>
                            <div className='v-divider' />
                            <ul className='sc-meta-info-holder'>
                                <li>
                                    {!!muid && (
                                        <p>
                                            <strong>MUID</strong>
                                            <CopyText
                                                absolute
                                                text={muid}
                                            >
                                                <span className='text-ellipsis'>{muid}</span>
                                            </CopyText>
                                        </p>
                                    )}
                                    {!!uid && (
                                        <p>
                                            <strong>UID</strong>
                                            <CopyText
                                                absolute={!isMobile}
                                                text={uid}
                                            >
                                                <span className='text-ellipsis'>{uid}</span>
                                            </CopyText>
                                        </p>
                                    )}
                                </li>
                                <li>
                                    <VersionReport
                                        product='bionic'
                                        app={bionicAppVersion}
                                        native={bionicNativeVersion}
                                        simulator={!!idd?.device?.simulator}
                                    />
                                    <VersionReport
                                        product='lit'
                                        app={litAppVersion}
                                        native={litNativeVersion}
                                        simulator={!!idd?.device?.simulator}
                                    />
                                </li>
                            </ul>
                        </div>
                    </Collapse>
                </div>
            </Modal>
            <Modal
                title={title}
                topDivider={false}
                active={runCommandActive}
                onClose={() => {
                    setRunCommandActive(false);
                    setCurrentPrompt(null);
                    setCommand('');
                }}
                message={{
                    type: 'runCommand',
                    title: translate('Run Command'),
                    children: translate('Enter shell code that you want to execute'),
                    node: (
                        <RunCommandComponent
                            setCommand={setCommand}
                        />
                    ),
                }}
                primaryButton={{
                    disabled: !command?.length,
                    loading: loadingActions.includes(deviceActions.runCommand),
                    children: translate('Run'),
                    onClick: () => !!command?.length && onAction(deviceActions.runCommand),
                }}
            />
        </>
    );
});

ScreenConsole.propTypes = {
    id: PropTypes.number,
    name: PropTypes.string,
    active: PropTypes.bool,
    command: PropTypes.string,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    setCache: PropTypes.func,
    deviceData: PropTypes.object,
    setCommand: PropTypes.func,
    onActionSelect: PropTypes.func,
    appInstallVersion: PropTypes.string,
    RunCommandComponent: PropTypes.any,
    nativeInstallVersion: PropTypes.string,
};

export default ScreenConsole;
