import { conditionalSpread } from 'clyne-core';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import whiteLabel from '../../constants/whiteLabel';

import getTinyColor from '../../utils/getTinyColor';

import { subDomain } from '../../helpers';

import bbMediaLogo from './media/bbmedia.svg';
import arbaMediaLogo from './media/arbamedia.svg';

const logoMapping = {
    bbmedia: bbMediaLogo,
    arbamedia: arbaMediaLogo,
};

const WhiteLabelHydrate = props => {
    const {
        children,
    } = props;

    const [ready, setReady] = useState(false);

    useEffect(() => {
        const logo = logoMapping[subDomain];

        if (logo) {
            const queries = [
                'link[href*="safari-pinned-tab.svg"]',
                'link[href*="favicon.ico"]',
                'link[href*="apple-touch-icon.png"]',
                'link[href*="favicon-32x32.png"]',
                'link[href*="favicon-16x16.png"]',
            ];

            queries.forEach(query => {
                const element = document.querySelector(query);

                if (element) {
                    element.hasAttribute('color') && whiteLabel?.colors?.hero && element.setAttribute('color', whiteLabel?.colors?.hero);

                    element.setAttribute('href', logo);
                }
            });
        }

        if (whiteLabel?.colors) {
            document.documentElement.classList.add('white-label');

            const modifiedColors = Object.keys(whiteLabel.colors).reduce((prev, current) => ({
                ...prev,
                [current]: Array.isArray(whiteLabel.colors[current]) ? whiteLabel.colors[current]?.[0] : whiteLabel.colors[current],
                ...conditionalSpread({
                    [`${current}-f`]: whiteLabel.colors[current]?.[1],
                }, Array.isArray(whiteLabel.colors[current])),
            }), {});

            const colors = {
                ...modifiedColors,
                ...conditionalSpread({
                    'brand': modifiedColors['hero'],
                    'brand-f': modifiedColors['hero-f'] || modifiedColors['hero'],
                }, !modifiedColors['brand']),
                ...conditionalSpread({
                    'brand-accent': modifiedColors['brand'] || modifiedColors['hero'],
                }, !modifiedColors['brand-accent']),
                ...conditionalSpread({
                    'brand-accent-f': modifiedColors['brand-f'] || modifiedColors['hero-f'] || modifiedColors['hero'],
                }, !modifiedColors['brand-accent-f']),
            };

            Promise.all(Object.keys(colors).map(key => getTinyColor({
                dark: {
                    amount: 20,
                    value: colors.brand,
                },
                over: key,
                color: colors[key],
                light: '#ffffff',
            }))).then(colors => colors.forEach((colorsToLoop, index) => Object.keys(colorsToLoop).forEach((key, i) => {
                document.documentElement.style.setProperty(key, colorsToLoop[key]);
                if (Object.keys(colorsToLoop).length - 1 === i && colors.length - 1 === index) {
                    setReady(true);
                }
            })));

            import('tinycolor2').then(({ default: tinyColor }) => {
                const svgMap = [
                    ['#eff1f4', '#0e1c2e'],
                    ['#f2f7ff', '#0d1723'],
                    ['#edf5ff', '#222530'],
                    ['#e7edf7', '#0f1f35'],
                    ['#E7F1FF', '#0d1e32'],
                    ['#deebff', '#0e233b'],
                    ['#d6dbee', '#1d202a'],
                    ['#bdd5ff', '#113865'],
                    ['#8abaff', '#1255a0'],
                ];

                svgMap.forEach((svgColors, index) => {
                    const [light, dark] = svgColors;

                    document.documentElement.style.setProperty(`--svg-shade-light-${index + 1}`, tinyColor.mix(tinyColor({
                        ...tinyColor(light).toHsl(),
                        h: tinyColor(colors.hero).toHsl().h,
                    }), light, 60));

                    document.documentElement.style.setProperty(`--svg-shade-dark-${index + 1}`, tinyColor.mix(tinyColor({
                        ...tinyColor(dark).toHsl(),
                        h: tinyColor(colors.hero).toHsl().h,
                    }), dark, 60));
                });
            });
        } else {
            setReady(true);
        }
    }, []);

    return ready && children;
};

WhiteLabelHydrate.propTypes = {
    children: PropTypes.any,
};

export default WhiteLabelHydrate;
