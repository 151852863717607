import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icon';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const ScreenDetail = props => {
    const {
        icon,
        name,
        skeleton,
        children,
        appearance = 'expanded',
    } = props;

    const { isMobile } = useDevice();

    return (
        <ul
            className={classNames(
                `screen-details`,
                `a-${appearance}`,
            )}
        >
            <li>
                <Icon
                    type={icon}
                    skeleton={skeleton}
                    size={appearance === 'expanded' ? (isMobile ? 18 : 20) : 20}
                />
            </li>
            <li>
                <h4>
                    {skeleton ? (
                        <span className='skeleton-wave inline m-l min-60' />
                    ) : (
                        name
                    )}
                </h4>
                <div>
                    {skeleton ? (
                        <span className='skeleton-wave m-l min-110' />
                    ) : (
                        <p>{children}</p>
                    )}
                </div>
            </li>
        </ul>
    );
};

ScreenDetail.propTypes = {
    icon: PropTypes.string,
    name: PropTypes.any,
    skeleton: PropTypes.bool,
    children: PropTypes.any,
    appearance: PropTypes.oneOf([
        'expanded',
        'scrollable',
    ]),
};

export default ScreenDetail;
