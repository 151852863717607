import translate from './translate';

const bytesToOtherSizes = bytes => {
    const sizes = [
        translate('Bytes'),
        translate('KB'),
        translate('MB'),
        translate('GB'),
        translate('TB'),
    ];
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1000)), 10), sizes.length - 1) || 0;
    return bytes ? `${(bytes / (1000 ** i)).toFixed(i ? 1 : 0)} ${sizes[i]}` : '';
};

export default bytesToOtherSizes;
