import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Loader from '../loader';
import BlurHash from '../blurHash';

import './index.scss';

const LazyImage = props => {
    const {
        alt,
        style,
        width,
        height,
        source,
        blurCut,
        source2x,
        skeleton,
        blurHash,
        className,
        sourceWEBP,
        source2xWEBP,
    } = props;

    const ref = useRef(null);
    const [loaded, setLoaded] = useState(false);

    const srcSetConstruct = (x1, x2) => [x1 ? `${x1} 1x` : '', x2 ? `${x2} 2x` : ''].filter(i => i).join(', ');

    const srcSetWebp = srcSetConstruct(sourceWEBP, source2xWEBP);
    const srcSetImage = srcSetConstruct(source, source2x);

    return (
        <picture
            className={classNames(
                'lazy-image-holder',
                className,
                {
                    'not-loaded': !loaded,
                }
            )}
        >
            {!loaded && !blurHash && (skeleton ? (
                <div className='skeleton-blink absolute-splash' />
            ) : (
                <Loader absolute />
            ))}
            {!!source && (
                <>
                    {!!srcSetWebp && (
                        <source
                            type='image/webp'
                            srcSet={srcSetWebp}
                        />
                    )}
                    {!!srcSetImage && (
                        <source
                            type='image/jpeg'
                            srcSet={srcSetImage}
                        />
                    )}
                    <img
                        ref={ref}
                        src={source}
                        width={width}
                        height={height}
                        alt={alt || ''}
                        onLoad={() => setLoaded(true)}
                        style={{
                            ...style,
                            minHeight: loaded ? 0 : style?.minHeight,
                        }}
                        className={classNames(
                            {
                                'active': loaded,
                            }
                        )}
                    />
                </>
            )}
            {!!blurHash && (
                <BlurHash
                    hash={blurHash}
                    active={!loaded}
                />
            )}
            {loaded && blurCut && (
                <div className='lazy-meta-heading-blur'>
                    <img
                        alt={alt}
                        src={source}
                    />
                </div>
            )}
        </picture>
    );
};

LazyImage.propTypes = {
    alt: PropTypes.string,
    style: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    source: PropTypes.string,
    blurCut: PropTypes.bool,
    source2x: PropTypes.string,
    skeleton: PropTypes.bool,
    blurHash: PropTypes.string,
    className: PropTypes.string,
    sourceWEBP: PropTypes.string,
    source2xWEBP: PropTypes.string,
};

export default LazyImage;
