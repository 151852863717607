import Translate from '../../components/translate';

export const myScreensConfig = {
    screenStatusMapping: {
        synced: {
            id: 1,
            key: 'synced',
            name: <Translate>Synced</Translate>,
        },
        notSynced: {
            id: 2,
            key: 'notSynced',
            name: <Translate>Not Synced</Translate>,
        },
    },
    screenVisibilityMapping: {
        visible: {
            id: 1,
            key: 'visible',
            name: <Translate>Visible</Translate>,
        },
        hidden: {
            id: 2,
            key: 'hidden',
            name: <Translate>Hidden</Translate>,
        },
    },
};
