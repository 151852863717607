import { conditionalSpread, isFunction, rem } from 'clyne-core';
import { useState, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Avatar from '../avatar';

import { createOverlay } from '../../helpers';

import './index.scss';

const Point = props => {
    const {
        map,
        lat,
        lng,
        icon,
        avatar,
        onClick,
        focused,
        children,
        selected,
        gradient,
        size = 0,
        onMouseLeave,
        onMouseEnter,
        type = 'marker',
        color = 'brand',
        selectedIcon = 'icon-a-check',
    } = props;

    const container = useMemo(() => {
        const div = document.createElement('div');
        div.style.position = 'absolute';
        if (type === 'circle') {
            div.style.setProperty('--z-index', `${Number.isInteger(children) ? children : 1000}`);
        }
        return div;
    }, []); // eslint-disable-line

    const position = {
        lat,
        lng,
    };

    const overlay = useMemo(() => createOverlay({
        position,
        container,
        pane: 'overlayMouseTarget',
    }), [position]); // eslint-disable-line

    useEffect(() => {
        if (!overlay.map) {
            overlay?.setMap(map);
            return () => {
                overlay?.setMap(null);
            };
        }
    }, [map]); // eslint-disable-line

    const [hovered, setHovered] = useState(false);
    const [openPopover, setOpenPopover] = useState(false);
    const [showSelected, setShowSelected] = useState(false);
    const [arrowElement, setArrowElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [referenceElement, setReferenceElement] = useState(null);

    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        modifiers: [
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
        ],
    });

    useEffect(() => {
        setShowSelected(!!selected);
    }, [selected]);

    useEffect(() => {
        let timer;
        if (showSelected) {
            timer = setTimeout(() => {
                setShowSelected(false);
            }, 400);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [showSelected]);

    return createPortal((
        <>
            <button
                ref={setReferenceElement}
                onClick={e => {
                    e.stopPropagation();
                    isFunction(onClick);
                }}
                className={classNames(
                    'point-holder',
                    `t-${type}`,
                    `c-${selected ? 'success' : color}`,
                    {
                        'gradient': gradient?.length,
                        'selected': selected ? showSelected || (type === 'marker' && hovered) : false,
                        'focused': focused || openPopover,
                        'pointer-events-none': !onClick && !onMouseEnter && !onMouseLeave,
                    }
                )}
                style={{
                    ...conditionalSpread({
                        '--size': rem(size <= 32 ? 32 : size),
                    }, type === 'circle'),
                    ...conditionalSpread({
                        '--gradient-background': `linear-gradient(-194deg, ${gradient?.map(color => `var(--${color})`)?.join(', ')}, transparent)`,
                    }, gradient?.length),
                }}
                onMouseEnter={() => {
                    isFunction(onMouseEnter);
                    setHovered(true);
                    (type === 'marker' && children) && setOpenPopover(true);
                }}
                onMouseLeave={() => {
                    isFunction(onMouseLeave);
                    setHovered(false);
                    (type === 'marker' && children) && setOpenPopover(false);
                }}
            >
                <div className='point-shape'>
                    {type === 'marker' && (
                        <Icon type='icon-marker' />
                    )}
                </div>
                <ul className='mark-animate-holder'>
                    <li className='absolute-splash'>
                        {!!icon && (
                            <Icon type={icon} />
                        )}
                        {!!avatar && (
                            <Avatar url={avatar} />
                        )}
                        {(type === 'circle' && !!children) && (
                            <span>{children}</span>
                        )}
                    </li>
                    <li className='absolute-splash'>
                        <Icon
                            size={24}
                            type={selectedIcon}
                        />
                    </li>
                </ul>
                {(children && openPopover) && (
                    <>
                        <div className='mouse-overlay-popover' />
                        {createPortal((
                            <div
                                ref={setPopperElement}
                                style={styles.popper}
                                className='point-children-holder'
                                {...attributes.popper}
                                onClick={e => e.stopPropagation()}
                            >
                                <div className='point-children'>
                                    {children}
                                    <small ref={setArrowElement} style={styles.arrow} />
                                </div>
                            </div>
                        ), document.body)}
                    </>
                )}
            </button>
        </>
    ), container);
};

Point.propTypes = {
    map: PropTypes.object,
    lat: PropTypes.number,
    lng: PropTypes.number,
    type: PropTypes.oneOf([
        'marker',
        'circle',
    ]),
    size: PropTypes.number,
    icon: PropTypes.string,
    color: PropTypes.oneOf([
        'brand',
        'success',
        'dark',
        'taxi',
    ]),
    avatar: PropTypes.string,
    onClick: PropTypes.func,
    focused: PropTypes.bool,
    children: PropTypes.any,
    selected: PropTypes.bool,
    gradient: PropTypes.arrayOf(PropTypes.string),
    onMouseLeave: PropTypes.func,
    onMouseEnter: PropTypes.func,
    selectedIcon: PropTypes.string,
};

export default Point;
