import Guide from '../guide';
import Translate from '../translate';
import SplashInformation from '../splashInformation';

import './index.scss';

const ErrorFallback = () => {
    return (
        <div className='error-fallback-holder'>
            <Guide middle>
                <SplashInformation
                    logo
                    artwork={{
                        color: 'error',
                        icon: 'icon-a-cross-circle',
                    }}
                    title={<Translate>Somebody is getting fired for this!</Translate>}
                    message={<Translate>We sincerely apologize for the error you encountered. Rest assured, we will thoroughly investigate this issue and take all necessary measures to ensure it does not recur in the future.</Translate>}
                    ctas={[
                        {
                            onClick: () => window.location.reload(),
                            children: <Translate>Reload the Page</Translate>,
                        },
                        {
                            onClick: () => window.location.href = '/',
                            children: <Translate>Go Home</Translate>,
                        },
                    ]}
                />
            </Guide>
        </div>
    );
};

export default ErrorFallback;
