import PropTypes from 'prop-types';
import { useRecoilValue } from 'recoil';
import { Fragment } from 'react';

import { languageState } from '../../state';

import translationService from '../../services/translationService';

const Translate = props => {
    const {
        children,
        replaceMap,
    } = props;

    const language = useRecoilValue(languageState);

    return typeof children === 'string' ? (
        <Fragment key={language?.code}>
            {translationService.t(children, replaceMap)}
        </Fragment>
    ) : children;
};

Translate.propTypes = {
    children: PropTypes.any,
    replaceMap: PropTypes.object,
};

export default Translate;
