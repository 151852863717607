import { useRecoilValue } from 'recoil';
import { useEffect, useState } from 'react';

import { getLSItem } from '../helpers';

import { genericState, preciseLocationState, userState, workspaceModeState } from '../state';

const usePrice = (number, condition = true, postfix = '') => {
    const user = useRecoilValue(userState);
    const generic = useRecoilValue(genericState)
    const workspaceMode = useRecoilValue(workspaceModeState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const currencies = generic?.currencies;

    const [price, setPrice] = useState('');

    useEffect(() => {
        if (condition && preciseLocation?.currency && !isNaN(number)) {
            const langCode = preciseLocation?.location?.languages?.[0]?.code || 'ENG';
            const searchForCurrency = workspaceMode ? user?.workspace?.currency : (user?.currency || getLSItem('currency') || preciseLocation?.currency);
            const userCurrency = currencies?.find(({ code }) => code === searchForCurrency) || preciseLocation;
            const locales = `${langCode}-${user?.countryId || preciseLocation?.country_code}`;
            const options = {
                style: 'currency',
                currency: userCurrency?.code || preciseLocation?.currency,
            };
            const formatter = () => {
                switch (true) {
                    case options.currency === 'AMD':
                        return `${new Intl.NumberFormat().format(number)}${postfix} ${userCurrency?.symbol || preciseLocation?.currency_symbol}`;
                    default:
                        return `${new Intl.NumberFormat(locales, options).format(number)}${postfix}`;
                }
            };
            setPrice(formatter()?.replace(/\D00(?=\D*$)/, ''));
        }
    }, [preciseLocation, number, condition, postfix, workspaceMode]);  // eslint-disable-line

    return condition ? price : number;
};

export default usePrice;
