const lightMain = '#f2f3f6';

export const mapTheme = {
    light: [
        {
            'featureType': 'all',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': lightMain,
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#747b8f'
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'color': lightMain,
                },
                {
                    'visibility': 'on'
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': lightMain,
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#b5bac6'
                }
            ]
        },
        {
            'featureType': 'poi.medical',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#ffd5e3'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#d1f4d6'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#15a24c'
                }
            ]
        },
        {
            'featureType': 'poi.sports_complex',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#dfdfe6'
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#ffffff'
                },
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'transit.line',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#c6c6cc'
                }
            ]
        },
        {
            'featureType': 'transit.station',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#dcdce2'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#cde5fb'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#0083ff'
                }
            ]
        }
    ],
    dark: [
        {
            'featureType': 'all',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#0c0e11'
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#747b8f'
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.text.stroke',
            'stylers': [
                {
                    'color': '#0c0e11'
                },
                {
                    'visibility': 'on'
                }
            ]
        },
        {
            'featureType': 'all',
            'elementType': 'labels.icon',
            'stylers': [
                {
                    'visibility': 'off'
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#0c0e11'
                }
            ]
        },
        {
            'featureType': 'landscape.man_made',
            'elementType': 'geometry.stroke',
            'stylers': [
                {
                    'color': '#3b404b'
                }
            ]
        },
        {
            'featureType': 'poi.medical',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#250912'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'geometry',
            'stylers': [
                {
                    'lightness': '8'
                },
                {
                    'color': '#041814'
                }
            ]
        },
        {
            'featureType': 'poi.park',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#1ec05c'
                }
            ]
        },
        {
            'featureType': 'poi.sports_complex',
            'elementType': 'geometry.fill',
            'stylers': [
                {
                    'color': '#d5d5ec'
                },
                {
                    'invert_lightness': true
                }
            ]
        },
        {
            'featureType': 'road',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#15171e'
                },
                {
                    'visibility': 'simplified'
                }
            ]
        },
        {
            'featureType': 'transit.line',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#232328'
                }
            ]
        },
        {
            'featureType': 'transit.station',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#171721'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'geometry',
            'stylers': [
                {
                    'color': '#172136'
                }
            ]
        },
        {
            'featureType': 'water',
            'elementType': 'labels.text.fill',
            'stylers': [
                {
                    'color': '#0083ff'
                }
            ]
        }
    ],
};
