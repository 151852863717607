import { Route, Routes, useLocation } from 'react-router-dom';
import React, { Fragment, memo } from 'react';
import { useRecoilValue } from 'recoil';

import Wizard from '../wizard';
import Footer from '../../components/footer';
import Header from '../../components/header';
import Helmet from '../../components/helmet';
import Toaster from '../../components/toaster';
import RedirectTo404 from '../../components/redirectTo404';
import AppUrlListener from '../../components/appUrlListener';
import RouteComponent from '../../components/routeComponent';
import SharedComponents from '../../components/sharedComponents';

import translate from '../../utils/translate';

import { crState, loadToasterState, wizardState } from '../../state';

import { routes, fallbackRoute } from '../../routes';

import useDevice from '../../hooks/useDevice';

const pathnameSplitter = str => `/${str.split('/')[1]}`;

const flatRoutes = routes.map(route => route.url).flatMap(routes => routes).map(pathnameSplitter);

const Layout = memo(() => {
    const location = useLocation();

    const cr = useRecoilValue(crState);
    const wizard = useRecoilValue(wizardState);
    const loadToaster = useRecoilValue(loadToasterState);

    const {
        isMobile,
    } = useDevice();

    const routeRenderer = (route, url, index) => (
        <Route
            index={index}
            path={url || route.url}
            key={route.url + translate(route.name)}
            element={(
                <RouteComponent
                    route={route}
                    isProtected={!!route?.protected}
                />
            )}
        />
    );

    return (
        <>
            <Helmet />
            <AppUrlListener />
            <Header />
            <main>
                {wizard.enabled ? (
                    <Wizard searchKey={wizard.key} />
                ) : (
                    <Routes>
                        {routes.map(route => typeof route.url === 'string' ? routeRenderer(route) : route.url.map((url, index) => (
                            <Fragment key={url}>
                                {routeRenderer(route, url, route.slug === 'home' && !index)}
                            </Fragment>
                        )))}
                        {!flatRoutes.includes(pathnameSplitter(location.pathname)) && routeRenderer({
                            ...fallbackRoute,
                            component: <RedirectTo404 />,
                        }, '*')}
                    </Routes>
                )}
            </main>
            {(cr.footer && cr.footer(isMobile)) && (
                <Footer />
            )}
            {loadToaster && (
                <Toaster />
            )}
            <SharedComponents />
        </>
    );
});

export default Layout;
