import React, { useEffect, useRef, useState } from 'react';
import { conditionalSpread, rem } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Loader from '../loader';
import Button from '../button';
import Tooltip from '../tooltip';
import BlurHash from '../blurHash';
import LightBox from '../lightBox';
import Translate from '../translate';
import MarqueeLoop from '../marqueeLoop';
import TextTransition from '../textTransition';

import { getFileNameFromSource, ratioStyle, guid } from '../../helpers';

import translate from '../../utils/translate';
import setContrastColor from '../../utils/setConstractColor';

import { fileTypeByFormat } from '../../constants/construct';
import resizeModesStruct from '../../constants/resizeModesStruct';

import useElementResize from '../../hooks/useElementResize';
import useFileDragAndDrop from '../../hooks/useFileDragAndDrop';

import uploadService from '../../services/uploadService';

import './index.scss';

const decorations = [
    'icon-a-fat-rows',
    'icon-a-image-fill',
    'icon-a-play-fill',
    'icon-a-fat-rows',
    'icon-a-image-fill',
    'icon-a-play-fill',
];

const MediaFrame = props => {
    const {
        id,
        name,
        error,
        color,
        width,
        height,
        source,
        blurHash,
        onUpload,
        featured,
        onDelete,
        mediaSize,
        getterUrl,
        thumbnail,
        onFeatured,
        onComplete,
        completeUrl,
        showResizeMode,
        accept = 'media',
        appearance = 'box',
        onResizeModeChange,
        initialResizeMode = 1,
    } = props;

    const [key, setKey] = useState(null);
    const [uploads, setUploads] = useState({});
    const [hovered, setHovered] = useState(false);
    const resizeModeRef = useRef(initialResizeMode);
    const [localError, setLocalError] = useState('');
    const [mediaLoaded, setMediaLoaded] = useState(false);
    const [lightBoxOpened, setLightBoxOpened] = useState(false);
    const [resizeMode, setResizeMode] = useState(initialResizeMode);

    const ref = useRef(null);
    const labelRef = useRef(null);

    const {
        width: elementWidth,
        height: elementHeight,
    } = useElementResize(ref);

    const currentUpload = uploads[key];

    const inProgress = currentUpload?.status === 'pending' || currentUpload?.status === 'uploading' || currentUpload?.status === 'loading';

    const mode = getterUrl ? 'upload' : 'view';

    const states = {
        done: 'done',
        blank: 'blank',
        error: 'error',
        pending: 'pending',
        loading: 'loading',
        uploading: 'uploading',
    };

    const internalState = localError ? 'error' : currentUpload?.status ? currentUpload?.status : source ? 'done' : 'blank';

    const state = states[internalState];

    const sourceName = getFileNameFromSource(source);
    const sourceNameParts = `${sourceName}`?.split('.');
    const sourceKey = sourceNameParts?.slice(0, sourceNameParts?.length - 1)?.[0];
    const sourceExtension = sourceNameParts?.[sourceNameParts?.length - 1]?.toLowerCase();

    const foundInBlobs = currentUpload || uploads[sourceKey || sourceName];

    const currentMedia = {
        type: foundInBlobs?.data?.type || fileTypeByFormat.find(item => item.formats.includes(sourceExtension))?.type,
        name: decodeURI(`${foundInBlobs?.data?.name || name}`),
        color: foundInBlobs?.data?.color || color,
        source: foundInBlobs?.data?.blobUrl || source,
        blurHash: foundInBlobs?.data?.blurHash || blurHash,
        width: foundInBlobs?.data?.width || mediaSize?.width,
        height: foundInBlobs?.data?.height || mediaSize?.height,
        thumbnail: foundInBlobs?.data?.thumbnailUrl || thumbnail,
        contrastColor: setContrastColor(foundInBlobs?.data?.color || color || 'black'),
    };

    const hasMedia = !!currentMedia.source;

    useEffect(() => {
        setKey(guid());
        uploadService.getUploads().subscribe(setUploads);
    }, []); // eslint-disable-line

    useEffect(() => {
        setLocalError(error);
    }, [error]);

    useEffect(() => {
        const uploadValues = Object.values(uploads);
        if (!hasMedia && uploadValues.length && id) {
            const found = uploadValues.find(upload => upload.guid === id);
            !!found && setKey(found.key);
        }
    }, [hasMedia, uploads]); // eslint-disable-line

    const handleDelete = () => {
        setLocalError('');
        setMediaLoaded(false);
        uploadService.cancelUpload(key);
        !!onDelete && onDelete(inProgress);
        setKey(guid());
    };

    const handleUpload = file => {
        !!onUpload && onUpload(file);
        setLocalError('');
        setMediaLoaded(false);
        new Promise(resolve => {
            if (!inProgress) {
                return resolve(key);
            }
            uploadService.cancelUpload(key);
            const newKey = guid();
            setKey(newKey);
            resolve(newKey);
        }).then(key => {
            uploadService.handleUpload(file, key, {
                guid: id,
                getterUrl,
                completeUrl,
                onKeyChange: key => setKey(key),
            }).then(val => {
                if (val) {
                    onComplete(Object.values(val).length ? {
                        ...val,
                        resizeMode: resizeModeRef.current,
                    } : val);
                }
            }).catch(e => {
                if (e.code !== 'ERR_CANCELED' && e.code !== 'UNSUPPORTED_FORMAT') {
                    handleDelete();
                    setLocalError(translate('Try again or another file'));
                } else {
                    setKey(guid());
                }
            });
        });
    };

    const holderStyle = {
        '--width': rem(elementWidth),
        '--contrastColor': currentMedia.contrastColor,
    };

    const boxStyle = ratioStyle({
        width,
        height,
    }, elementWidth, elementHeight);

    const { dragged } = useFileDragAndDrop(labelRef, handleUpload);

    const progress = (foundInBlobs?.progress || 0).toFixed(1).replaceAll('.0', '');

    const isLoading = state === 'loading' || foundInBlobs?.progress === 100;

    const acceptFileTypes = fileTypeByFormat.filter(item => accept === 'media' ? item.type : item.type === accept).flatMap(i => i.formats).map(w => `.${w}`).join(',');

    const sharedStyle = {
        objectFit: resizeModesStruct.find(mode => mode.value === resizeMode)?.fit || 'cover',
        background: 'black',
    };

    return (
        <>
            <div
                ref={ref}
                style={holderStyle}
                className={classNames(
                    'media-frame-holder',
                    `s-${state}`,
                    `m-${mode}`,
                    `a-${appearance}`,
                    {
                        hovered,
                        dragged,
                        'has-media': hasMedia,
                    }
                )}
            >
                <div className='position-relative'>
                    {!!showResizeMode && (
                        <ul className='media-frame-resize-mode-holder'>
                            {resizeModesStruct.map(mode => (
                                <li
                                    key={mode.value}
                                    onClick={() => {
                                        setResizeMode(mode.value);
                                        resizeModeRef.current = mode.value;
                                        !!onResizeModeChange && onResizeModeChange(mode.value);
                                    }}
                                >
                                    <div
                                        className={classNames(
                                            'mf-resize-mode-item',
                                            {
                                                active: mode.value === resizeMode,
                                            }
                                        )}
                                    >
                                        <Icon
                                            size={18}
                                            type={mode.icon}
                                        />
                                        <span
                                            style={{
                                                maxWidth: mode.value === resizeMode ? rem((mode.label.length * 8) + 4) : 0,
                                            }}
                                        >
                                    {mode.label}
                                </span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    )}
                    <div
                        style={boxStyle}
                        className='media-frame-ar-holder'
                        onMouseEnter={() => setHovered(true)}
                        onMouseLeave={() => setHovered(false)}
                    >
                        {((hasMedia ? !mediaLoaded : true) && mode === 'upload') && (
                            <ul className='media-frame-decorations absolutely-splash pointer-events-none'>
                                {decorations.map((icon, index) => (
                                    <li key={index}>
                                        <Icon
                                            type={icon}
                                        />
                                    </li>
                                ))}
                            </ul>
                        )}
                        {(hasMedia || mode === 'view') && (
                            <div className='media-frame-preview absolute-splash'>
                                {(!mediaLoaded && hasMedia) && (
                                    <Loader absolute />
                                )}
                                {hasMedia ? (
                                    <>
                                        <BlurHash
                                            hash={currentMedia.blurHash}
                                        />
                                        {currentMedia.type === 'video' ? (
                                            <video
                                                loop
                                                muted
                                                autoPlay
                                                playsInline
                                                style={sharedStyle}
                                                src={currentMedia.source}
                                                className='animation-fadeIn'
                                                poster={currentMedia.thumbnail}
                                                onCanPlay={() => setMediaLoaded(true)}
                                            />
                                        ) : (
                                            <img
                                                alt=''
                                                style={sharedStyle}
                                                className='animation-fadeIn'
                                                onLoad={() => setMediaLoaded(true)}
                                                src={currentMedia.thumbnail || currentMedia.source}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <p>
                                        <Translate>No media</Translate>
                                    </p>
                                )}
                                {(currentMedia.name && mediaLoaded && props.hasOwnProperty('name')) && (
                                    <div className='media-frame-preview-name'>
                                        <MarqueeLoop>
                                            {currentMedia.name}
                                        </MarqueeLoop>
                                    </div>
                                )}
                            </div>
                        )}
                        {mode === 'upload' && (
                            <label
                                ref={labelRef}
                                className='absolute-splash cursor-pointer'
                            >
                                <input
                                    onChange={e => {
                                        handleUpload(e.target.files[0]);
                                        e.target.value = '';
                                    }}
                                    type='file'
                                    accept={acceptFileTypes}
                                />
                            </label>
                        )}
                        <ul className='media-frame-states-holder pointer-events-none absolute-splash'>
                            <li
                                className={classNames(
                                    'absolute-splash',
                                    {
                                        'active': hasMedia && !dragged && hovered && !inProgress,
                                    }
                                )}
                            >
                                <button
                                    type='button'
                                    disabled={!hasMedia}
                                    className='media-frame-preview-cta'
                                    onClick={e => {
                                        e.stopPropagation();
                                        setLightBoxOpened(val => !val);
                                    }}
                                >
                                    <Icon type='icon-maximize-alt' />
                                </button>
                            </li>
                            {mode === 'upload' && (
                                <>
                                    <li
                                        className={classNames(
                                            'absolute-splash',
                                            {
                                                'active': (isLoading || localError || state === 'pending' || state === 'loading' || state === 'uploading') && state !== 'done' && !dragged,
                                            }
                                        )}
                                    >
                                        <ul className='media-frame-progress-holder'>
                                            <li>
                                                <svg
                                                    viewBox='0 0 100 100'
                                                    className={classNames(
                                                        {
                                                            'active': isLoading,
                                                        }
                                                    )}
                                                >
                                                    <path
                                                        className='circle-trail'
                                                        d='M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94'
                                                    />
                                                    <path
                                                        className='circle-path'
                                                        d='M 50,50 m 0,-47a 47,47 0 1 1 0,94a 47,47 0 1 1 0,-94'
                                                        style={{
                                                            '--stroke-dasharray': `${(localError ? 100 : foundInBlobs?.progress || 0) * 3}px`,
                                                        }}
                                                    />
                                                </svg>
                                            </li>
                                            <li className='absolute-splash'>
                                                {localError ? (
                                                    <>
                                                        <Icon
                                                            size={32}
                                                            type='icon-a-cross-octagon'
                                                        />
                                                        {localError?.length && (
                                                            <p>
                                                                {localError}
                                                            </p>
                                                        )}
                                                    </>
                                                ) : (
                                                    <strong>
                                                        {`${progress}%`}
                                                    </strong>
                                                )}
                                            </li>
                                        </ul>
                                    </li>
                                    <li
                                        className={classNames(
                                            'absolute-splash',
                                            {
                                                'active': (hovered ? dragged : state === 'blank') || dragged,
                                            }
                                        )}
                                    >
                                        <div className='media-frame-drag'>
                                            <Icon
                                                size={40}
                                                type='icon-a-cloud-upload'
                                            />
                                            {!hasMedia && (
                                                <TextTransition
                                                    text={dragged ? translate('Drop Here') : translate('Drag & Drop')}
                                                />
                                            )}
                                        </div>
                                    </li>
                                    <li
                                        className={classNames(
                                            'absolute-splash',
                                            {
                                                'active': hovered && !dragged && state === 'blank',
                                            }
                                        )}
                                    >
                                        <div className='media-frame-add'>
                                            <Icon
                                                size={32}
                                                type='icon-a-plus'
                                            />
                                        </div>
                                    </li>
                                </>
                            )}
                        </ul>
                        <div
                            className={classNames(
                                'upload-featured',
                                {
                                    active: !!hasMedia && onFeatured,
                                }
                            )}
                        >
                            <Tooltip
                                content={featured ? (
                                    <Translate>Unmark as featured</Translate>
                                ) : (
                                    <Translate>Mark as featured</Translate>
                                )}
                            >
                                <small
                                    onClick={onFeatured}
                                    className={classNames(
                                        {
                                            'active': featured,
                                        }
                                    )}
                                >
                                    <Icon
                                        size={18}
                                        type='icon-a-check'
                                    />
                                </small>
                            </Tooltip>
                        </div>
                        <div
                            className={classNames(
                                'media-frame-ar-border',
                                'pointer-events-none',
                                'absolute-splash',
                                {
                                    active: true,
                                }
                            )}
                        />
                        <div
                            className={classNames(
                                'media-frame-remove',
                                {
                                    'active': hasMedia,
                                }
                            )}
                        >
                            <Button
                                shadow
                                size='small'
                                type='button'
                                color='contrast'
                                onClick={handleDelete}
                                icon={{
                                    size: 20,
                                    type: 'icon-a-cross',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {hasMedia && (
                <LightBox
                    type={currentMedia.type}
                    sources={[currentMedia.source]}
                    opened={lightBoxOpened}
                    {...conditionalSpread({
                        customAttributes: [
                            {
                                poster: currentMedia.thumbnail,
                            },
                        ],
                    }, currentMedia.type === 'video')}
                />
            )}
        </>
    );
};

MediaFrame.propTypes = {
    id: PropTypes.any,
    name: PropTypes.string,
    error: PropTypes.any,
    width: PropTypes.number,
    color: PropTypes.string,
    height: PropTypes.number,
    source: PropTypes.any,
    accept: PropTypes.oneOf([
        'media',
        'image',
        'video',
    ]),
    blurHash: PropTypes.string,
    onUpload: PropTypes.func,
    featured: PropTypes.bool,
    onDelete: PropTypes.func,
    mediaSize: PropTypes.shape({
        width: PropTypes.number,
        height: PropTypes.number,
    }),
    getterUrl: PropTypes.string,
    thumbnail: PropTypes.any,
    onFeatured: PropTypes.func,
    appearance: PropTypes.oneOf([
        'box',
        'fit',
    ]),
    onComplete: PropTypes.func,
    completeUrl: PropTypes.string,
    showResizeMode: PropTypes.bool,
    initialResizeMode: PropTypes.number,
    onResizeModeChange: PropTypes.func,
};

export default MediaFrame;
