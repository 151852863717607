import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Modal from '../modal';
import Translate from '../translate';

import MyScreens from '../../containers/myScreens';

import { signedInState, userRoleState } from '../../state';

import useRedirectToSignIn from '../../hooks/useRedirectToSignIn';

import { getURLParam, urlConstruct } from '../../helpers';

import { userRoles } from '../../constants/construct';

const SyncScreenWizard = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [active, setActive] = useState(false);

    const userRole = useRecoilValue(userRoleState);
    const signedIn = useRecoilValue(signedInState);

    const setShouldRedirect = useRedirectToSignIn();

    const onSyncSuccess = () => {
        setActive(false);
        navigate(urlConstruct({
            pathname: location.pathname,
        }, { replace: true }));
    };

    useEffect(() => {
        const code = getURLParam('sync');
        setActive(!!(code && userRole === userRoles.partner));
        setShouldRedirect(!!(code && !signedIn));
        if (signedIn && userRole !== userRoles.partner && userRole !== null && code) {
            onSyncSuccess();
        }
    }, [location.pathname, location.search, userRole, signedIn]); // eslint-disable-line

    return !!signedIn && (
        <Modal
            width={940}
            active={active}
            onClose={() => onSyncSuccess()}
            title={
                <Translate>Select the Screen to Sync</Translate>
            }
            primaryButton={{
                children: <Translate>Okay</Translate>,
                onClick: () => onSyncSuccess(),
            }}
        >
            <MyScreens
                appearance='select'
                onSyncSuccess={onSyncSuccess}
            />
        </Modal>
    );
};

export default SyncScreenWizard;
