import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { conditionalSpread } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../../components/icon';
import Guide from '../../components/guide';
import Button from '../../components/button';
import Translate from '../../components/translate';
import TextTransition from '../../components/textTransition';
import RouteComponent from '../../components/routeComponent';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';
import getRoutesBySlug from '../../utils/getRoutesBySlug';

import useDevice from '../../hooks/useDevice';

import { campaignDataState, crState, searchForceUpdateState, selectedVirtualSlotsState, wizardState, workspaceIdState } from '../../state';

import { PAYMENT_BOUND_CARDS, SCREENS, URLS } from '../../constants/apiKeys';

import campaignService from '../../services/campaignService';
import connectionService from '../../services/connectionService';

import { groupScreens, removeDuplicatesFromArray } from '../../helpers';

import { routes } from '../../routes';

import './index.scss';

const Wizard = props => {
    const {
        searchKey,
    } = props;

    const campaignPrevData = useRef(null);

    const navigate = useNavigate();
    const { isMobile } = useDevice();
    const { search } = useLocation();

    const cr = useRecoilValue(crState);
    const setWizard = useSetRecoilState(wizardState);
    const workspaceId = useRecoilValue(workspaceIdState);
    const campaignData = useRecoilValue(campaignDataState);
    const searchForceUpdate = useRecoilValue(searchForceUpdateState);
    const selectedVirtualSlots = useRecoilValue(selectedVirtualSlotsState);

    const [showSaved, setShowSaved] = useState(false);
    const [wizardRoutes, setWizardRoutes] = useState([]);
    const [nextLoading, setNextLoading] = useState(false);
    const [mainScreensData, updateMainScreensData] = useState([]);

    const currentStep = cr?.wizard?.step;
    const prev = wizardRoutes[currentStep - 1];
    const next = wizardRoutes[currentStep + 1];

    useEffect(() => {
        document.documentElement.classList.add('filters-not-animated');

        return () => {
            campaignService.disposeCampaign();
        };
    }, []); // eslint-disable-line

    const dataToSend = useMemo(() => {
        return campaignData ? ({
            ...campaignData,
            virtualSlots: Object.keys(selectedVirtualSlots || {}).filter(key => selectedVirtualSlots[key] > 1).reduce((prev, current) => ({
                ...prev,
                [current]: selectedVirtualSlots[current] - 1,
            }), {}),
        }) : null;
    }, [campaignData, selectedVirtualSlots]);

    useEffect(() => {
        if (dataToSend && JSON.stringify(campaignPrevData.current || {}) !== JSON.stringify(dataToSend || {})) {
            campaignPrevData.current = dataToSend;
            campaignService.initCampaign(dataToSend);
        }
    }, [dataToSend]); // eslint-disable-line

    useEffect(() => {
        let timer = null;
        const subscription = campaignService.isSaved.subscribe(() => {
            setShowSaved(true);
            timer = setTimeout(() => {
                setShowSaved(false);
            }, 3000);
        });
        return () => {
            subscription && subscription.unsubscribe();
            timer && clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        let subscription;
        if (campaignData && campaignData?.availableScreens && campaignData?.medias?.length) {
            subscription = connectionService.getJson(`${URLS[SCREENS]}/${removeDuplicatesFromArray([...(campaignData.availableScreens || [])])?.sort((a, b) => a - b)?.join(',')}`).subscribe(screens => {
                const { main } = groupScreens(screens);
                updateMainScreensData(Object.keys(main).map(key => main[key]));
            });
        }

        return () => {
            subscription && subscription.unsubscribe();
        };
    }, [campaignData]);

    useEffect(() => {
        !!searchKey && setWizardRoutes(routes.filter(route => route.wizard.key === searchKey && route));
    }, [searchKey]);

    const validateDefaultMedias = () => {
        return (mainScreensData || []).every(screen => (campaignData?.medias || []).some(({ additionalData }) => screen?.groupKey === additionalData?.groupKey));
    };

    const validateAndGo = () => {
        switch (true) {
            case ((next?.url === '/campaign/schedule' || next?.url === '/campaign/add-media') && !campaignData?.screens?.length): {
                addToast({
                    type: 'warning',
                    id: 'noScreensSelected',
                    title: <Translate>No screens selected</Translate>,
                    message: <Translate>You must have at least one selected screen to continue</Translate>,
                });
                break;
            }
            case (next?.url === '/campaign/add-media' && !campaignData?.availableScreens?.length): {
                addToast({
                    type: 'warning',
                    id: 'noAvailableScreens',
                    title: <Translate>No available screens</Translate>,
                    message: <Translate>You must have at least one available screen to continue</Translate>,
                });
                break;
            }
            case (next?.url === '/campaign/review' && (!campaignData?.medias?.length || !validateDefaultMedias())): {
                addToast({
                    type: 'warning',
                    id: 'mediaUploadIsRequired',
                    title: <Translate>Media upload is required</Translate>,
                    message: <Translate>You must upload medias for all required groups to continue</Translate>,
                });
                break;
            }
            case (next?.url === '/campaign/finalize'): {
                if (process.env.NODE_ENV !== 'development' && campaignData?.availableScreens) {
                    setNextLoading(true);
                    connectionService.getJson(`${SCREENS}/${removeDuplicatesFromArray(campaignData?.availableScreens)?.join(',')}`).subscribe(val => {
                        const selfWorkspace = val.map(screen => screen.workspaceId).every(id => id === workspaceId);
                        if (selfWorkspace) {
                            setNextLoading(false);
                            navigate(next?.url + search);
                        } else {
                            connectionService.getJson(PAYMENT_BOUND_CARDS, {}, false).subscribe(val => {
                                setNextLoading(false);
                                if (val?.length) {
                                    navigate(next?.url + search);
                                } else {
                                    addToast({
                                        type: 'warning',
                                        id: 'paymentCardIsRequired',
                                        title: <Translate>Payment method is required</Translate>,
                                        message: <Translate>You need to add a payment method to complete your campaign submission</Translate>,
                                    });
                                }
                            });
                        }
                    });
                } else {
                    navigate(next?.url + search);
                }
                break;
            }
            default:
                navigate(next?.url + search);
        }
    };

    useEffect(() => {
        setWizard(val => ({
            ...val,
            meta: {
                ...val.meta,
                routes: wizardRoutes,
            },
        }));

        return () => {
            setWizard(val => ({ ...val, meta: {} }));
        };
    }, [wizardRoutes]);  // eslint-disable-line

    useEffect(() => {
        !!campaignData && setWizard(val => ({
            ...val,
            meta: {
                ...val?.meta,
                header: true,
                name: campaignData?.name && campaignData?.id ? `#${campaignData?.id} - ${campaignData?.name}` : (
                    <Translate>Campaign</Translate>
                ),
                returnUrl: getRoutesBySlug(['myCampaigns'])?.[0]?.url,
            }
        }));
    }, [campaignData]); // eslint-disable-line

    const buttonSharedProps = {
        size: isMobile ? 'default' : 'medium',
        color: 'accent',
        flexibility: 'fit',
        itemsDirection: 'end',
        cornerRadius: 'full',
    };

    return !!wizardRoutes.length && (
        <div
            className={classNames(
                'wizard-holder',
                {
                    'absolutely-splash': !isMobile,
                }
            )}
        >
            <div
                className={classNames(
                    `wizard-step-saved`,
                    {
                        'active': showSaved,
                    }
                )}
            >
                <Icon
                    size={24}
                    type='icon-a-check'
                />
                <p>
                    {isMobile ? (
                        <Translate>Saved</Translate>
                    ) : (
                        <Translate>Successfully Saved</Translate>
                    )}
                </p>
            </div>
            <ul
                className={classNames(
                    'wizard-children-holder',
                    {
                        'absolutely-splash': !isMobile,
                    }
                )}
                {...conditionalSpread({
                    style: {
                        transform: `translate3d(-${100 * currentStep}%, 0, 0)`,
                    },
                }, !isMobile)}
            >
                {wizardRoutes.map(route => (
                    <li
                        key={route.wizard.step}
                        className={classNames({
                            'has-footer': typeof route?.wizard?.footer === 'function' && route?.wizard?.footer(isMobile),
                        })}
                    >
                        {route.wizard.step === currentStep && (
                            <RouteComponent
                                route={route}
                                key={`${searchForceUpdate}${isMobile}`}
                                isProtected={!!route?.protected}
                            />
                        )}
                    </li>
                ))}
            </ul>
            <div
                className={classNames(
                    `wizard-footer-holder`,
                    {
                        'active': typeof cr?.wizard?.footer === 'function' && cr?.wizard?.footer(isMobile),
                    }
                )}
            >
                <div className='wizard-progress'>
                    <div
                        className='wizard-progress-state absolutely-splash'
                        style={{ width: `${100 / wizardRoutes.length * (currentStep + 1)}%` }}
                    />
                </div>
                <Guide padding={false}>
                    <ul className='wizard-footer'>
                        <li>
                            {!!prev && (
                                <Button
                                    {...buttonSharedProps}
                                    onClick={() => navigate(-1)}
                                    icon={{
                                        type: 'icon-a-arrow-left',
                                    }}
                                />
                            )}
                        </li>
                        <li>
                            <TextTransition
                                flexibility='fit'
                                text={isMobile ? translate(cr.name) : (
                                    translate('Step _NUMBER_STEP_NAME_', { '_NUMBER_STEP_NAME_': `${currentStep + 1} - ${translate(cr.name)}` })
                                )}
                            />
                        </li>
                        <li>
                            {!!next && (
                                <Button
                                    {...buttonSharedProps}
                                    onClick={validateAndGo}
                                    icon={{
                                        type: 'icon-a-arrow-right',
                                    }}
                                    loading={nextLoading}
                                    disabled={cr?.wizard?.cta?.disabled ? cr?.wizard?.cta?.disabled(campaignData) : false}
                                    {...conditionalSpread({
                                        name: cr?.wizard?.cta?.children || translate('Next'),
                                    }, !isMobile)}
                                />
                            )}
                        </li>
                    </ul>
                </Guide>
            </div>
        </div>
    );
};

Wizard.propTypes = {
    searchKey: PropTypes.string,
};

export default Wizard;
