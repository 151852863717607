import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

import days from '../../constants/days';

import './index.scss';

const WorkingDays = props => {
    const {
        data,
    } = props;

    const sortedData = data?.sort((a, b) => a?.weekDayGroup - b?.weekDayGroup);

    return (
        <div className='working-days'>
            {days.map((day, index) => {
                const from = sortedData?.[index] ? moment.utc(sortedData[index].openTime).format('HH:mm') : '-';
                const to = sortedData?.[index] ? moment.utc(sortedData[index].closeTime).format('HH:mm') : '-';

                return (
                    <div
                        key={index}
                        className={classNames(
                            `working-day`,
                            {
                                'disabled': from === '-' && to === '-',
                            }
                        )}
                    >
                        <div className='working-day-name'>
                            {day}
                        </div>
                        <p>
                            {`${from} - ${to}`}
                        </p>
                    </div>
                );
            })}
        </div>
    );
};

WorkingDays.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
};

export default WorkingDays;
