import React, { forwardRef, useEffect, useState } from 'react';
import { conditionalSpread, isFunction, toProps } from 'clyne-core';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Badge from '../badge';
import Tooltip from '../tooltip';
import Translate from '../translate';
import TextTransition from '../textTransition';

import { getFileNameFromSource } from '../../helpers';

import useColor from '../../hooks/useColor';
import useComponentRendered from '../../hooks/useComponentRendered';

import translate from '../../utils/translate';

import { uiKitCornerRadius, uiKitSizes } from '../../props';

import './index.scss';

const Button = forwardRef(function Button(props, ref) {
    const {
        to,
        rel,
        icon,
        href,
        hero,
        name,
        addon,
        slash,
        badge,
        shadow,
        target,
        loading,
        onClick,
        tooltip,
        children,
        download,
        gradient,
        tabIndex,
        disabled,
        className,
        ariaLabel,
        forceFocus,
        onDoubleClick,
        type = 'submit',
        color = 'brand',
        appearance = 'fill',
        flexibility = 'full',
        size = uiKitSizes[2],
        itemsDirection = 'start',
        cornerRadius = uiKitCornerRadius[0],
    } = props;

    const [focused, setFocused] = useState(false);
    const [badgeState, setBadgeState] = useState(null);
    const [animateBadge, setAnimateBadge] = useState(false);
    const componentRendered = useComponentRendered();

    useEffect(() => {
        setAnimateBadge(!!badge && componentRendered && badgeState !== JSON.stringify(badge));
        setBadgeState(JSON.stringify(badge));
    }, [badge]); // eslint-disable-line

    useEffect(() => {
        const timer = setTimeout(() => !!animateBadge && setAnimateBadge(false), 600);
        return () => {
            !!timer && clearTimeout(timer);
        };
    }, [animateBadge]);

    const colors = useColor(hero);

    const contentRenderer = (
        <>
            {!!addon && (
                <span className='button-addon-holder'>
                    {addon}
                </span>
            )}
            {!!icon && (
                <Icon
                    {...icon}
                />
            )}
            {!!children && (
                <span className='text-ellipsis'>
                    <Translate>{children}</Translate>
                </span>
            )}
            {!!name && (
                <TextTransition
                    noStyle
                    text={translate(name)}
                />
            )}
            {props.hasOwnProperty('badge') && (
                <Badge
                    animate={animateBadge}
                    {...(typeof badge === 'object' ? badge : { badge })}
                />
            )}
            {!!loading && (
                <Icon
                    size={20}
                    type='icon-a-loader'
                />
            )}
            {!!tooltip && (
                <Tooltip
                    position='top'
                    active={focused}
                    content={tooltip}
                >
                    <div className='absolutely-splash' />
                </Tooltip>
            )}
        </>
    );

    const sharedProps = {
        ref,
        tabIndex,
        onDoubleClick,
        onFocus: () => setFocused(true),
        onBlur: () => setFocused(false),
        ...conditionalSpread({
            'aria-label': ariaLabel,
        }, ariaLabel),
        className: classNames(
            `button`,
            className,
            `s-${size}`,
            `c-${color}`,
            `a-${appearance}`,
            `f-${flexibility}`,
            `cr-${cornerRadius}`,
            `id-${itemsDirection}`,
            {
                slash,
                shadow,
                loading,
                disabled,
                gradient,
                'force-focus': forceFocus && !gradient,
                'np': !children && !name && icon,
                'pointer-events-none': loading,
            },
        ),
        onClick: e => {
            setFocused(false);
            isFunction(onClick, false) && onClick(e);
        },
        style: {
            ...conditionalSpread(colors, !!hero),
            ...conditionalSpread({
                background: gradient,
            }, !!gradient),
        },
    };

    return type === 'div' ? (
        <div {...sharedProps}>
            {contentRenderer}
        </div>
    ) : (
        to ? (
            <NavLink
                to={to}
                rel={rel}
                {...conditionalSpread({
                    target,
                }, target)}
                {...sharedProps}
            >
                {contentRenderer}
            </NavLink>
        ) : href ? (
            <a
                href={href}
                rel={rel}
                {...conditionalSpread({
                    target,
                }, target)}
                {...sharedProps}
                {...conditionalSpread({
                    download: getFileNameFromSource(href),
                }, download)}
            >
                {contentRenderer}
            </a>
        ) : (
            <button
                {...sharedProps}
                type={type}
            >
                {contentRenderer}
            </button>
        )
    );
});

Button.propTypes = {
    to: PropTypes.string,
    rel: PropTypes.string,
    size: PropTypes.oneOf(uiKitSizes),
    icon: PropTypes.shape({
        type: PropTypes.string,
        size: PropTypes.number,
        today: PropTypes.bool,
        onClick: PropTypes.func,
        skeleton: PropTypes.bool,
        className: PropTypes.string,
        multiColor: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
    }),
    type: PropTypes.oneOf([
        'submit',
        'button',
        'div',
    ]),
    href: PropTypes.string,
    hero: PropTypes.string,
    name: PropTypes.any,
    addon: PropTypes.any,
    badge: PropTypes.any,
    slash: PropTypes.bool,
    color: PropTypes.oneOf([
        'brand',
        'hero',
        'accent',
        'grayscale',
        'contrast',
        'color',
    ]),
    shadow: PropTypes.bool,
    target: PropTypes.oneOf(toProps.target),
    tooltip: PropTypes.any,
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    gradient: PropTypes.string,
    tabIndex: PropTypes.number,
    download: PropTypes.bool,
    className: PropTypes.string,
    ariaLabel: PropTypes.string,
    forceFocus: PropTypes.bool,
    appearance: PropTypes.oneOf([
        'fill',
        'outline',
    ]),
    flexibility: PropTypes.oneOf([
        'full',
        'fit',
    ]),
    cornerRadius: PropTypes.oneOf(uiKitCornerRadius),
    onDoubleClick: PropTypes.func,
    itemsDirection: PropTypes.oneOf([
        'start',
        'end',
    ]),
};

export default Button;
