import React, { useEffect, useState } from 'react';
import FsLightbox from 'fslightbox-react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import './index.scss';

const LightBox = props => {
    const {
        openAt = 0,
        sources = [],
        type = 'image',
        opened = false,
        customAttributes,
    } = props;

    const [lightBoxController, setLightBoxController] = useState({
        toggler: opened,
        slide: openAt + 1,
    });

    useEffect(() => {
        setLightBoxController({
            toggler: opened,
            slide: openAt + 1
        });
    }, [opened, openAt]);

    useEffect(() => {
        return () => {
            document.documentElement.classList.remove('fslightbox-open');
            document.body.style = null;
        };
    }, []);

    return createPortal(
        <FsLightbox
            type={type}
            sources={sources}
            key={sources}
            customAttributes={customAttributes}
            {...lightBoxController}
        />,
        document.body
    );
};

LightBox.propTypes = {
    sources: PropTypes.arrayOf(
        PropTypes.string,
    ),
    type: PropTypes.oneOf([
        'image',
        'video',
        'youtube',
    ]),
    opened: PropTypes.bool,
    openAt: PropTypes.number,
    customAttributes: PropTypes.arrayOf(PropTypes.object),
};

export default LightBox;
