import { getRecoil, resetRecoil, setRecoil } from 'recoil-nexus';
import { BehaviorSubject, Subject } from 'rxjs';
import moment from 'moment';

import { campaignDataState, selectedVirtualSlotsState } from '../state';

import { CREATE_CAMPAIGN, GET_CAMPAIGNS, SCREENS, URLS } from '../constants/apiKeys';

import { groupScreens, removeDuplicatesFromArray } from '../helpers';

import uploadService from './uploadService';
import connectionService from './connectionService';

const distributeMediasByScreens = (medias, screens) => {
    const { main, additional } = groupScreens(screens);
    return medias.map(media => {
        if (media.additionalData.rank === 1) {
            const excludeScreens = medias.flatMap(({ additionalData: { groupKey, orientationId } }) => additional[groupKey] && orientationId === additional[groupKey].orientationId ? additional[groupKey].screenIds : []);
            return {
                ...media,
                screens: (main[media.additionalData.groupKey]?.screenIds || []).filter(val => !excludeScreens.includes(val))
            };
        }
        return {
            ...media,
            screens: additional[media.additionalData.groupKey]?.screenIds || []
        };
    });
};

const campaignCompareFunction = (a, b) => JSON.stringify(a) === JSON.stringify(b);

class CampaignService {
    constructor() {
        this.pendingCreateCall = false;
        this.campaignCallId = 0;
        this._prevCampaignData = null;
        this._campaignSaved = new Subject();
        this._campaignData = new BehaviorSubject(null);
        this._campaignData.subscribe(storeData => {
            this._prevCampaignData && !campaignCompareFunction(this._prevCampaignData, storeData) && storeData?.id && this.updateCampaign(storeData);
            !!storeData && !storeData.id && this.createCampaign(storeData);
            this._prevCampaignData = storeData;
        });
    }

    updateCampaign(newData) {
        const callId = ++this.campaignCallId;
        if (newData?.id) {
            const availableScreens = newData.screens.filter(val => newData.availableScreens?.includes(val));
            connectionService.getJson(`${URLS[SCREENS]}/${removeDuplicatesFromArray(newData.screens).join(',')}`).subscribe(screens => {
                const correctScreens = screens ? removeDuplicatesFromArray(screens?.map(screen => [screen.id, ...(Array.isArray(screen?.linked) ? screen?.linked : [])]).flatMap(i => i)).sort() : [];
                if (JSON.stringify([...newData.screens].sort()) !== JSON.stringify(correctScreens)) {
                    this.updateCampaign({
                        ...newData,
                        screens: correctScreens,
                    });
                } else {
                    connectionService.putJson(`${URLS[GET_CAMPAIGNS]}/${newData.id}`, {
                        ...newData,
                        ...(availableScreens?.length ? {
                            medias: distributeMediasByScreens(newData.medias, screens.filter(val => newData?.availableScreens?.includes(val.id))),
                        } : {})
                    }).subscribe(res => {
                        const campaignData = getRecoil(campaignDataState);
                        const data = Array.isArray(res?.data?.data) ? res?.data?.data[0] : res?.data?.data;
                        if (data) {
                            const medias = distributeMediasByScreens(data?.medias, screens.filter(val => data?.availableScreens?.includes(val.id)));
                            this._prevCampaignData = data;
                            if (JSON.stringify(data.medias) === JSON.stringify(medias) && this._prevCampaignData?.id === newData.id) {
                                this._campaignSaved.next(true);
                                callId === this.campaignCallId && !campaignCompareFunction(campaignData, data) && setRecoil(campaignDataState, data);
                            } else {
                                this.updateCampaign({
                                    ...data,
                                    medias,
                                });
                            }
                        }
                    });
                }
            });
        }
    }

    initCampaign(campaignData) {
        this._campaignData.next(campaignData);
    }

    disposeCampaign() {
        const campaignData = this._campaignData.getValue();
        const medias = campaignData?.medias?.map(media => media?.mediaId);
        if (medias?.length) {
            uploadService.removeUpload(medias);
        }
        this._campaignData.next(null);
        this.pendingCreateCall = false;
        resetRecoil(campaignDataState);
        resetRecoil(selectedVirtualSlotsState);
    }

    createCampaign(campaignData) {
        if (!!campaignData && !campaignData.id && !this.pendingCreateCall) {
            const initialState = {
                ...campaignData,
                startDate: campaignData.startDate || moment.utc().format('YYYY-MM-DDT00:00:00[Z]'),
                endDate: campaignData.endDate || moment.utc().add(30, 'day').format('YYYY-MM-DDT23:59:59[Z]'),
            };
            this.pendingCreateCall = true;
            const subscription = connectionService.postJson(URLS[CREATE_CAMPAIGN], initialState).subscribe(res => {
                if (res?.status === 200 && res?.data?.data?.id) {
                    this.getCampaign(Number(res?.data?.data?.id)).subscribe(data => {
                        if (data?.length) {
                            this._prevCampaignData = data[0];
                            setRecoil(campaignDataState, data[0]);
                        }
                    });
                } else if (JSON.stringify(campaignData) !== JSON.stringify(initialState)) {
                    setRecoil(campaignDataState, initialState);
                }
                this.pendingCreateCall = false;
                subscription.unsubscribe();
            });
        }
    }

    getCampaign(id) {
        return connectionService.getJson(`${URLS[GET_CAMPAIGNS]}/${id}`, {}, false);
    }

    getCampaignTotals(id) {
        return connectionService.getJson(`${URLS[GET_CAMPAIGNS]}/${id}/totals`, {}, false);
    }

    updateCampaignMedia = (mediaId, groupKey, additionalData, meta, resizeMode = 1) => {
        if (mediaId) {
            const campaignData = getRecoil(campaignDataState);
            const medias = campaignData?.medias.filter(media => media.additionalData.groupKey !== groupKey);
            medias?.push({
                meta,
                mediaId,
                resizeMode,
                screens: [],
                additionalData,
            });
            setRecoil(campaignDataState, {
                ...campaignData,
                medias,
            });
        }
    };

    get isSaved() {
        return this._campaignSaved;
    }
}

export default new CampaignService(); // eslint-disable-line
