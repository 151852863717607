import { useLocation, useNavigate } from 'react-router-dom';
import { isFunction } from 'clyne-core';
import { useRecoilValue } from 'recoil';
import { useEffect } from 'react';

import { getLSItem, setLSItem } from '../helpers';

import languages from '../configs/languages';

import { preciseLocationState, signedInState, userState } from '../state';

import { PROFILE, URLS } from '../constants/apiKeys';

import connectionService from '../services/connectionService';
import translationService from '../services/translationService';

import useDevice from './useDevice';

const citiesRedirect = [
    {
        pathname: '/yerevan',
        fallbackLanguage: 'hy',
        mobile: `north=40.3296930177552&south=40.03986617516082&west=44.448768373396845&east=44.57751440611169&zoom=12`,
        desktop: `north=40.2246663117884&south=40.130757660913574&west=44.32293003110892&east=44.709511452007355&zoom=13`,
    },
    {
        pathname: '/demo',
        fallbackLanguage: 'hy',
        mobile: `north=40.3296930177552&south=40.03986617516082&west=44.448768373396845&east=44.57751440611169&zoom=12`,
        desktop: `north=40.2246663117884&south=40.130757660913574&west=44.32293003110892&east=44.709511452007355&zoom=13`,
    },
];

const useLanguageDetect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const user = useRecoilValue(userState);
    const signedIn = useRecoilValue(signedInState);
    const preciseLocation = useRecoilValue(preciseLocationState);

    const { isMobile } = useDevice();

    useEffect(() => {
        const urlLanguage = location.pathname.split('/')[1];
        const detectedLanguage = languages.find(language => language.code === urlLanguage);
        const foundCity = citiesRedirect.find(city => location.pathname.includes(city.pathname));
        const finalUrl = location.pathname.replace(`${urlLanguage}/`, '') || '/';

        const setLanguage = (lang, callback) => {
            translationService.setLanguage(lang);
            setLSItem('language', lang);
            if (signedIn && user?.language !== lang) {
                connectionService.putJson(URLS[PROFILE], { language: lang }).subscribe(() => isFunction(callback));
            } else {
                isFunction(callback);
            }
        };

        if (foundCity) {
            setLanguage(detectedLanguage?.code || foundCity.fallbackLanguage, () => navigate(`/s?${isMobile ? foundCity.mobile : foundCity.desktop}`, {
                replace: true,
            }));
        } else {
            if (detectedLanguage) {
                setLanguage(detectedLanguage?.code, () => navigate(finalUrl, {
                    replace: true,
                }));
            }

            if (!detectedLanguage && Object.keys(preciseLocation || {})?.length && signedIn !== null && (!signedIn || user?.language)) {
                const lang = [
                    getLSItem('language'),
                    user?.language,
                    preciseLocation?.country_code === 'AM' ? 'hy' : preciseLocation?.location?.languages?.[0]?.code,
                    languages.find(lang => lang.default)?.code,
                ].filter(Boolean)[0];
                setLanguage(lang);
            }
        }
    }, [JSON.stringify(preciseLocation), signedIn, user?.language]); // eslint-disable-line
};

export default useLanguageDetect;
