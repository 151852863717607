import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { conditionalSpread, isFunction } from 'clyne-core';
import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';

import Tab from '../tab';
import Tabs from '../tabs';
import Input from '../input';
import Loader from '../loader';
import NoData from '../noData';
import Button from '../button';
import Popover from '../popover';
import Collapse from '../collapse';
import DropDown from '../dropDown';
import Translate from '../translate';
import Pagination from '../pagination';
import DatePickerInput from '../datePickerInput';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

import { moduleContentParamsState, reviewCampaignState, workspaceIdState } from '../../state';

import { getClosestNumber, getURLParam, inputValue, urlConstruct } from '../../helpers';

import searchParamsConstructor from '../../utils/searchParamsConstructor';

import connectionService from '../../services/connectionService';

import { paginationConfig } from '../pagination/props';

import './index.scss';

const dateFormatter = value => moment(value).format('YYYY-MM-DD');

const sharedProps = {
    cornerRadius: 'full',
};

const paginationOptions = paginationConfig.options.flatMap(option => option.value);

const ModuleContent = memo(props => {
    const {
        cta,
        cache,
        noData,
        filters,
        dataKey,
        children,
        workspaces,
        onTabClick,
        superAdmin,
        onDataChange,
        onUpdateById,
        hideUnneededPagination,
    } = props;

    const workspaceId = useRecoilValue(workspaceIdState);
    const reviewCampaign = useRecoilValue(reviewCampaignState);
    const [params, setParams] = useRecoilState(moduleContentParamsState);
    const resetParams = useResetRecoilState(moduleContentParamsState);

    const [itemToUpdate, setItemToUpdate] = useState(null);
    const [triggerItemUpdate, setTriggerItemUpdate] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();

    const initialFilters = filters?.flatMap(item => item.keys).reduce((obj, cur) => ({ ...obj, [cur]: '' }), {}) || {};
    const initialParams = {
        ...initialFilters,
    };

    const [tmpParams, setTmpParams] = useState({});
    const [callParams, setCallParams] = useState('');
    const [filtersOpened, setFiltersOpened] = useState(false);

    const findTabFilter = filters.find(filter => filter.component === 'Tabs');

    const tabsFilter = findTabFilter ? ({
        ...findTabFilter,
        keys: findTabFilter.keys.join(''),
    }) : null;

    const selectedTab = tabsFilter?.data?.map(f => f?.id)?.indexOf(params?.[tabsFilter?.keys]);

    useEffect(() => {
        if (reviewCampaign.id) {
            setItemToUpdate(reviewCampaign.id);
        } else {
            itemToUpdate && setTriggerItemUpdate(true);
        }
    }, [reviewCampaign.id]); // eslint-disable-line

    useEffect(() => {
        return () => {
            resetParams();
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        const state = {
            ...location,
            search: searchParamsConstructor(
                location.search,
                params,
                'set',
                null,
                true
            ),
        };
        location.search ? location.search.slice(1) !== state?.search && navigate(urlConstruct(state)) : navigate(urlConstruct(state), { replace: true });
    }, [params]); // eslint-disable-line

    useEffect(() => {
        if (location.search) {
            const localParams = searchParamsConstructor(
                location.search,
                Object.keys({ ...(initialParams || {}), page: 1, limit: 10 }),
                'get',
                (item, value) => {
                    switch (item) {
                        case 'limit':
                            return getClosestNumber(paginationOptions, parseInt(value));
                        case 'status':
                        case 'visibility':
                        case 'statusId':
                        case 'isProforma':
                        case 'holdStatusId':
                        case 'selfWorkspaceOnly':
                        case 'isLocked':
                        case 'verified':
                        case 'type':
                        case 'isAuthorisation':
                        case 'page':
                            return parseInt(value);
                        default:
                            return value;
                    }
                },
                true
            );
            if (!Object.keys(params).length || JSON.stringify(params) !== JSON.stringify(localParams)) {
                setParams(localParams);
                setTmpParams(localParams);
            }
            const modifiedParams = searchParamsConstructor('', localParams, 'set', null, true);
            setCallParams(`?${modifiedParams}`);
        } else {
            setParams(initialParams);
            setTmpParams(initialParams);
        }
    }, [location.search]); // eslint-disable-line

    const url = `${superAdmin ? '/super-admin' : workspaces ? `/workspaces/${workspaceId}` : ''}${dataKey?.[0] === '/' ? dataKey : `/${dataKey}`}${callParams}`;

    const { data, setData, initialLoading, loading, total, rawData } = useConnector(url, [], {}, false, callParams, 'replace', [callParams, cache, url].join(','));

    useEffect(() => {
        if (Array.isArray(data) && !data?.length && !loading && !initialLoading && !isNaN(params?.page) && params?.page > 1) {
            setParams(val => ({ ...val, page: params.page - 1 }));
        }
        !initialLoading && isFunction(onDataChange, false) && onDataChange(data);
    }, [data]);  // eslint-disable-line

    const hasFilters = Object.keys(params).filter(key => params[key] !== '' && key !== 'limit' && key !== 'page' && key !== tabsFilter?.keys);

    const resetAllFilters = () => {
        setFiltersOpened(false);
        const syncCode = getURLParam('sync');
        const tabsState = getURLParam(tabsFilter?.keys);
        navigate(urlConstruct({
            ...location,
            search: searchParamsConstructor(
                '',
                {
                    ...initialParams,
                    ...conditionalSpread({
                        sync: syncCode,
                    }, syncCode),
                    ...conditionalSpread({
                        [tabsFilter?.keys]: tabsState,
                    }, tabsState),
                },
                'set',
                null,
                true
            ),
        }));
        setTmpParams({});
    };

    const { isMobile } = useDevice();

    const clearFiltersRenderer = (
        <label
            className={classNames(
                `clear-filters-holder`,
                `button-external-hover`,
                {
                    'active': hasFilters.length,
                }
            )}
        >
            <Button
                size='small'
                color='accent'
                flexibility='fit'
                onClick={() => resetAllFilters()}
                icon={{
                    size: isMobile ? 20 : 22,
                    type: 'icon-a-cross',
                }}
            />
            <p>
                {isMobile ? (
                    <Translate>Clear</Translate>
                ) : (
                    <Translate>Clear filters</Translate>
                )}
            </p>
        </label>
    );

    const ctaRenderer = !!cta && (
        <li>
            <Button
                flexibility='fit'
                {...(isMobile ? ({
                    appearance: 'outline'
                }) : ({
                    color: 'accent',
                }))}
                icon={{
                    ...conditionalSpread({
                        size: 30,
                    }, isMobile),
                    type: 'icon-a-plus',
                }}
                {...cta}
                {...conditionalSpread({
                    children: null,
                }, isMobile)}
            />
        </li>
    );

    const filtersRenderer = filters.map(({ keys, superAdminOnly, ...filter }, index) => {
        const key = keys.join('');

        return filter.component !== 'Tabs' && (
            <li
                key={index}
                className={classNames(
                    {
                        [`t-${filter.type}`]: filter.type,
                        [`c-${filter.component}`]: filter.component,
                    }
                )}
            >
                {filter.component === 'Input' && (
                    <Input
                        {...sharedProps}
                        {...filter}
                        value={tmpParams[key]}
                        onChange={e => {
                            const value = inputValue(e);
                            setTmpParams(val => ({
                                ...val,
                                page: 1,
                                [key]: value || '',
                            }));
                        }}
                        {...conditionalSpread({
                            onBlur: () => setParams(val => tmpParams[key] !== undefined ? ({
                                ...val,
                                page: 1,
                                [key]: tmpParams[key],
                            }) : val),
                            onKeyDown: e => (e.key === 'Enter' || e.keyCode === 13) && setParams(val => !!tmpParams[key] && ({
                                ...val,
                                page: 1,
                                [key]: tmpParams[key],
                            })),
                        }, !isMobile)}
                    />
                )}
                {filter.component === 'DropDown' && (
                    <DropDown
                        {...sharedProps}
                        {...filter}
                        defaultSelected={params[key] || undefined}
                        onChange={value => {
                            if (isMobile) {
                                setTmpParams(val => ({
                                    ...val,
                                    [key]: value,
                                }));
                            } else {
                                setParams(val => ({
                                    ...val,
                                    [key]: value,
                                }));
                            }
                        }}
                    />
                )}
                {filter.component === 'DatePickerInput' && (
                    <DatePickerInput
                        {...sharedProps}
                        {...filter}
                        {...conditionalSpread({
                            ...conditionalSpread({
                                start: new Date(params[keys[0]]),
                                end: new Date(params[keys[1]]),
                            }, params[keys[0]] && params[keys[1]] && !isMobile),
                            ...conditionalSpread({
                                start: new Date(tmpParams[keys[0]] ?? params[keys[0]]),
                                end: new Date(tmpParams[keys[1]] ?? params[keys[1]]),
                            }, ((tmpParams[keys[0]] && tmpParams[keys[1]]) || (params[keys[0]] && params[keys[1]])) && isMobile),
                            onChange: value => {
                                const setter = val => ({
                                    ...val,
                                    [keys[0]]: dateFormatter(value[0]),
                                    [keys[1]]: dateFormatter(value[1]),
                                });
                                isMobile ? setTmpParams(setter) : setParams(setter);
                            },
                        }, filter.type === 'dateRangePicker')}
                    />
                )}
            </li>
        );
    });

    const handleApplyFilters = () => {
        setFiltersOpened(false);
        setTmpParams(val => {
            Object.keys(val).forEach(key => setParams(value => ({ ...value, [key]: val[key] })));
            return ({});
        });
    };

    const updateById = (id, setLoading) => {
        isFunction(onUpdateById);
        typeof setLoading === 'function' && setLoading(true);
        connectionService.getJson(url, {}, false).subscribe(res => {
            typeof setLoading === 'function' && setLoading(false);
            setItemToUpdate(null);
            setTriggerItemUpdate(false);
            if (Object.keys(res || {}).length) {
                const current = res.find(item => item.id === id);
                setData(val => current ? val.map(item => item.id === id ? current : item) : res);
            }
        });
    };

    useEffect(() => {
        if (itemToUpdate && triggerItemUpdate) {
            updateById(itemToUpdate);
        }
    }, [itemToUpdate, triggerItemUpdate]); // eslint-disable-line

    return initialLoading ? (
        <Loader />
    ) : (
        <>
            <div
                className={classNames({
                    'page-layout': data?.length,
                })}
            >
                <div className='grid module-content-grid'>
                    {(!!filters?.length || !!cta) && isMobile ? (
                        <>
                            <ul className='module-content-fh-m'>
                                <li>
                                    {!!filters?.length && (
                                        <Popover
                                            opened={filtersOpened}
                                            onClose={() => setFiltersOpened(false)}
                                            module={{
                                                footer: {
                                                    cta: [
                                                        {
                                                            color: 'grayscale',
                                                            disabled: !hasFilters.length,
                                                            onClick: () => resetAllFilters(),
                                                            children: <Translate>Reset</Translate>,
                                                        },
                                                        {
                                                            color: 'brand',
                                                            disabled: !Object.keys(tmpParams).length,
                                                            onClick: () => handleApplyFilters(),
                                                            children: <Translate>Apply</Translate>,
                                                        },
                                                    ],
                                                },
                                            }}
                                            content={
                                                <div className='popover-filters-grid'>
                                                    <Collapse
                                                        open
                                                        label={<Translate>General Filters</Translate>}
                                                    >
                                                        <ul className='grid'>
                                                            {filtersRenderer}
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                            }
                                        >
                                            <Button
                                                onClick={() => setFiltersOpened(true)}
                                                flexibility='fit'
                                                appearance='outline'
                                                icon={{
                                                    type: 'icon-a-filter',
                                                }}
                                                {...conditionalSpread({
                                                    badge: {
                                                        badge: hasFilters.length,
                                                        size: 'small',
                                                        position: 'static',
                                                    },
                                                }, hasFilters.length)}
                                            >
                                                <Translate>Filters</Translate>
                                            </Button>
                                        </Popover>
                                    )}
                                </li>
                                {ctaRenderer}
                            </ul>
                            <div className='v-divider' />
                        </>
                    ) : (
                        <ul className='module-content-head'>
                            <li>
                                {!!filters?.length && (
                                    <ul
                                        className='module-content-filters'
                                        style={{
                                            '--count': filters.length - 1 || 1,
                                        }}
                                    >
                                        {filtersRenderer}
                                        <li className='t-clear-all'>
                                            {clearFiltersRenderer}
                                        </li>
                                    </ul>
                                )}
                            </li>
                            {ctaRenderer}
                        </ul>
                    )}
                    {!!tabsFilter && (
                        <Tabs
                            body={false}
                            tabsMaxWidth={180}
                            onTabClick={tab => {
                                !!onTabClick && onTabClick();
                                setParams(val => ({
                                    ...val,
                                    page: 1,
                                    [tabsFilter?.keys]: tabsFilter?.data[tab < 0 ? 0 : tab]?.id,
                                }));
                            }}
                            selected={selectedTab < 0 ? 0 : selectedTab}
                        >
                            {tabsFilter?.data.map((tab, index) => (
                                <Tab
                                    key={index}
                                    badge={{
                                        ...tab.badge,
                                        position: 'static',
                                        appearance: 'light',
                                    }}
                                    title={tab.name}
                                />
                            ))}
                        </Tabs>
                    )}
                    {(!!total || loading) && (
                        <div className='grid module-content-grid gap-2'>
                            {children({
                                data: loading ? Array(params.limit || paginationConfig.options[0].value).fill({ skeleton: true }) : (data || []),
                                setData,
                                rawData,
                                loading,
                                updateById,
                            })}
                            {(!!data?.length && (hideUnneededPagination ? data.length !== total : true)) && (
                                <Pagination
                                    total={total}
                                    perPage={params?.limit}
                                    currentPage={params?.page}
                                    setPerPage={limit => setParams(val => ({ ...val, limit }))}
                                    setCurrentPage={page => setParams(val => ({ ...val, page }))}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {(!loading && !data?.length) && (
                <div className='module-content-no-data-holder'>
                    <NoData
                        type={noData?.type}
                        size={isMobile ? 'small' : 'big'}
                        {...(hasFilters.length ? ({
                            ...noData?.filtered,
                            cta: {
                                color: 'accent',
                                children: <Translate>Reset Filters</Translate>,
                                onClick: () => resetAllFilters(),
                            },
                        }) : noData?.nothing)}
                    />
                </div>
            )}
        </>
    );
});

ModuleContent.propTypes = {
    cta: PropTypes.object,
    cache: PropTypes.number,
    noData: PropTypes.shape({
        type: PropTypes.string,
        nothing: PropTypes.object,
        filtered: PropTypes.object,
    }),
    dataKey: PropTypes.string,
    filters: PropTypes.array,
    children: PropTypes.any,
    workspaces: PropTypes.bool,
    superAdmin: PropTypes.bool,
    onTabClick: PropTypes.func,
    onDataChange: PropTypes.func,
    onUpdateById: PropTypes.func,
    hideUnneededPagination: PropTypes.bool,
};

export default ModuleContent;
