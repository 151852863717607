import { Field, Form, Formik } from 'formik';
import React, { useState } from 'react';
import { object, string, } from 'yup';
import axios from 'axios';

import Input from '../input';
import Alert from '../alert';
import Button from '../button';
import Translate from '../translate';

import useDevice from '../../hooks/useDevice';

import integrations from '../../configs/integrations';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';

import './index.scss';

const SubscribeForm = () => {
    const [loading, setLoading] = useState(false);
    const [response, setResponse] = useState({
        show: false,
        type: '',
        text: '',
    });

    const { isMobile } = useDevice();

    return (
        <>
            <Formik
                initialValues={{ email: '' }}
                validationSchema={object().shape({
                    email: string()
                        .email()
                        .required()
                })}
                validateOnBlur={false}
                onSubmit={(values, { resetForm, setErrors }) => {
                    setLoading(true);
                    const url = `${integrations.hubSpot.api.endpoint}${integrations.hubSpot.portal}/${integrations.hubSpot.forms.subscribe}`;
                    axios.post(url, {
                        submittedAt: new Date().getTime(),
                        fields: [
                            ...Object.keys(values).map(name => ({
                                name,
                                value: values[name],
                            }))
                        ],
                        context: {
                            pageUri: document.location.href,
                            pageName: document.title,
                        },
                    }).then(() => {
                        setResponse({
                            show: true,
                            type: 'success',
                            text: <Translate>Thank you for staying in touch with us ♥️</Translate>,
                        });
                        resetForm();
                        setLoading(false);
                    }, () => {
                        addToast({
                            type: 'error',
                            message: <Translate>Please recheck provided data.</Translate>,
                        });
                        setLoading(false);
                    });
                    setTimeout(() => {
                        setErrors({});
                    }, 0);
                }}
            >
                {({ errors }) => (
                    <Form
                        noValidate
                        className='subscribe-form-holder'
                    >
                        <Field name='email'>
                            {({ field }) => (
                                <Input
                                    type='email'
                                    size={isMobile ? 'big' : 'extra-big'}
                                    disabled={loading}
                                    cornerRadius='full'
                                    placeholder={translate('Email')}
                                    error={errors && errors[field.name]}
                                    {...field}
                                />
                            )}
                        </Field>
                        <Button
                            ariaLabel={translate('Subscribe')}
                            flexibility='fit'
                            size={isMobile ? 'big' : 'extra-big'}
                            loading={loading}
                            {...isMobile ? ({
                                color: 'accent',
                                icon: {
                                    size: 24,
                                    type: 'icon-a-send',
                                },
                            }) : ({
                                children: (
                                    <Translate>Subscribe</Translate>
                                ),
                            })}
                        />
                    </Form>
                )}
            </Formik>
            <Alert
                show={response.show}
                text={response.text}
                type={response.type}
                onClose={() => setResponse(val => ({
                    ...val,
                    show: false,
                }))}
            />
        </>
    );
};

export default SubscribeForm;
