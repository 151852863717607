import { conditionalSpread, isFunction } from 'clyne-core';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Input from '../input';
import Point from '../point';
import Price from '../price';
import Loader from '../loader';
import Checkbox from '../checkbox';
import DropDown from '../dropDown';
import FieldSet from '../fieldSet';
import Collapse from '../collapse';
import GoogleMap from '../googleMap';
import Translate from '../translate';
import MediaFrame from '../mediaFrame';
import Information from '../information';
import MassActionApply from '../massActionApply';

import useDevice from '../../hooks/useDevice';
import useConnector from '../../hooks/useConnector';

import { addEditScreenFields } from './data';

import { ADD_SCREEN, URLS } from '../../constants/apiKeys';

import connectionService from '../../services/connectionService';

import addToast from '../../utils/addToast';
import translate from '../../utils/translate';
import getCountryStateCityFetchUrl from '../../utils/getCountryStateCityFetchUrl';

import { getScreenType, successStatus, inputValue, removeDuplicatesFromArray, idFormatter } from '../../helpers';

import { addEditScreenModalState, dynamicDataState, genericState, workspaceIdState } from '../../state';

import './index.scss';

const initialZoom = 3;

const AddEditScreen = props => {
    const {
        id,
        setModal,
        onComplete,
        mode = 'add',
    } = props;

    const formRef = useRef(null);

    const generic = useRecoilValue(genericState);
    const dynamicData = useRecoilValue(dynamicDataState);
    const workspaceId = useRecoilValue(workspaceIdState);
    const setAddEditScreenModal = useSetRecoilState(addEditScreenModalState);

    const { isMobile } = useDevice();

    const { data: dataArray, initialLoading } = useConnector(`/workspaces/${workspaceId}/screens?query=${id}`, {}, {}, false, !!id, 'replace');

    const data = dataArray?.[0];

    const handleSetImagesArray = current => {
        const currentImages = (current || []).filter(i => i?.url);
        return [
            ...currentImages,
            ...Array(!isMobile && currentImages.length < 3 ? 3 - currentImages.length : 1).fill({
                url: '',
            }),
        ];
    };

    const calcInitialValues = () => addEditScreenFields.flatMap(i => i.fields).reduce((arr, val) => {
        const newData = {
            ...arr, ...(val.name ? {
                [val.modifierKey || val.name]: val.modifierKey ? (mode === 'edit' ? val.modifier(data, mode) : '') : ((mode === 'edit' && data?.[val.name]) || val.initialValue || ''),
            } : {})
        };

        const getPrice = period => data?.prices?.find(price => price?.period === period)?.price;

        switch (val.name) {
            case 'images':
                newData.images = handleSetImagesArray(newData.images);
                break;
            case 'dailyPrice':
                newData[val.name] = getPrice(1);
                break;
            case 'weeklyPrice':
                newData[val.name] = getPrice(2);
                break;
            case 'monthlyPrice':
                newData[val.name] = getPrice(3);
                break;
            default:
                break;
        }

        return newData;
    }, {});

    const [fields, setFields] = useState([]);
    const [initialValues, setInitialValues] = useState(calcInitialValues());
    const [massActionApplyActive, setMassActionApplyActive] = useState(false);
    const [saveForMassEditChecked, setSaveForMassEditChecked] = useState(false);
    const [map, setMap] = useState({
        lat: data?.latitude || 0,
        lng: data?.longitude || 0,
        zoom: data?.longitude ? 13 : initialZoom,
    });

    useEffect(() => {
        if (generic && (mode === 'edit' ? data : true) && !fields.length) {
            setAddEditScreenModal(val => ({
                ...val,
                title: [idFormatter(data?.id), data?.name].filter(Boolean).join(' - '),
            }));
            addEditScreenFields.forEach(section => setFields(val => ([
                ...val,
                {
                    ...section,
                    fields: section.fields.map(field => ({
                        ...field,
                        ...conditionalSpread({
                            data: generic[field.dataKey],
                        }, field.dataKey),
                    })),
                }
            ])));
            setInitialValues(calcInitialValues());
        }
    }, [generic, data]); // eslint-disable-line

    useEffect(() => setModal(val => ({
        ...val,
        primaryButton: {
            ...val.primaryButton,
            onClick: () => {
                const element = formRef.current?.querySelector('.onScrollError');
                if (element) {
                    element.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                        behavior: 'smooth',
                    });
                }
                formik.setErrors({});
                formik.submitForm().then(() => {
                });
            },
        }
    })), []);  // eslint-disable-line

    const handleOnComplete = () => {
        setMassActionApplyActive(false);
        setSaveForMassEditChecked(false);
        isFunction(onComplete);
    };

    const formatFormikValues = values => Object.entries(values).reduce((acc, [key, value]) => {
        const modifierField = addEditScreenFields.flatMap(i => i.fields).find(item => item.modifierKey === key);

        if (key === 'dailyPrice' || key === 'weeklyPrice' || key === 'monthlyPrice') {
            return ({
                ...acc,
                prices: [
                    ...(acc?.prices || []),
                    {
                        period: key === 'dailyPrice' ? 1 : key === 'weeklyPrice' ? 2 : 3,
                        price: value,
                    },
                ],
            });
        }

        return ({
            ...acc,
            [modifierField?.name || key]: key === 'images' ? value.filter(val => !!val.imageKey) : modifierField ? modifierField.modifier(values) : value,
        });
    }, {});

    const handleSubmit = values => {
        setModal(val => ({
            ...val,
            primaryButton: {
                loading: true,
                ...val.primaryButton,
            }
        }));

        const requestValues = formatFormikValues(values);

        const constructParams = (data, images) => ({
            ...data,
            images: images.some(i => i.featured) ? images.map(image => ({
                ...image,
            })) : images.map((image, index) => ({
                ...image,
                featured: !index,
            })),
        });

        if (mode !== 'edit') {
            const url = `/workspaces/${workspaceId}${URLS[ADD_SCREEN]}`;

            const images = (requestValues.images || []).filter(i => i.url);

            connectionService.postJson(url, constructParams(requestValues, images)).subscribe(res => {
                setModal(val => ({
                    ...val,
                    primaryButton: {
                        ...val.primaryButton,
                        loading: false,
                    }
                }));
                if (successStatus(res)) {
                    addToast({
                        type: 'success',
                        message: <Translate>Your screen is successfully added</Translate>,
                    });
                    handleOnComplete();
                }
            });
        } else {
            const url = `/workspaces/${workspaceId}/screens/${data.id}`;
            const changedValues = Object.keys(requestValues).reduce((acc, name) => {
                return JSON.stringify(requestValues[name]) !== JSON.stringify(data?.[name]) ? { ...acc, [name]: requestValues[name] } : acc;
            }, {});

            const images = (changedValues.images || values.images || []).filter(i => i.url);

            connectionService.putJson(url, constructParams(changedValues, images)).subscribe(res => {
                setModal(val => ({
                    ...val,
                    primaryButton: {
                        ...val.primaryButton,
                        loading: false,
                    }
                }));
                if (successStatus(res)) {
                    const name = [idFormatter(data?.id), changedValues?.name || data?.name].filter(Boolean).join(' - ');

                    addToast({
                        type: 'success',
                        title: <Translate>Successfully saved</Translate>,
                        message: <Translate replaceMap={{ _NAME_: name }}>_NAME_ is successfully updated</Translate>,
                    });
                    if (data?.linked?.filter(id => id !== data?.id).length || saveForMassEditChecked) {
                        setMassActionApplyActive(true);
                    } else {
                        handleOnComplete();
                    }
                }
            });
        }
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validationSchema: Yup.object().shape(Object.keys(initialValues).reduce((acc, key) => {
            const field = fields.flatMap(i => i.fields).find(i => i.name === key);
            if (field?.validation) {
                return { ...acc, [key]: field.validation };
            }
            if (key === 'images') {
                return {
                    ...acc,
                    [key]: Yup.array().min(1).of(
                        Yup.object().shape({
                            url: Yup.string(),
                        })
                    ).test({
                        message: translate('At least one image is required'),
                        test: arr => arr.filter(val => val.url).length > 0,
                    })
                };
            }
            return { ...acc, [key]: Yup.string().required(true) };
        }, {})),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: handleSubmit,
    });

    useEffect(() => {
        setModal(val => ({
            ...val,
            isDirty: formik.dirty,
        }));
    }, [formik.dirty]); // eslint-disable-line

    useEffect(() => {
        const {
            name,
            cityId,
            countryId,
            stateCode,
        } = formik.values;

        mode === 'edit' && setAddEditScreenModal(val => ({
            ...val,
            title: [idFormatter(data?.id), name || data?.name].filter(Boolean).join(' - '),
        }));

        (!!stateCode && !!cityId && !!countryId) && connectionService.getJson(getCountryStateCityFetchUrl(countryId, stateCode)).subscribe(res => {
            const city = res?.find(city => city?.geoNameId === cityId);
            !!city && setMap(val => ({
                ...val,
                lat: parseFloat(city.lat),
                lng: parseFloat(city.lng),
                ...conditionalSpread({
                    zoom: 10,
                }, val.zoom === initialZoom),
            }));
        });
    }, [formik.values]); // eslint-disable-line

    const massScreens = removeDuplicatesFromArray((dynamicData?.deviceStatuses?.all || []).map(screen => screen.screenId)).sort((a, b) => a - b).filter(id => id !== data?.id);

    const fieldRenderer = field => {
        const name = field.modifierKey || field.name;

        const {
            error,
            value,
        } = formik.getFieldMeta(name);

        const {
            setError,
            setValue: setFormikValue,
        } = formik.getFieldHelpers(name);

        const setValue = val => {
            setError(undefined);
            setFormikValue(val);
        };

        const sharedProps = {
            name: name,
            label: field.label,
            cornerRadius: 'full',
        };

        const handleMapClick = val => {
            formik.setFieldValue('latitude', val.lat);
            formik.setFieldValue('longitude', val.lng);
            formik.setFieldError('latitude', '');
            formik.setFieldError('longitude', '');
        };

        const firstErrorName = Object.keys(formik?.errors || {})?.[0];

        return (
            <div
                className={classNames(
                    'full-width',
                    {
                        onScrollError: field.type === 'map' ? (firstErrorName === 'latitude' || firstErrorName === 'longitude') : (firstErrorName === field.name && field.name)
                    }
                )}
            >
                {(() => {
                    switch (field.type) {
                        case 'map':
                            return (
                                <div
                                    className={classNames(
                                        'ae-ss-map-holder',
                                        'span',
                                        {
                                            'error': formik.errors.latitude,
                                            'disabled': !formik.values.cityId,
                                        },
                                    )}
                                >
                                    <GoogleMap
                                        controls
                                        geolocation
                                        lat={map.lat}
                                        lng={map.lng}
                                        zoom={map.zoom}
                                        enableItemLocate
                                        onClick={handleMapClick}
                                    >
                                        {(!!formik.values.latitude && !!formik.values.longitude) && (
                                            <Point
                                                lat={formik.values.latitude}
                                                lng={formik.values.longitude}
                                                key={`${formik.values.latitude}${formik.values.longitude}`}
                                                icon={formik.values.typeId ? getScreenType(formik.values.typeId, generic)?.icon : 'icon-a-monitor'}
                                                {...conditionalSpread({
                                                    color: getScreenType(formik.values.typeId, generic)?.color,
                                                }, !!formik.values.typeId)}
                                            />
                                        )}
                                    </GoogleMap>
                                    {(!!massScreens?.length && mode === 'edit') && (
                                        <div className='apply-for-all-checkbox'>
                                            <Checkbox
                                                value={saveForMassEditChecked}
                                                label={<Translate>Save for Mass Edit</Translate>}
                                                onChange={e => setSaveForMassEditChecked(e.target.checked)}
                                            />
                                        </div>
                                    )}
                                </div>
                            );
                        case 'dropDown':
                            return (
                                <DropDown
                                    {...sharedProps}
                                    width={280}
                                    position='bottom-start'
                                    data={field.data}
                                    defaultSelected={value}
                                    error={error}
                                    onChange={setValue}
                                    {...conditionalSpread({
                                        fetchFrom: field?.fetchFrom && field?.fetchFrom(formik.values),
                                    }, !!field?.fetchFrom)}
                                    {...conditionalSpread({
                                        disabled: field?.disabled && field?.disabled(formik.values),
                                    }, !!field?.disabled)}
                                    {...conditionalSpread({
                                        valueSelector: field?.valueSelector,
                                    }, !!field?.valueSelector)}
                                    {...conditionalSpread({
                                        renderer: field?.renderer,
                                    }, !!field?.renderer)}
                                />
                            );
                        case 'images':
                            return (
                                <>
                                    <div
                                        className={classNames(
                                            'grid',
                                            'gap-2',
                                            isMobile ? 'cols-1' : 'cols-3',
                                        )}
                                    >
                                        {value.sort((a, b) => !!b.url - !!a.url).map((image, index) => (
                                            <MediaFrame
                                                key={index}
                                                width={1920}
                                                height={1080}
                                                accept='image'
                                                appearance='fit'
                                                source={image.thumbnailUrl || image.url}
                                                getterUrl={`/workspaces/${workspaceId}/screen/get-upload-url`}
                                                {...conditionalSpread({
                                                    onFeatured: () => setValue(value.map((val, i) => ({
                                                        ...val,
                                                        featured: index === i,
                                                    }))),
                                                }, value.filter(i => i.url).length > 1)}
                                                featured={image.featured}
                                                onComplete={val => {
                                                    value[index] = {
                                                        color: val.data?.color || '',
                                                        imageKey: val.getter.key,
                                                        blurHash: val.data?.blurHash || '',
                                                        thumbnailKey: val.getter.thumbnailKey,
                                                        url: val.getter.uploadUrl.replace(/\?.*$/, ''),
                                                        featured: (!value.some(img => img.featured) && !index),
                                                    };
                                                    setValue(handleSetImagesArray(value));
                                                }}
                                                onDelete={() => {
                                                    value[index].featured && (value[0].featured = true);
                                                    value[index] = {
                                                        url: '',
                                                    };
                                                    setValue(handleSetImagesArray(value));
                                                }}
                                            />
                                        ))}
                                    </div>
                                    <Collapse open={!!error?.length && error?.toLowerCase() !== `${name} is a required field` && error?.toLowerCase() !== 'required'}>
                                        <div className='grid-margin-top'>
                                            <Information appearance='inline'>
                                                {error}
                                            </Information>
                                        </div>
                                    </Collapse>
                                </>
                            );
                        default:
                            return (
                                <Input
                                    {...sharedProps}
                                    value={value}
                                    type={field.type}
                                    error={error}
                                    min={0}
                                    onChange={e => setValue(field.dataType === 'number' && inputValue(e) !== '' ? parseFloat(inputValue(e)) : inputValue(e))}
                                />
                            );
                    }
                })()}
            </div>
        );
    };

    const excludeFieldsFromMassEdit = [
        'name',
        'description',
        'images',
        'latitude',
        'longitude',
    ];

    return initialLoading && mode === 'edit' ? (
        <div className='modal-message-loader'>
            <Loader absolute />
        </div>
    ) : (
        <>
            <form
                ref={formRef}
                className='grid gap-4'
                onSubmit={formik.handleSubmit}
            >
                {fields.map((section, index) => (
                    <FieldSet
                        key={index}
                        cols={section.cols}
                        collapseAble={false}
                        title={section.name}
                        fields={section.fields}
                        fieldRenderer={fieldRenderer}
                    />
                ))}
            </form>
            {(!!data?.linked?.filter(id => id !== data?.id)?.length || saveForMassEditChecked) && (
                <MassActionApply
                    columns={isMobile ? 2 : 3}
                    onClose={handleOnComplete}
                    closeOnBackdropClick={false}
                    active={massActionApplyActive}
                    dynamicScreens={saveForMassEditChecked}
                    screens={saveForMassEditChecked ? massScreens : data?.linked?.filter(id => id !== data?.id)}
                    endpointConstructor={id => `/workspaces/${workspaceId}/screens/${id}`}
                    title={saveForMassEditChecked ? (
                        <Translate>Mass Screen Edit</Translate>
                    ) : (
                        <Translate>Linked Screen Edit</Translate>
                    )}
                    paramsConstructor={({ value }) => formatFormikValues(value.reduce((prev, current) => ({
                        ...prev,
                        [current]: current === 'loopDuration' ? formik.values?.slotDuration * formik.values?.slotCount : formik.values[current],
                    }), {}))}
                    information={{
                        children: massActionApplyActive ? (
                            <Translate>You can deselect the fields or screens that you don't want to change</Translate>
                        ) : (
                            <Translate>You can deselect the fields that you don't want to change</Translate>
                        ),
                    }}
                    dropDown={{
                        mode: 'multiple',
                        valueSelector: 'name',
                        labelSelector: 'label',
                        selectAllByDefault: true,
                        data: addEditScreenFields.flatMap(i => i.fields).filter(i => i.name && !excludeFieldsFromMassEdit.includes(i.name)).map(field => {
                            const getDisplayValue = field => generic[field.dataKey]?.find(item => item.id === formik.getFieldMeta(field.name).value)?.name || formik.getFieldMeta(field.name).value;

                            return ({
                                ...field,
                                information: field.name === 'loopDuration' ? formik.getFieldMeta('slotCount').value : field.type === 'price' ? (
                                    <Price
                                        number={getDisplayValue(field)}
                                    />
                                ) : getDisplayValue(field),
                            });
                        }),
                        placeholder: translate('Select the fields to change'),
                    }}
                    message={{
                        type: 'linkedScreen',
                        children: saveForMassEditChecked ? (
                            <Translate>Please review everything before applying the mass edit. This action can't be undone.</Translate>
                        ) : (
                            <Translate>We have noticed that you have edited a linked screen, would you like to apply the same settings for the other linked screens?</Translate>
                        ),
                    }}
                />
            )}
        </>
    );
};

AddEditScreen.propTypes = {
    data: PropTypes.object,
    mode: PropTypes.oneOf([
        'add',
        'edit',
    ]),
    setModal: PropTypes.func,
    onComplete: PropTypes.func,
};

export default AddEditScreen;
