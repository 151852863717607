import React from 'react';

import './index.scss';

const Background = () => {
    return (
        <div
            className="row-background"
        />
    );
};

export default Background;
