import { conditionalSpread } from 'clyne-core';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import useColor from '../../hooks/useColor';
import useElementResize from '../../hooks/useElementResize';

import Icon from '../icon';

import { ReactComponent as Artwork } from './media/artwork.svg';

import './index.scss';

const BillboardArtwork = props => {
    const {
        icon,
        color,
    } = props;

    const ref = useRef(null);

    const { width } = useElementResize(ref);

    const colors = useColor(color);

    return (
        <div className='billboard-artwork-holder'>
            <div
                ref={ref}
                className='billboard-artwork'
            >
                <Artwork />
                <div
                    className='ba-icon-holder'
                    style={{
                        '--icon-external-size': `${width / 10}px`,
                        ...conditionalSpread(colors, !!color),
                    }}
                >
                    <Icon
                        type={icon}
                    />
                </div>
            </div>
        </div>
    );
};

BillboardArtwork.propTypes = {
    icon: PropTypes.string,
    color: PropTypes.string,
};

export default BillboardArtwork;
