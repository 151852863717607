import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from '../icon';
import Collapse from '../collapse';

import useDevice from '../../hooks/useDevice';

import './index.scss';

const FieldSet = props => {
    const {
        cols,
        span,
        title,
        fields,
        opened,
        onTitleClick,
        fieldRenderer,
        collapseAble = true,
    } = props;

    const { isMobile } = useDevice();

    return (
        <div
            className={classNames(
                'aes-section-holder',
                {
                    'span': span,
                }
            )}
        >
            <div
                className={classNames(
                    'aes-s-head',
                    {
                        'active': collapseAble ? opened : !collapseAble,
                        'pointer-events-none': !collapseAble,
                    }
                )}
                onClick={onTitleClick}
            >
                {collapseAble && (
                    <small>
                        <Icon
                            size={20}
                            type={opened ? 'icon-a-minus' : 'icon-a-plus'}
                        />
                    </small>
                )}
                <h2>{title}</h2>
            </div>
            <Collapse
                removeOnClose={false}
                open={collapseAble ? opened : !collapseAble}
            >
                <div
                    className={classNames(
                        `grid`,
                        `gap-2`,
                        `cols-${isMobile ? 1 : (cols || 3)}`,
                    )}
                >
                    {fields.map((field, index) => !field.hidden && (
                        <div
                            key={index}
                            className={classNames(
                                'field-set-item',
                                (field.type === 'map' || field.type === 'images' || field.subType === 'media') ? 'span' : 'overflow-hidden',
                            )}
                        >
                            {fieldRenderer(field)}
                        </div>
                    ))}
                </div>
            </Collapse>
        </div>
    );
};

FieldSet.propTypes = {
    cols: PropTypes.number,
    span: PropTypes.bool,
    title: PropTypes.any,
    fields: PropTypes.array,
    opened: PropTypes.bool,
    onTitleClick: PropTypes.func,
    collapseAble: PropTypes.bool,
    fieldRenderer: PropTypes.func,
};

export default FieldSet;
