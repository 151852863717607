import clasNames from 'classnames';
import PropTypes from 'prop-types';

import Icon from '../icon';
import Tooltip from '../tooltip';
import Translate from '../translate';

import useCopy from '../../hooks/useCopy';

import './index.scss';

const CopyText = props => {
    const {
        text,
        absolute,
        children,
    } = props;

    const {
        icon,
        canCopy,
        className,
        handleCopy,
    } = useCopy();

    return canCopy(text) ? (
        <span
            className={clasNames(
                'copy-text-holder',
                {
                    absolute,
                }
            )}
        >
            {children}
            <Tooltip
                content={
                    <Translate>Copy</Translate>
                }
            >
                <Icon
                    size={18}
                    type={icon}
                    tabIndex={0}
                    className={className}
                    onClick={() => handleCopy(text)}
                />
            </Tooltip>
        </span>
    ) : children;
};

CopyText.propTypes = {
    text: PropTypes.any,
    absolute: PropTypes.bool,
    children: PropTypes.any,
};

export default CopyText;
