export const fileTypeByFormat = [
    {
        type: 'image',
        formats: [
            'jpg',
            'jpeg',
            'png',
            'gif',
            'tif',
            'tiff',
            'ico',
            'cur',
            'bmp',
        ],
    },
    {
        type: 'video',
        formats: [
            'mp4',
            'mov',
            '3gp',
            'm4v',
        ],
    }
];

export const fileUploadSupportedFormats = fileTypeByFormat.flatMap(i => i.formats);

export const notificationKeys = {
    notifications: 'notifications',
    workspaceNotifications: 'workspaceNotifications',
};

export const infiniteScrollLimit = 10;

export const wizardErrorsKeys = {
    campaign: {
        dates: 'requiredCampaignDates',
    },
};

export const userRoles = {
    client: 'client',
    partner: 'partner',
};

export const verificationHashLength = 6;

export const NIL = '00000000-0000-0000-0000-000000000000';

export const dateFormat = 'D MMM YYYY';

export const dateTimeFormat = 'DD MMM YYYY HH:mm:ss';

export const appearanceIcons = {
    light: 'icon-a-sun',
    dark: 'icon-a-moon',
    automatic: 'icon-a-rays',
};

export const priceCalculationTypes = {
    perView: 1,
    fixed: 2,
};

export const campaignPeriods = {
    daily: 1,
    weekly: 2,
    monthly: 3,
    custom: 4,
};
