import { backgroundImage } from 'clyne-core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import React from 'react';

import To from '../to';
import Icon from '../icon';
import Badge from '../badge';

import './index.scss';

const Notification = props => {
    const {
        to,
        name,
        icon,
        date,
        image,
        badge,
        style,
        message,
        onClick,
        disabled,
        inlineIcon,
        placeholder,
        appearance = 'compact',
    } = props;

    return (
        <ul
            style={style}
            onClick={onClick}
            className={classNames(
                `notification-holder`,
                `a-${appearance}`,
                {
                    disabled,
                }
            )}
        >
            <li>
                <div
                    style={backgroundImage(image)}
                    className={classNames(
                        'notification-ii-holder',
                        {
                            'i-image': image && !inlineIcon,
                            [`i-${icon || inlineIcon}`]: (icon && !image) || inlineIcon,
                        }
                    )}
                >
                    {(icon && !image && !inlineIcon) && (
                        <Icon
                            size={28}
                            type={icon}
                        />
                    )}
                    {Number.isNaN(badge) ? (
                        <div
                            className={classNames(
                                `notification-badge`,
                                {
                                    'active': badge,
                                }
                            )}
                        />
                    ) : (
                        <Badge
                            size='small'
                            badge={badge}
                            transform={false}
                        />
                    )}
                    {!!inlineIcon && (
                        <div className='inline-icon-holder'>
                            <Icon
                                size={16}
                                type={inlineIcon}
                            />
                        </div>
                    )}
                </div>
            </li>
            <li>
                {!!to && (
                    <To
                        url={to}
                        className='absolutely-splash'
                    />
                )}
                <div className='notification-head'>
                    <h6
                        className={classNames(
                            {
                                'strong': badge,
                            }
                        )}
                    >
                        {name}
                    </h6>
                    <time>{moment(date).format(moment(date).isBefore(moment().startOf('day')) ? 'D MMM HH:mm' : 'HH:mm')}</time>
                </div>
                <div className={classNames({ placeholder: !message })}>
                    {message || placeholder}
                </div>
            </li>
        </ul>
    );
};

Notification.propTypes = {
    to: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
    date: PropTypes.string,
    image: PropTypes.string,
    badge: PropTypes.any,
    style: PropTypes.object,
    message: PropTypes.any,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    inlineIcon: PropTypes.string,
    appearance: PropTypes.oneOf([
        'compact',
        'expanded',
    ]),
    placeholder: PropTypes.any,
};

export default Notification;
