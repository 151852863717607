import { Navigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import PropTypes from 'prop-types';
import React from 'react';

import Loader from '../loader';

import { userRoleState } from '../../state';

import { userRoles } from '../../constants/construct';

const RoleProtection = props => {
    const {
        role,
        children,
    } = props;

    const userRole = useRecoilValue(userRoleState);

    return userRole === null ? (
        <Loader absolute />
    ) : (
        userRole === role ? (
            children
        ) : (
            <Navigate to='/' />
        )
    );
};

RoleProtection.propTypes = {
    role: PropTypes.oneOf(Object.values(userRoles)),
    children: PropTypes.any,
};

export default RoleProtection;
